import gql      from 'graphql-tag';


export default gql`
  mutation publishArtist($id: String!) {
    publishArtist(id: $id) {
      __typename

      ... on Artist {
        IsActive
      }

      ... on ArtistNotFound {
        message
        status
      }

      ... on AlgoliaError {
        message
        Status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
