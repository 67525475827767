<template>
  <section class="landing-categories__wrapper">
    <header class="landing-categories__header">
      <Heading
        :level="'h2'"
        :tag="'h2'"
        :class="{ 'mb-base': !$mediaQueries.isDesktop }">
        {{ $t('artist.dashboard.landing.categories.heading') }}
      </Heading>
      <div class="landing-categories__arrows-wrapper">
        <Icon
          @click="() => $refs.flickity && $refs.flickity.previous()"
          :variant="'arrow-left-outline'"
          :size="'xs'"
          :color="'white'"
          :tag="'button'"
          hasDarkBackdrop
          type="button"
          class="ml-auto mr-md">
        </Icon>
        <Icon
          @click="() => $refs.flickity && $refs.flickity.next()"
          :variant="'arrow-right-outline'"
          :size="'xs'"
          :color="'white'"
          :tag="'button'"
          hasDarkBackdrop
          type="button"
          class="ml-base">
        </Icon>
      </div>
    </header>

    <Flickity
      :options="flickityOptions"
      ref="flickity"
      class="landing-categories__categories-list">
      <div
        v-for="(category, index) in categories"
        :key="`category-${index}`"
        class="landing-categories__categories-item">
        <figure class="landing-categories__categories-item-wrapper">
          <img
            :src="category.image"
            alt="Categorie artiste"
            class="landing-categories__categories-item-image">
            <Paragraph
              :tag="'figcaption'"
              :size="'md'"
              hasEllipsisOverflow>
              {{ category.label }}
            </Paragraph>
        </figure>
      </div>
    </Flickity>
  </section>
</template>

<script>

import Flickity                 from 'vue-flickity';

import Heading                  from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                     from '../../../components/atoms/Icon/a-Icon.vue';
import Paragraph                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FLICKITY_OPTIONS         from '../../../constants/flickity-options.js';
import AnimationImage           from '../../../../public/assets/images/landing/animation.jpg';
import SingerImage              from '../../../../public/assets/images/landing/chant.jpg';
import CircusImage              from '../../../../public/assets/images/landing/cirque.jpg';
import DjImage                  from '../../../../public/assets/images/landing/dj.jpg';
import DancerImage              from '../../../../public/assets/images/landing/danse.jpg';
import BandImage                from '../../../../public/assets/images/landing/groupe.jpg';
import HypnotiserImage          from '../../../../public/assets/images/landing/hypnose.jpg';
import MagicianImage            from '../../../../public/assets/images/landing/magie.jpg';
import MakeupImage              from '../../../../public/assets/images/landing/makeup.jpg';
import PuppeteerImage           from '../../../../public/assets/images/landing/marionnettes.jpg';
import MentalistImage           from '../../../../public/assets/images/landing/mentaliste.jpg';
import MusicianImage            from '../../../../public/assets/images/landing/musicien.jpg';
import SingerMusicianImage      from '../../../../public/assets/images/landing/musicienchanteur.jpg';


export default {
  name: 'o-LandingCategories',
  components: {
    Flickity,
    Heading,
    Icon,
    Paragraph,
  },
  data: () => ({
    flickityOptions: FLICKITY_OPTIONS,
  }),
  computed: {
    categories() {
      return [
        {
          label: this.$t('activities.Animator'),
          image: AnimationImage,
        },
        {
          label: this.$t('activities.Singer'),
          image: SingerImage,
        },
        {
          label: this.$t('activities.CircusArtist'),
          image: CircusImage,
        },
        {
          label: this.$t('activities.Dj'),
          image: DjImage,
        },
        {
          label: this.$t('activities.Dancer'),
          image: DancerImage,
        },
        {
          label: this.$t('activities.Band'),
          image: BandImage,
        },
        {
          label: this.$t('activities.Hypnotiser'),
          image: HypnotiserImage,
        },
        {
          label: this.$t('activities.Magician'),
          image: MagicianImage,
        },
        {
          label: this.$t('activities.MakeupArtist'),
          image: MakeupImage,
        },
        {
          label: this.$t('activities.Puppeteer'),
          image: PuppeteerImage,
        },
        {
          label: this.$t('activities.Mentalist'),
          image: MentalistImage,
        },
        {
          label: this.$t('activities.Musician'),
          image: MusicianImage,
        },
        {
          label: this.$t('activities.SingerMusician'),
          image: SingerMusicianImage,
        },
      ];
    },
  },
}

</script>

<style lang="scss">

.landing-categories {
  &__wrapper {
    grid-row: 2 / 3;
    padding: var(--space-xl) var(--space-lg);
    padding-top: var(--space-lg);
    padding-bottom: var(--space-xxl);

    @media screen and ($desktop) {
      padding: var(--space-xl) var(--space-xxl);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-md);
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__categories-list {
    margin: 0 calc(-1 * var(--space-lg));

    @media screen and ($desktop) {
      margin: 0 calc(-1 * var(--space-xxl));
    }
  }

  &__categories-item {
    display: flex;
    flex-direction: column;
    margin-right: var(--space-lg);
  }

  &__categories-item-wrapper {
    width: 175px;
  }

  &__categories-item-image {
    height: 175px;
    object-fit: cover;
    margin-bottom: var(--space-sm);
    border-radius: var(--rounded-xl);
  }

  &__arrows-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-xs);

    @media screen and ($desktop) {
      margin-left: auto;
    }
  }
}

</style>
