<template>
  <main :class="[mainLayoutStyle, 'main-layout']">
    <slot></slot>
  </main>
</template>

<script>


export default {
  name: 'l-MainLayout',
  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    mainLayoutStyle() {
      return {
        'main-layout--full-screen': this.isFullScreen,
      }
    }
  },
}

</script>

<style lang="scss">

.main-layout {
  display: grid;
  grid-template-columns: 100%;

  @media screen and ($desktop) {
    max-width: 2000px;
    margin: 0 auto;
    padding: var(--space-xl);
  }

  &--full-screen {
    min-height: 100vh;
  }

  &--signin {
    align-items: center;
  }

  &--dashboard {
    grid-template-rows: auto 1fr;
    grid-row-gap: var(--space-xl);
    padding: var(--space-lg) var(--space-base);

    @media screen and ($desktop) {
      padding: var(--space-xl);
    }
  }

  &--artist-public-profile {
    grid-template-columns: 100%;
    padding: 0 var(--space-base);
    padding-bottom: var(--space-xl);
    grid-gap: var(--space-lg);

    @media screen and ($desktop) {
      max-width: 1500px;
      grid-template-columns: 1fr 33%;
      grid-template-rows: repeat(6, auto);
    }
  }

  &--landing-page {
    grid-template-rows: calc(100vmax - var(--space-header-height));
    align-items: start;
    margin: 0;
    max-width: 100%;
    padding: 0;

    @media screen and ($desktop) {
      grid-template-rows: calc(60vmin + var(--space-xxl));
    }
  }
}
</style>
