import gql from 'graphql-tag';


export default gql`
  query ($id: String!) {
    findArtist(id: $id) {
      ... on Artist {
        ID
        Token
        Email
        Firstname
        Lastname
        PhoneNumber
        AdminDescription
        Birthday
        BirthdayLocation
        createdAt
        LocationRange
        Line1
        Line2
        PostalCode
        State
        City
        Country
        Description
        URLCardID
        IsCertified
        HasCapacityEquipment
        ArtisteNickname
        ArtisteNicknameGenerated
        URLProfilePictures
        URLBannerPictures
        ProfilePicturesCropsOptions
        BannerPicturesCropsOptions
        IsAdditionalHourOk
        Tags
        Languages
        StatusName
        ClientPrices {
          Exception
          Normal
        }
        Status {
          __typename
          ... on Company {
            Siret
            VATNumber
            KBISUrl
            VATTaxable
            IsRCPro
            Name
          }
          ... on Intermittent {
            Nationality
            FamilyStatus
            PlaceOfBirth
            StateOfBirth
            CountryOfBirth
            SocialSecurityNumber
            ShowLeaveNumber
            Iban
            Bic
            TaxRate
          }
        }
        FAQ {
          ID
          QuestionTag
          Answer
        }
        Medias {
          ID
          URL
          Type
          Options
          Rank
        }
        PerformanceDuration
        ClientPrices {
          Normal
          Exception
        }
        DefaultPrices {
          __typename
          Normal
          Exception
        }
        Price {
          __typename
          Normal
          Exception
        }
        Bookings {
          ID
          EventType
          EventDate
          PriceForClient
          PriceForArtist
          Line1
          Line2
          PostalCode
          City
          Country
          IsMaterialNeeded
          StartTime
          Artist {
            ID
            Email
            Firstname
            Status
            ArtisteNicknameGenerated
            Lastname
            PhoneNumber
            URLProfilePictures
            IsPaid
            ProfilePicturesCropsOptions
          }
          Client {
            Email
            Firstname
            Lastname
            PhoneNumber
          }
        }
        IsActive
        IsBanned
        IsEmailValid
        IsPhoneNumberConfirmed
        IsStripeSetup
        ChildrenPermission
        createdAt
        Gender
        Latitude
        Longitude
        Activity
        ActivityInformations{
          __typename
          ... on Animator {
            NumberOfClientSupported
          }
          ... on Band {
            NumberOfMembers
          }
          ... on CircusArtist {
            NumberOfTricks
          }
          ... on Dancer {
            NumberOfMembers
          }
          ... on Doppleganger {
            NumberOfCostumes
          }
          ... on Hypnotiser {
            NumberOfTricks
          }
          ... on Magician {
            NumberOfTricks
          }
          ... on MakeupArtist {
            NumberOfClientSupported
          }
          ... on Mentalist {
            NumberOfTricks
          }
          ... on Puppeteer {
            NumberOfTricks
          }
        }
      }
      ... on ArtistNotFound {
        message
        status
      }
      ... on Unauthorized {
        message
        status
      }
      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
