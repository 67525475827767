import ACTIVITIES			from './activities';


export default [
	{
		label: 'activities.Animator',
		value: ACTIVITIES.animator,
	},
	{
		label: 'activities.MakeupArtist',
		value: ACTIVITIES.makeupArtist,
	},
	{
		label: 'activities.CircusArtist',
		value: ACTIVITIES.circusArtist,
	},
	{
		label: 'activities.Singer',
		value: ACTIVITIES.singer,
	},
	{
		label: 'activities.SingerMusician',
		value: ACTIVITIES.singerMusicien,
	},
	{
		label: 'activities.Dancer',
		value: ACTIVITIES.dancer,
	},
	{
		label: 'activities.Dj',
		value: ACTIVITIES.dj,
	},
	{
		label: 'activities.Band',
		value: ACTIVITIES.band,
	},
	{
		label: 'activities.Hypnotiser',
		value: ACTIVITIES.hypnotiser,
	},
	{
		label: 'activities.Magician',
		value: ACTIVITIES.magician,
	},
	{
		label: 'activities.Musician',
		value: ACTIVITIES.musicien,
	},
	{
		label: 'activities.Mentalist',
		value: ACTIVITIES.mentalist,
	},
	{
		label: 'activities.Doppleganger',
		value: ACTIVITIES.doppleganger,
	},
	{
		label: 'activities.Puppeteer',
		value: ACTIVITIES.puppeteer,
	},
];
