import VueRouter            from 'vue-router';

import navigationGuard      from './navigationGuard.js';
import Signin               from '../features/signin/components/p-Signin.vue';
import Dashboard            from '../features/dashboard/components/p-Dashboard.vue';
import SearchArtist         from '../features/searchArtist/components/p-SearchArtist.vue';
import ArtistProfile        from '../features/artistProfile/components/p-ArtistPublicProfile.vue';
import EditLanding          from '../features/editLanding/components/p-EditLanding.vue';
import AdminEvents          from '../features/adminEvents/components/p-AdminEvents.vue';
import Bookings             from '../features/bookings/components/p-Bookings.vue';
import Booking              from '../features/bookings/components/p-Booking.vue';


const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Signin',
      component: Signin,
      meta: {
        isNotAllowedWhenSignedIn: true,
      },
    },
    {
      path: '/dashboard',
      component: Dashboard,
      meta: {
        isAuthenticationRequired: true,
      },
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: AdminEvents,
        },
        {
          path: '/search/artist',
          name: 'SearchArtist',
          component: SearchArtist,
        },
        {
          path: '/search/artist/:id',
          name: 'ArtistProfile',
          component: ArtistProfile,
          props: true,
        },
        {
          path: '/bookings',
          name: 'Bookings',
          component: Bookings,
        },
        {
          path: '/bookings/:id',
          name: 'Booking',
          component: Booking,
          props: true,
          meta: {
            isGreyBackground: true,
          },
        },
        {
          path: '/landing',
          name: 'EditLanding',
          component: EditLanding,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop - 100,
        behavior: 'smooth',
      });
    }

    return savedPosition ?? { x: 0, y: 0 };
  }
});

router.beforeEach(navigationGuard);


export default router;
