<template>
  <section class="landing-testimonials__wrapper">
    <header :class="$mediaQueries.isDesktop ? 'mb-xl' : 'mb-md'">
      <Heading
        :level="'h2'"
        :tag="'h2'"
        :color="$mediaQueries.isDesktop ? 'white' : 'gradient'">
        {{ $t('artist.dashboard.landing.testimonials.title') }}
      </Heading>
    </header>

    <Flickity
      :options="flickityOptions"
      ref="flickity"
      class="landing-testimonials__testimonials-list">
      <div
        v-for="(comment, index) in comments"
        :key="`testimonial-${index}`"
        class="landing-testimonials__testimonials-item">
        <div class="landing-testimonials__testimonial-card">
          <Heading
            :level="'h5'"
            :tag="'span'">
            {{ comment.author }}
          </Heading>
          <Tag
            :color="'light'"
            style="grid-column: 3 / 4;">
            {{ comment.type || $t('common.artist') }}
          </Tag>
          <Paragraph class="landing-testimonials__testimonial-text">
            {{ comment.text || comment }}
          </Paragraph>
        </div>
      </div>
    </Flickity>

    <div>
      <Icon
        @click="() => $refs.flickity && $refs.flickity.previous()"
        :variant="'arrow-left-outline'"
        :color="$mediaQueries.isDesktop ? 'white' : 'black'"
        :size="'xs'"
        :tag="'button'"
        hasDarkBackdrop
        type="button"
        class="ml-auto mr-md">
      </Icon>
      <Icon
        @click="() => $refs.flickity && $refs.flickity.next()"
        :variant="'arrow-right-outline'"
        :color="$mediaQueries.isDesktop ? 'white' : 'black'"
        :size="'xs'"
        :tag="'button'"
        hasDarkBackdrop
        type="button"
        class="ml-base">
      </Icon>
    </div>
  </section>
</template>

<script>
import Flickity             from 'vue-flickity';

import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                 from '../../../components/atoms/Icon/a-Icon.vue';
import Tag                  from '../../../components/atoms/Tag/a-Tag.vue';
import FLICKITY_OPTIONS     from '../../../constants/flickity-options.js';


export default {
  name: 'o-LandingTestimonials',
  components: {
    Flickity,
    Heading,
    Paragraph,
    Icon,
    Tag,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    flickityOptions: FLICKITY_OPTIONS,
  }),
  computed: {
    testimonials() {
      return [
        {
          author: 'John Mcclaine (link)',
          type: 'Client (link)',
          image: 'https://photos.lci.fr/images/1280/720/amir-chanteur-1_thomas-samson-afp-48b4e7-1@1x.jpeg',
          text: 'Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum, quippe cum propter virtutem et probitatem etiam eos, quos numquam vidimus, quodam modo diligamus. Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. (link)',
        },
        {
          author: 'John Mcclaine (link)',
          type: 'Artiste (link)',
          image: 'https://photos.lci.fr/images/1280/720/amir-chanteur-1_thomas-samson-afp-48b4e7-1@1x.jpeg',
          text: 'Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum, quippe cum propter virtutem et probitatem etiam eos, quos numquam vidimus, quodam modo diligamus. Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. (link)',
        },
        {
          author: 'John Mcclaine (link)',
          type: 'Client (link)',
          image: 'https://photos.lci.fr/images/1280/720/amir-chanteur-1_thomas-samson-afp-48b4e7-1@1x.jpeg',
          text: 'Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum, quippe cum propter virtutem et probitatem etiam eos, quos numquam vidimus, quodam modo diligamus. Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. (link)',
        },
        {
          author: 'John Mcclaine (link)',
          type: 'Client (link)',
          image: 'https://photos.lci.fr/images/1280/720/amir-chanteur-1_thomas-samson-afp-48b4e7-1@1x.jpeg',
          text: 'Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum, quippe cum propter virtutem et probitatem etiam eos, quos numquam vidimus, quodam modo diligamus. Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. (link)',
        },
        {
          author: 'John Mcclaine (link)',
          type: 'Client (link)',
          image: 'https://photos.lci.fr/images/1280/720/amir-chanteur-1_thomas-samson-afp-48b4e7-1@1x.jpeg',
          text: 'Nihil est enim virtute amabilius, nihil quod magis adliciat ad diligendum, quippe cum propter virtutem et probitatem etiam eos, quos numquam vidimus, quodam modo diligamus. Ergo ego senator inimicus, si ita vultis, homini, amicus esse, sicut semper fui, rei publicae debeo. (link)',
        },
      ];
    },
  },
}

</script>

<style lang="scss">

.landing-testimonials {
  &__wrapper {
    grid-row: 6 / 7;
    display: flex;
    flex-direction: column;
    padding: var(--space-lg) var(--space-md);
    background-color: var(--color-grey-background);

    @media screen and ($desktop) {
      padding: var(--space-xl);
      background-color: var(--color-black);
    }
  }

  &__testimonials-list {
    margin: 0 calc(-1 * var(--space-md));
    margin-bottom: var(--space-xl);

    @media screen and ($desktop) {
      margin: 0 calc(-1 * var(--space-xl));
      margin-bottom: var(--space-xl);
    }
  }

  &__testimonials-item {
    width: 100%;
    margin-right: var(--space-lg);

    @media screen and ($desktop) {
      width: auto;
    }
  }

  &__testimonial-card {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-row-gap: var(--space-sm);
    grid-column-gap: var(--space-md);
    align-items: center;
    justify-items: end;
    padding: var(--space-lg);
    background-color: var(--color-white);
    border-radius: var(--rounded-xl);

    @media screen and ($desktop) {
      min-height: 300px;
      max-height: 300px;
      min-width: 450px;
      max-width: 450px;
    }
  }

  &__testimonial-image {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: var(--rounded-xl);
  }

  &__testimonial-text {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
  }
}

</style>
