<template>
  <MainLayout
    isFullScreen
    class="main-layout--signin">
    <section class="signin__wrapper">
      <Heading
        isTextCentered
        class="mb-xl">
        Arrière Guichet
      </Heading>
      <form @submit.prevent="onSubmit">
        <FieldInput
          v-model='email'
          :placeholder="'Entrer votre email'"
          :label="'Email'"
          isAutofocus
          id="email"
          class="mb-sm">
        </FieldInput>
        <FieldInput
          v-model='password'
          :placeholder="'Entrer votre password'"
          :label="'Password'"
          type="password"
          id="password"
          class="mb-md">
        </FieldInput>
        <Loader v-if="isLoading"></Loader>
        <Button
          v-else
          isFluid
          type="submit">
          Connexion
        </Button>
      </form>
    </section>
  </MainLayout>
</template>

<script>

import { mapActions }     from 'vuex';

import MainLayout         from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import FieldInput         from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button             from '../../../components/atoms/Button/a-Button.vue';
import Loader             from '../../../components/atoms/Loader/a-Loader.vue';


export default {
  name: 'p-Signin',
  components: {
    MainLayout,
    Heading,
    FieldInput,
    Button,
    Loader,
  },
  data: () => ({
    isLoading: false,
    email: '',
    password: '',
  }),
  methods: {
    ...mapActions({ signin: 'SIGN_IN' }),
    async onSubmit() {
      const input = {
        email: this.email,
        password: this.password,
      };

      try {
        this.isLoading = true;

        await this.signin(input);
      } finally {
        this.isLoading = false;
      }
    },
  }
}

</script>

<style lang="scss">

.signin {
  &__wrapper {
    min-width: 600px;
    margin: 0 auto;
  }
}

</style>
