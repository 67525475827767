<template>
  <section>
    <header class="mb-xl">
      <Heading>
        Bookings
      </Heading>
    </header>
    <VirtualizedList
      @incremented="currentBookingsPage++"
      :incrementPaginationBy="50"
      :items="bookingsByDate">
      <template #default="{ virtualizedListItems: items }">
        <ul class="bookings__list">
          <router-link
            v-for="(booking, index) in items"
            :to="{ name: 'Booking', params: { id: booking.ID } }"
            :key="`booking-${index}`">
            <ArtistBookingCard
              :booking="booking"
              :style="{ opacity: isCurrentOrFutureDate(booking.EventDate * 1000) ? 1 : 0.3 }">
              {{ bookings }}
            </ArtistBookingCard>
          </router-link>
        </ul>
      </template>
    </VirtualizedList>
  </section>
</template>

<script>

import {
  mapState,
  mapActions,
}                                     from 'vuex';

import Heading                        from '../../../components/atoms/Heading/a-Heading.vue';
import ArtistBookingCard              from '../../../components/molecules/ArtistBookingCard/m-ArtistBookingCard.vue';
import VirtualizedList                from '../../../components/renderless/VirtualizedList/r-VirtualizedList.vue';
import { isCurrentOrFutureDate }      from '../../../utils/dateUtils.js';

export default {
  name: 'p-Bookings',
  components: {
    Heading,
    ArtistBookingCard,
    VirtualizedList,
  },
  data: () => ({
    currentBookingsPage: 0,
  }),
  watch: {
    currentBookingsPage: {
      immediate: true,
      handler: 'fetchBookings',
    },
  },
  async created() {
    await this.fetchBookings();
  },
  computed: {
    ...mapState(['bookings']),
    bookingsByDate() {
      return [...this.bookings].sort((a, b) => b.EventDate - a.EventDate);
    },
  },
  methods: {
    ...mapActions({
      listBookings: 'LIST_BOOKINGS',
    }),
    isCurrentOrFutureDate,
    async fetchBookings() {
      await this.listBookings(this.currentBookingsPage);
    },
  },
}

</script>

<style lang="scss">

.bookings {
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-lg);
  }
}

</style>
