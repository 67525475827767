<template>
  <section class="artist-result-card__wrapper">
    <div class="artist-result-card__main-content">
      <div class="artist-result-card__profile-picture">
        <img
          v-if="artist.urlProfilePictures"
          :src="artist.urlProfilePictures"
        >
        <Icon
          v-else
          :variant="'user-outline'"
          :size="'lg'">
        </Icon>
      </div>
      <header>
        <Heading
          :tag="'h2'"
          :level="'h2'">
          {{ artist.firstname }}
        </Heading>
        <Paragraph>
          {{ artist.email }}
        </Paragraph>
        <Paragraph isBold>
          {{ $t(getActivityTranslation(artist.activity)) }}
        </Paragraph>
      </header>
      <Heading
        v-if="artist.priceNormal"
        :tag="'h3'"
        :level="'h1'">
        {{ artist.priceNormal }}
        <Paragraph
          :size="'sm'"
          :tag="'span'"
          class="mt-auto mb-xs ml-xxs">
          €
        </Paragraph>
      </Heading>
    </div>

    <footer class="artist-result-card__footer">
      <ul class="artist-result-card__tags-list">
        <li
          v-for="(tag, index) in tagsToDisplay"
          :key="`tag-${artist.objectID}-${index}`"
          class="artist-result-card__tags-item">
          <Tag :color="'neutral'">
            {{ tag }}
          </Tag>
        </li>
      </ul>
      <Icon
        :variant="'chevron-right-outline'"
        class="ml-auto">
      </Icon>
    </footer>
  </section>
</template>

<script>

import Icon                           from '../../atoms/Icon/a-Icon.vue';
import Paragraph                      from '../../atoms/Paragraph/a-Paragraph.vue';
import Heading                        from '../../atoms/Heading/a-Heading.vue';
import Tag                            from '../../atoms/Tag/a-Tag.vue';
import { getActivityTranslation }     from '../../../utils/i18nUtils.js';


export default {
  name: 'm-ArtistResultCard',
  components: {
    Icon,
    Paragraph,
    Heading,
    Tag,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tagsToDisplay() {
      const tags = [];

      for (let i = 0; i <= Math.min(this.artist?.tags?.length ?? 0, 5); i++) {
        i === 5
          ? tags.push('...')
          : this.artist?.tags?.[i] && tags.push(this.artist.tags[i]);
      }

      return tags;
    },
  },
  methods: {
    getActivityTranslation,
  },
}

</script>

<style lang="scss">

.artist-result-card {
  &__wrapper {
    display: flex;
    flex-direction: column;
    border-radius: var(--rounded-xl);
    box-shadow: 0 0 6px 4px hsla(0, 0%, 0%, .025);
  }

  &__main-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: var(--space-md);
    align-items: center;
    padding: var(--space-md) var(--space-lg);
  }

  &__profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 130px;
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);

    img {
      object-fit: cover;
      border-radius: var(--rounded-xl);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: var(--space-md) var(--space-lg);
    border-bottom-left-radius: var(--rounded-xl);
    border-bottom-right-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);
  }

  &__tags-list {
    grid-column: 1 / -1;
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }
}

</style>
