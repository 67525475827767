<template>
  <section class="landing-showreel__wrapper">
    <header class="landing-showreel__title">
      {{ $t('artist.dashboard.landing.video.title') }}
      <span class="landing-showreel__title-artist">
        {{ $t('artist.dashboard.landing.video.subtitle') }}
      </span>
    </header>
    <VideoMediaCard
      :maxVideoHeight="'100%'"
      :isRoundedBorders="$mediaQueries.isDesktop"
      :source="showreelURL">
    </VideoMediaCard>
  </section>
</template>

<script>

import VideoMediaCard     from '../../../components/molecules/VideoMediaCard/m-VideoMediaCard.vue';


export default {
  name: 'o-LandingShowreel',
  components: {
    VideoMediaCard,
  },
  props: {
    showreelURL: {
      type: String,
      required: true,
    },
  },
}

</script>

<style lang="scss">

.landing-showreel {
  &__wrapper {
    grid-row: 3 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--space-xl) 0;
  }

  &__title {
    font-size: var(--text-xxl);
    font-weight: var(--font-light);
    margin-bottom: var(--space-lg);
  }

  &__title-artist {
    margin-left: var(--space-xs);
    font-weight: var(--font-bold);
  }
}

</style>
