import gql      from 'graphql-tag';


export default gql`
  mutation unpublishArtist($input: UnpublishArtistInput!) {
    unpublishArtist(input: $input) {
      __typename

      ... on Artist {
        IsActive
      }

      ... on ArtistNotFound {
        message
        status
      }

      ... on AlgoliaError {
        message
        Status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
