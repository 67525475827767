<template>
  <section class="artist-profile-events-history__wrapper">
    <header>
      <Heading
        :level="'h2'"
        :tag="'h2'"
        class="mb-sm">
        Événements artiste
      </Heading>
    </header>
    <Loader
      v-if="isLoading"
      :height="200">
    </Loader>
    <Paragraph v-if="!sortedByDateEvents || !sortedByDateEvents.length">Aucun événement</Paragraph>
    <template v-else>
      <ol class="artist-profile-events-history__events-list">
        <li
          v-for="(event, index) in sortedByDateEvents"
          :key="`breadcrumb-level-${index}`"
          class="artist-profile-events-history__event">
          <Icon
            :variant="'plus-outline'"
            :isCircled="true"
            :size="'xxs'"
            :color="'dark'"
            class="mx-xs">
          </Icon>
          <Paragraph
            isBold>
            {{ event.EventType | formatEvent }}
          </Paragraph>
          <Paragraph
            class="ml-auto"
            isCapitalized
            isBold>
            {{ event.Date | formatDate }}
          </Paragraph>
        </li>
      </ol>
    </template>
  </section>
</template>

<script>

import Loader                   from '../../../components/atoms/Loader/a-Loader.vue';
import Heading                  from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                     from '../../../components/atoms/Icon/a-Icon.vue';
import Paragraph                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import {
  getDateAsLocalizedString,
}                               from '../../../utils/dateUtils.js';


export default {
  name: 'o-ArtistProfileEventsHistory',
  components: {
    Loader,
    Heading,
    Icon,
    Paragraph,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    sortedByDateEvents() {
      return [...this?.events]?.sort((a, b) => Date.parse(new Date(b.Date)) - Date.parse(new Date(a.Date)))
        ?? [];
    },
  },
  filters: {
    formatDate(value) {
      return getDateAsLocalizedString({ date: new Date(value) })
    },
    formatEvent(value) {
      switch (value) {
        case "BANNED":
          return "BANNIE";
        case "UNBANNED":
          return "DÉ-BANNIE";
        case "PUBLISH":
          return "PUBLIÉ";
        case "UNPUBLISH":
          return "DÉ-PUBLIÉ";
        case "NEW_ACCOUNT":
          return "CREATION DU COMPTE";
        case "NEW_BOOKING":
          return "NOUVEAU BOOKING";
        default:
          console.log("Unexpected event", value)
          return value
      }
    },
  },
}

</script>

<style lang="scss">

.artist-profile-events-history {
  &__wrapper {
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);
  }

  &__events-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
    max-height: 300px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  &__event {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-xxs);

    &:not(:last-child) {
      padding-bottom: var(--space-xs);
      border-bottom: 1px solid var(--color-grey-semi);
    }
  }

  &__actions-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }
}

</style>
