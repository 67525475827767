<template>
  <div
    id="app"
    ref="app">
    <router-view name="default"></router-view>
    <Toaster></Toaster>
  </div>
</template>

<script>

import {
  mapState,
  mapActions,
}                 from 'vuex';

import Toaster      from './components/molecules/Toaster/m-Toaster.vue';


export default {
  name: 'App',
  components: {
    Toaster,
  },
  computed: {
    ...mapState(['isSignedIn']),
  },
  watch: {
    '$route'() {
      this.handleBackgroundColorSetting();
    },
    isSignedIn: {
      immediate: true,
      handler(isSignedIn) {
        if (isSignedIn) this.subscribeToAdminEvents();
      },
    },
  },
  mounted() {
    this.handleBackgroundColorSetting();
  },
  methods: {
    ...mapActions({
      subscribeToAdminEvents: 'SUBSCRIBE_TO_ADMIN_EVENTS',
    }),
    handleBackgroundColorSetting() {
      const color = this.$route.meta.isGreyBackground
        ? 'var(--color-grey-background)'
        : 'var(--color-white)';

      this.$refs.app.style.backgroundColor = color;
      document.body.backgroundColor = color;
    },
  },
}
</script>

<style>
</style>
