export default [
  {
    value: 'banner',
    label: 'Artistes en bannière',
  },
  {
    value: 'video',
    label: 'Showreel vidéo',
  },
  {
    value: 'featured',
    label: 'Artistes en lumière',
  },
  {
    value: 'comments',
    label: 'Avis client',
  },
];
