<template>
  <section class="artist-profile-agenda__wrapper">
    <header>
      <Heading
        :tag="'h3'"
        :level="'h3'"
        class="mb-md">
        Calendrier
      </Heading>
      <Paragraph
        v-if="!isPublic"
        class="mb-lg">
        Définissez vos tarifs en fonction de l'intensité de votre activité. Votre tarif standard correspond aux jours de faible activité. Votre tarif majoré correspond aux jours où vous avez de nombreuses demandes. Votre tarif spécial correspond aux dates spéciales pour vous (fêtes de fin d'année, fêtes religieuses, anniversaire etc.) 
        Pensez à inclure déplacement et frais de bouche dans vos tarifs.
      </Paragraph>
    </header>

    <div
      v-if="!isPublic"
      class="mb-xl">
      <form
        @submit.prevent="handleEditPricing"
        class="artist-profile-agenda__pricings-form"
        novalidate>
        <FieldInput
          v-for="(pricing, index) in pricingOptions"
          v-model="localPricings[pricing.name]"
          :label="`${pricing.label}*`"
          :key="`pricing-${index}`"
          :id="`pricing-${index}`"
          hasLeftIcons>
          <template #icons-left>
            <span class="artist-profile-agenda__currency-sign">€</span>
          </template>
        </FieldInput>
        <template v-if="isEditingPricing">
          <Loader
            v-if="isLoading"
            :height="100"
            class="ml-md">
          </Loader>
          <div
            v-else
            class="artist-profile-agenda__profile-agenda-pricing-submit-wrapper">
            <Button
              type="submit"
              class="mr-sm">
              {{ $t('common.save') }}
            </Button>
            <Button
              @click="prefillPricings"
              isWhite
              type="button">
              {{ $t('common.cancel') }}
            </Button>
          </div>
        </template>
      </form>

      <TextLink>
        Calculer mon cachet (trad)
      </TextLink>
    </div>

    <ArtistAgenda
      :isPublic="isPublic"
      id="artist-agenda">
    </ArtistAgenda>
    <hr
      v-if="isPublic"
      class="artist-profile-agenda__separator"/>
  </section>
</template>

<script>

import {
  mapGetters,
  mapMutations,
}                       from 'vuex';

import Heading          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FieldInput       from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import TextLink         from '../../../components/atoms/TextLink/a-TextLink.vue';
import Button           from '../../../components/atoms/Button/a-Button.vue';
import Loader           from '../../../components/atoms/Loader/a-Loader.vue';
import ArtistAgenda     from '../../artistAgenda/components/o-ArtistAgenda.vue';


export default {
  name: 'o-ArtistProfileAgenda',
  components: {
    Heading,
    Paragraph,
    FieldInput,
    TextLink,
    Loader,
    Button,
    ArtistAgenda,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    localPricings: {
      normal: 0,
      exception: 0,
    }
  }),
  computed: {
    ...mapGetters({
      pricings: 'PRICINGS',
    }),
    isEditingPricing() {
      return !Object.entries(this.pricingOptions).every(([ name, { value } ]) => Number(this.localPricings[name]) === value);
    },
    pricingOptions() {
      const getPricing = (pricingType) => {
        if (!this.pricings.length) {
          return 0
        }
        return Number(this.pricings.find(({ type }) => type === pricingType).price) ?? 0
      }

      return {
        normal: {
          name: 'normal',
          value: getPricing('normal'),
          label: 'Tarif standard (trad)',
          field: 'PriceNormal',
        },
        exception: {
          name: 'exception',
          value: getPricing('exception'),
          label: 'Tarif spécial (trad)',
          field: 'PriceException',
        },
      };
    },
  },
  created() {
    this.prefillPricings();
  },
  methods: {
    ...mapMutations({ addErrors: 'ADD_ERRORS' }),
    prefillPricings() {
      Object
        .values(this.pricingOptions)
        .forEach(({ name, value }) => this.localPricings[name] = value);
    },
    async handleEditPricing() {
      const input = Object
        .values(this.pricingOptions)
        .reduce((acc, pricing) => ({ ...acc, [pricing.field]: Number(this.localPricings[pricing.name]) }), {});
      const params = { input };

      await this.editIdentity(params);
    },
  }
}

</script>

<style lang="scss">

.artist-profile-agenda {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__pricings-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      flex-direction: row;
      flex-wrap: wrap;

      > * {
        flex: 1 1 0;
      }
    }

    > *:not(:last-child) {
      margin-bottom: var(--space-base);

      @media screen and ($desktop) {
        margin-right: var(--space-base);
        margin-bottom: 0;
      }
    }
  }

  &__profile-agenda-pricing-submit-wrapper {
    display: flex;
    align-items: center;
    margin: var(--space-md) 0;

    @media screen and ($desktop) {
      margin-top: var(--space-lg);
      flex: 1 0 100%;
    }
  }

  &__currency-sign {
    font-size: var(--text-xxs);
    font-style: italic;
    color: var(--color-grey-neutral);
  }

  &__separator {
    height: 1px;
    border: none;
    background-color: var(--color-grey-semi);
  }
}

</style>
