export default [
    {
        color: 'base',
        label: 'common.pricingsList.base'
    },
    {
        color: 'exceptional',
        label: 'common.pricingsList.exceptional'
    },
    {
        color: 'unavailable',
        label: 'common.pricingsList.unavailable'
    }
];
