import gql from 'graphql-tag';


export default gql`
query ($artistID: String!) {
  getArtistEvents(artistID: $artistID) {
    __typename
    ... on Events {
      Events {
        ID
        EventType
        IsRead
        Description
        Date
      }
    }
  }
}
`;
