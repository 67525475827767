export default [
  {
    activity: "Singer",
    questions: [
      'common.faqList.chooseSong',
      'common.faqList.learnSong',
      'common.faqList.playInstrument',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "Musicien",
    questions: [
      'common.faqList.chooseSong',
      'common.faqList.learnSong',
      'common.faqList.playInstrument',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "SingerMusicien",
    questions: [
      'common.faqList.chooseSong',
      'common.faqList.learnSong',
      'common.faqList.playInstrument',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "Band",
    questions: [
      'common.faqList.bandComposition',
      'common.faqList.chooseSong',
      'common.faqList.learnSong',
      'common.faqList.playInstrument',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "DJ",
    questions: [
      'common.faqList.chooseSong',
      'common.faqList.playInstrument',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "Animator",
    questions: [
      'common.faqList.activityExamples',
      'common.faqList.spectatorsLimit',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "Dancer",
    questions: [
      'common.faqList.chooseDanceSong',
      'common.faqList.customChoreography',
      'common.faqList.performanceSpace',
      'common.faqList.setupTime',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.performanceOutfit',
    ]
  },
  {
    activity: "CircusArtist",
    questions: [
      'common.faqList.specialities',
      'common.faqList.customPerformance',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
    ]
  },
  {
    activity: "Doppleganger",
    questions: [
      'common.faqList.customPerformance',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
      'common.faqList.characterOutfit',
    ]
  },
  {
    activity: "MakeupArtist",
    questions: [
      'common.faqList.productsUse',
      'common.faqList.executableTechniques',
      'common.faqList.kidsMakeup',
      'common.faqList.customeMakeupTheme',
      'common.faqList.henna',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
    ]
  },
  {
    activity: "Puppeteer",
    questions: [
      'common.faqList.charactersStories',
      'common.faqList.customPerformance',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
    ]
  },
  {
    activity: "Magician",
    questions: [
      'common.faqList.chooseTricks',
      'common.faqList.specialities',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
    ]
  },
  {
    activity: "Mentalist",
    questions: [
      'common.faqList.chooseTricks',
      'common.faqList.spectatorsLimit',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
    ]
  },
  {
    activity: "Hypnotiser",
    questions: [
      'common.faqList.chooseTricks',
      'common.faqList.spectatorsLimit',
      'common.faqList.setupTime',
      'common.faqList.performanceSpace',
      'common.faqList.communicationMeans',
      'common.faqList.communicationTiming',
      'common.faqList.equipementDetails',
      'common.faqList.equipementSpecificity',
    ]
  }
];
