<template>
  <section class="booking__wrapper">
    <header>
      <div class="booking__backlink">
        <Icon
          @click="$router.back()"
          :variant="'arrow-left-outline'"
          :tag="'button'"
          :size="'xs'"
          :color="'white'"
          hasBlackBackdrop
          type="button"
          class="mr-md">
        </Icon>
        <Heading
          :level="'h3'"
          :tag="'h3'"
          class="py-sm">
          {{ $t('common.back') }}
        </Heading>
      </div>
      <Heading
        :level="'h2'"
        :tag="'h1'"
        :color="isBookingCanceled ? 'black' : 'gradient'"
        style="display: inline-block;"
        class="mb-md">
        {{
          isBookingCanceled
            ? $t('artist.dashboard.client.booking.details.canceledBooking')
            : $t('artist.dashboard.client.booking.details.confirmedBooking')
        }}
        {{ (bookingTarget.Firstname || currentArtist.Firstname) && `${$t('common.with')} ${bookingTarget.Firstname || $t('common.artist')}` }}
        {{
          `${$t('common.the')} ${bookingDate}!`
        }}
      </Heading>
    </header>

    <div class="booking__sidebar">
      <div class="booking__sticky">
        <BookingDetailsCard
          :artist="booking.Artist"
          :booking="booking"
          :isBookingCanceled="isBookingCanceled">
        </BookingDetailsCard>
        <Button
          v-if="needsPayment"
          @click="markBookingAsPaid(booking.ID)"
          class="mt-md">
          Marquer comme payé
        </Button>
        <Heading
          v-else
          :level="'h3'"
          :color="'valid'"
          class="mt-md">
          <Icon
            :variant="'valid-solid'"
            :color="'valid'"
            class="mr-sm">
          </Icon>
          Booking payé
        </Heading>
      </div>
    </div>

    <dl class="booking__fields">
      <div
        v-for="(bookingField, index) in bookingFields"
        :key="`booking-field-${index}`">
        <dt class="booking__fields-label">
          {{ bookingField.label }}
        </dt>
        <dd class="booking__fields-value">
          {{ bookingField.value }}
        </dd>
      </div>
    </dl>

    <div class="booking__targets-wrapper">
      <div class="booking__target-infos-wrapper">
        <Heading
          :level="'h2'"
          class="mb-sm">
          Artiste
        </Heading>
        <div class="booking__client">
          <ArtistCard :artist="booking.Artist"></ArtistCard>
          <template v-if="booking.Artist.Firstname">
            <div class="
              booking__client-info-item
              booking__client-info-item--telephone
            ">
              <Icon
                :variant="'chat-outline'"
                class="ml-auto">
              </Icon>
              <Paragraph
                @click.native.stop
                :tag="'a'"
                :href="`tel:${booking.Artist.PhoneNumber}`"
                isBold>
                {{ booking.Artist.PhoneNumber }}
              </Paragraph>
            </div>
            <div class="
              booking__client-info-item
              booking__client-info-item--email
            ">
              <Icon
                :variant="'chat-outline'"
                class="ml-auto">
              </Icon>
              <Paragraph
                @click.native.stop
                :tag="'a'"
                :href="`mailto:${booking.Artist.Email}`"
                isBold>
                {{ booking.Artist.Email }}
              </Paragraph>
            </div>
          </template>
        </div>
      </div>
      <div class="booking__target-infos-wrapper">
        <Heading
          :level="'h2'"
          class="mb-sm">
          Client
        </Heading>
        <div class="booking__client">
          <Icon :variant="'user-outline'"></Icon>
          <template v-if="booking.Client.Firstname">
            <Heading
              :tag="'h3'"
              :level="'h3'"
              isCapitalized>
              {{ booking.Client.Firstname }}
            </Heading>
            <div class="
              booking__client-info-item
              booking__client-info-item--telephone
            ">
              <Icon
                :variant="'chat-outline'"
                class="ml-auto">
              </Icon>
              <Paragraph
                @click.native.stop
                :tag="'a'"
                :href="`tel:${booking.Client.PhoneNumber}`"
                isBold>
                {{ booking.Client.PhoneNumber }}
              </Paragraph>
            </div>
            <div class="
              booking__client-info-item
              booking__client-info-item--email
            ">
              <Icon
                :variant="'chat-outline'"
                class="ml-auto">
              </Icon>
              <Paragraph
                @click.native.stop
                :tag="'a'"
                :href="`mailto:${booking.Client.Email}`"
                isBold>
                {{ booking.Client.Email }}
              </Paragraph>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import {
  mapState,
  mapActions,
}                                 from 'vuex';

import Heading                    from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                  from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                       from '../../../components/atoms/Icon/a-Icon.vue';
import Button                     from '../../../components/atoms/Button/a-Button.vue';
import BookingDetailsCard         from '../../../components/molecules/BookingDetailsCard/m-BookingDetailsCard.vue';
import ArtistCard                 from '../../../components/molecules/ArtistCard/m-ArtistCard.vue';
import {
  getDateAsLocalizedString,
  isCurrentOrFutureDate,
}                                 from '../../../utils/dateUtils.js';
import AVAILABILITIES_OPTIONS     from '../../../constants/availabilities-options.js';
import STATUSES_OPTIONS           from '../../../constants/statuses.js';


export default {
  name: 'p-Booking',
  components: {
    Heading,
    Paragraph,
    Icon,
    Button,
    BookingDetailsCard,
    ArtistCard,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    invoiceURL: '',
  }),
  computed: {
    ...mapState(['bookings']),
    booking() {
      return this.bookings.find(({ ID }) => ID === this.id) ?? {};
    },
    bookingTarget() {
      return this.booking?.Client;
    },
    bookingDate() {
      return this.getDateAsLocalizedString({
        date: new Date(this.booking.EventDate * 1000),
        locale: 'fr',
        options: { day: '2-digit', month: 'long', year: 'numeric', },
      });
    },
    isBookingPast() {
      return Date.parse(new Date()) > (this.booking.EventDate * 1000);
    },
    bookingFields() {
      return [
        {
          label: this.$t('common.address'),
          value: `${this.booking.Line1}, ${this.booking.Line2}`,
        },
        {
          label: this.$t('common.city'),
          value: this.booking.City,
        },
        {
          label: this.$t('common.zipCode'),
          value: this.booking.PostalCode,
        },
        {
          label: this.$t('common.country'),
          value: this.booking.Country,
        },
        {
          label: this.$t('common.startTime'),
          value: this.booking.StartTime,
        },
        {
          label: this.$t('common.endTime'),
          value: `${this.booking.EndTime}`,
        },
        {
          label: this.$t('artist.dashboard.client.booking.details.numberOfPeople'),
          value: this.booking.NumberOfPeople,
        },
      ];
    },
    isShowBookingInfos() {
      return this.bookingTarget &&
        this.booking.Status?.toUpperCase() === AVAILABILITIES_OPTIONS.BOOKED &&
        isCurrentOrFutureDate(this.booking.EventDate * 1000);
    },
    isBookingCanceled() {
      return this.booking.Status?.toUpperCase() === AVAILABILITIES_OPTIONS.CANCELED;
    },
    intermittentStatus() {
      return STATUSES_OPTIONS.find(({ value }) => value === 'INTERMITTENT');
    },
    needsPayment() {
      return !this.booking.Artist.IsPaid && (this.booking.Artist.Status === this.intermittentStatus.value);
    },
  },
  methods: {
    ...mapActions({
      getInvoice: 'GET_INVOICE',
      markBookingAsPaid: 'MARK_BOOKING_AS_PAID',
    }),
    getDateAsLocalizedString,
  },
}

</script>

<style lang="scss">

.booking {
  &__wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: var(--space-lg) var(--space-xl);
    padding-top: var(--space-lg);
    padding-bottom: var(--space-xl);

    @media screen and ($desktop) {
      grid-template-columns: 1fr minmax(350px, auto);
      grid-template-rows: repeat(3, min-content);
    }
  }

  &__breadcrumb {
    grid-column: 1 / -1;
  }

  &__backlink {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-lg);
  }

  &__sidebar {
    grid-row: 3 / 4;

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-row: 1 / -2;
      margin-top: calc(var(--space-xl) + var(--space-sm));
    }
  }

  &__sticky {
    position: sticky;
    top: calc(var(--space-header-height) + var(--space-lg));
  }

  &__fields {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-column-gap: var(--space-xxl);
    grid-row-gap: var(--space-lg);
    margin-bottom: var(--space-md);
    padding: 0 var(--space-sm);
    font-family: var(--font-stack-secondary);

    @media screen and ($desktop) {
      grid-row: 2 / 3;
      padding: 0;
    }
  }

  &__fields-label {
    margin-bottom: var(--space-xxs);
    font-size: var(--text-sm);
  }

  &__fields-value {
    font-size: var(--text-sm);
    font-weight: var(--font-bold);
  }

  &__equipement {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-base);
    padding: var(--space-md);
    border-radius: var(--rounded-xs);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      grid-row: 4 / 5;
    }
  }

  &__client {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: min-content 1fr auto min-content;
    grid-gap: var(--space-base);
    align-items: center;
    padding: var(--space-lg) var(--space-md);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      grid-template-columns: min-content 1fr auto auto;
      padding-right: var(--space-xl);
    }
  }

  &__client-info-item {
    display: flex;
    align-items: center;
    column-gap: var(--space-sm);
    grid-column: 1 / -1;
    margin-right: auto;

    @media screen and ($desktop) {
      grid-column: 3 / 4;
    }

    &--telephone {
      @media screen and ($desktop) {
        grid-row: 1 / 2;
      }
    }

    &--email {
      @media screen and ($desktop) {
        grid-row: 2 / 3;
      }
    }
  }

  &__hint {
    grid-column: 1 / -1;

    @media screen and ($desktop) {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
      padding: 0 var(--space-xxl);
    }
  }

  &__loader {
    @media screen and ($desktop) {
      grid-column: 4 / 5;
    }
  }

  &__targets-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
  }

  &__target-infos-wrapper {
    display: flex;
    flex-direction: column;
  }
}

</style>
