<template>
  <!-- :style="{ 'background-image': `url(${currentArtistBanner.MediaURL})`}" -->
  <section class="landing-search__wrapper">
    <header class="landing-search__hero-header">
      <Heading
        :tag="'h1'"
        :level="'h1'"
        :color="'white'"
        class="landing-search__main-heading">
        {{ $t('artist.dashboard.landing.searchHeader.catchPhrases.main')}}
      </Heading>
      <Heading
        :tag="'h2'"
        :level="'h5'"
        :color="'white'"
        class="landing-search__secondary-heading">
        {{ $t('artist.dashboard.landing.searchHeader.catchPhrases.secondary')}}
      </Heading>
    </header>
    <!-- TODO -->
    <div
      v-if="false"
      class="landing-search__hero-artist-infos">
      <Icon
        :variant="'plus-outline'"
        :color="'white'"
        class="mr-md">
      </Icon>
      <Paragraph
        :color="'white'"
        class="mr-xs">
        Michel (link)
      </Paragraph>
      <Paragraph
        :color="'white'"
        isBold>
        Chanteur et musicien (link)
      </Paragraph>
    </div>
    <form
      v-if="false"
      @submit.prevent
      novalidate
      class="landing-search__search-bar-wrapper">
      <div class="landing-search__search-bar-activity-wrapper">
        <Heading
          :tag="'label'"
          :level="'h5'"
          for="search-activity"
          class="landing-search__search-bar-activity-label">
          Je cherche (trad)
        </Heading>
        <input
          :placeholder="'Une chanteuse (trad)'"
          type="text"
          class="landing-search__search-bar-activity-input"
          id="search-activity"
          />
      </div>
      <div class="landing-search__search-bar-location-wrapper">
        <Heading
          :tag="'label'"
          :level="'h5'"
          for="search-location"
          class="landing-search__search-bar-location-label">
          Lieu (trad)
        </Heading>
        <input
          :placeholder="`Lieu de l'évènement (trad)`"
          type="text"
          class="landing-search__search-bar-location-input"
          id="search-location"
          />
      </div>
      <button
        type="submit"
        class="landing-search__search-bar-submit">
        <Icon
          :variant="'search-outline'"
          :color="'white'">
        </Icon>
      </button>
    </form>

    <div
      class="landing-search__ghost-header"
      ref="ghost">
    </div>
  </section>
</template>

<script>

import Icon                 from '../../../components/atoms/Icon/a-Icon.vue';
import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import HeroImage            from '../../../../public/assets/images/landing/hero.jpg';


export default {
  name: 'o-LandingSearchHeader',
  components: {
    Icon,
    Heading,
    Paragraph,
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    currentArtistBannerIndex: 0,
    observer: null,
    heroImage: HeroImage,
  }),
  computed: {
    currentArtistBanner() {
      return this.banners?.[this.currentArtistBannerIndex] ?? '';
    },
  },
}

</script>

<style lang="scss">

.landing-search {
  &__wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 50% auto;
    align-items: end;
    min-height: 90vmax;
    max-height: 90vmax;
    background: url('../../../../public/assets/images/artiste/landing-hero.jpg');
    background-position: center;
    background-size: cover;
    padding: var(--space-base);
    padding-bottom: var(--space-lg);

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: hsla(0, 0%, 0%, .25);
    }

    @media screen and ($desktop) {
      grid-template-rows: auto 60% 1fr;
      min-height: 70vmin;
      max-height: 70vmin;
      padding: var(--space-xl) var(--space-xxl);
      padding-top: var(--space-xxl);
    }
  }

  &__hero-header {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-self: start;
    justify-content: center;
    margin-top: var(--space-lg);
    margin-right: var(--space-md);
  }

  &__main-heading {
    margin-bottom: var(--space-sm);
    font-size: 48px;
    line-height: 1;

    @media screen and ($desktop) {
      max-width: 70%;
      margin-bottom: var(--space-md);
      font-size: 80px;
      line-height: 1;
    }
  }

  &__secondary-heading {
    font-size: 16px;

    @media screen and ($desktop) {
      font-size: 28px;
    }
  }

  &__hero-artist-infos {
    grid-row: 3 / 4;
    display: flex;
    align-items: center;

    * {
      text-shadow: 0 0 5px hsla(0, 0%, 0%, .25);
    }
  }

  &__hero-slider-nav-wrapper {
    grid-row: 3 / 4;
    display: flex;
    align-items: center;
    justify-self: end;
    width: 150px;
  }

  &__hero-slider-nav-item {
    flex: 1 0 auto;
    height: 2px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-neutral);

    &:not(:last-child) {
      margin-right: var(--space-xs);
    }

    &--active {
      flex: 2 0 auto;
      background-color: var(--color-white);
    }
  }

  &__search-bar-wrapper {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-self: center;
    min-width: 50%;
    padding: var(--space-xs);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
    font-family: var(--font-stack-secondary);
  }

  &__search-bar-activity-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 40%;
    padding-left: var(--space-md);
    padding-right: var(--space-lg);
    border-right: 1px solid var(--color-grey-semi);
  }

  &__search-bar-activity-label {
    font-weight: var(--font-bold);
  }

  &__search-bar-activity-input {
    position: relative;
    top: -2px;
    background-color: transparent;
    outline: none;

    &::placeholder {
      font-size: var(--text-xs);
    }
  }

  &__search-bar-location-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 40%;
    padding: 0 var(--space-lg);
  }

  &__search-bar-location-input {
    position: relative;
    top: -2px;
    background-color: transparent;
    outline: none;

    &::placeholder {
      font-size: var(--text-xs);
    }
  }

  &__search-bar-submit {
    display: flex;
    align-items: center;
    padding: var(--space-xs);
    border-radius: var(--rounded-circle);
    background-color: var(--color-black);
  }

  &__artist-cta {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;

    button {
      padding: var(--space-lg) var(--space-xxl);
      margin-top: var(--space-lg);
    }
  }

  &__ghost-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    visibility: hidden;
  }
}

</style>
