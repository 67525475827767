<template>
  <MainLayout class="main-layout--artist-public-profile">
    <Loader
      v-if="isLoading"
      :height="$mediaQueries.isDesktop ? 800 : 700"
      class="artist-public-profile__loader">
    </Loader>

    <template v-else>
      <ArtistProfileHeader
        :artist="currentArtist"
        :isPreview="true"
        isPublic>
      </ArtistProfileHeader>
      <ArtistProfileInfos
         :artist="currentArtist"
         isPublic>
       </ArtistProfileInfos>
       <ArtistProfilePerformance
         :artist="currentArtist"
         isPublic>
       </ArtistProfilePerformance>
       <div class="artist-public-profile__sidebar">
         <div class="artist-public-profile__sticky">
           <ArtistProfilePricing :artist="currentArtist"></ArtistProfilePricing>
           <ArtistProfileDescription :artist="currentArtist"/>
           <ArtistProfileAdminActions :artist="currentArtist"/>
           <ArtistProfileEventsHistory :events="artistEvents"/>
          <ArtistProfilePrivateDocuments :artist="currentArtist"/>
         </div>
       </div>
       <ArtistProfileMedias
         v-if="hasMedias"
         :artist="currentArtist"
         isPublic>
       </ArtistProfileMedias>
       <ArtistProfileFAQ
         :artist="currentArtist"
         isPublic>
       </ArtistProfileFAQ>
       <ArtistProfileAgenda isPublic/>
       <ArtistProfileRatings
         :artist="currentArtist"
         class="mt-lg"
       />
    </template>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapActions,
  mapGetters,
}                                    from 'vuex';

import MainLayout                    from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader                        from '../../../components/atoms/Loader/a-Loader.vue';
import ArtistProfileHeader           from './o-ArtistProfileHeader.vue';
import ArtistProfileInfos            from './o-ArtistProfileInfos.vue';
import ArtistProfilePerformance      from './o-ArtistProfilePerformance.vue';
import ArtistProfilePricing           from './o-ArtistProfilePricing.vue';
import ArtistProfileAdminActions     from './o-ArtistProfileAdminActions.vue';
import ArtistProfileDescription      from './o-ArtistProfileDescription.vue';
import ArtistProfileMedias           from './o-ArtistProfileMedias.vue';
import ArtistProfileFAQ              from './o-ArtistProfileFAQ.vue';
import ArtistProfileAgenda           from './o-ArtistProfileAgenda.vue';
import ArtistProfileRatings          from './o-ArtistProfileRatings.vue';
import ArtistProfileEventsHistory    from './o-ArtistProfileEventsHistory.vue';
import ArtistProfilePrivateDocuments from './o-ArtistProfilePrivateDocuments.vue';


export default {
  name: 'p-ArtistPublicProfile',
  components: {
    MainLayout,
    Loader,
    ArtistProfileHeader,
    ArtistProfileInfos,
    ArtistProfilePerformance,
    ArtistProfilePricing,
    ArtistProfileAdminActions,
    ArtistProfileMedias,
    ArtistProfileFAQ,
    ArtistProfileAgenda,
    ArtistProfileRatings,
    ArtistProfileDescription,
    ArtistProfileEventsHistory,
    ArtistProfilePrivateDocuments
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,

  }),
  computed: {
    ...mapState(['currentArtist', 'artistEvents']),
    ...mapGetters({
      publicArtistVideos: 'MEDIAS_VIDEOS',
      publicArtistPictures: 'MEDIAS_IMAGES',
    }),
    hasMedias() {
      return Boolean(this.publicArtistPictures.length && this.publicArtistVideos.length);
    }
  },
  async created() {
    await this.fetchArtistProfile();
  },
  methods: {
    ...mapActions({
      fetchArtist: 'FETCH_ARTIST',
      getArtistEvents: 'GET_ARTIST_EVENTS',
    }),
    async fetchArtistProfile() {
      try {
        this.isLoading = true;

        await Promise.all([
          this.fetchArtist(this.id),
          this.getArtistEvents(this.id),
        ]);
      } catch (e) {
        console.log('error', e)
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">
.artist-public-profile {
  &__loader {
    grid-column: 1 / -1;
  }

  &__sidebar {
    grid-row: 3 / 4;

    @media screen and ($desktop) {
      grid-row: 2 / -1;
    }
  }

  &__sticky {
    position: sticky;
    top: var(--space-lg);

    > *:not(:last-child) {
      margin-bottom: var(--space-md);
    }
  }
}
</style>
