import { v4 as generateUUID }     from 'uuid';
import { getInitialState }        from './index.js';


const SET_IS_SIGNED_IN = (state, isSignedIn) => {
  state.isSignedIn = isSignedIn;
};

const SET_ADMIN = (state, admin) => {
  state.admin = { ...state.admin, ...admin };
};

const ADD_ERRORS = (state, errors = []) => {
  const errorsWithID = errors.map((error) => ({ ...error, id: generateUUID() }));

  state.errors.push(...errorsWithID);
};

const CLEAR_ERROR = (state, error) => {
  state.errors = state.errors.filter(({ id }) => id !== error.id);
};

const LOG_OUT = (state) => {
  const { admin, errors, isSignedIn } = getInitialState();

  state.admin = admin;
  state.errors = errors;
  state.isSignedIn = isSignedIn;
};

const SET_ACTIVE_FILTER = (state, filter = { name: '', payload: null }) => {
	state.filters = {
		...state.filters,
		[filter.name]: filter.payload
	};
};

const CLEAR_ACTIVE_FILTERS = (state) => {
	const { filters } = getInitialState();

	state.filters = filters;
};

const SET_CURRENT_ARTIST = (state, artist = {}) => {
  state.currentArtist = artist;
};

const SET_NEW_DESCRIPTION = (state, adminDescription) => {
  state.adminDescription = adminDescription.AdminDescription;
};

const TOGGLE_IS_AVAILABILITIES_LOADING = (state, isLoading) => {
  state.isAvailabilitiesLoading = isLoading;
};

const SET_LANDING_DATA = (state, landingData) => {
  state.landingData = landingData;
};

const SET_PENDING_LANDING_DATA = (state, pendingData) => {
  state.pendingLandingData = {
    ...state.pendingLandingData,
    ...pendingData,
  };
};

const CLEAR_PENDING_LANDING_DATA = (state) => {
  const { pendingLandingData } = getInitialState();

  state.pendingLandingData = pendingLandingData;
};

const SET_ARTISTS_BANNERS = (state, banners = []) => {
  state.artistsBanners = banners;
};

const SET_ARTIST_EVENTS = (state, events) => {
  state.artistEvents = events;
};

const SET_ADMIN_EVENTS = (state, events) => {
  state.adminEvents = events;
};

const SET_BOOKINGS = (state, bookings) => {
  state.bookings = bookings;
};

const TOGGLE_LANDING_HERO_VISIBILITY = (state, isVisible = false) => {
  state.isLandingHeroVisible = isVisible;
};

export default {
  SET_IS_SIGNED_IN,
  SET_ADMIN,
  ADD_ERRORS,
  CLEAR_ERROR,
  LOG_OUT,
  SET_ACTIVE_FILTER,
  CLEAR_ACTIVE_FILTERS,
  SET_CURRENT_ARTIST,
  TOGGLE_IS_AVAILABILITIES_LOADING,
  SET_LANDING_DATA,
  CLEAR_PENDING_LANDING_DATA,
  SET_PENDING_LANDING_DATA,
  SET_ARTISTS_BANNERS,
  SET_NEW_DESCRIPTION,
  SET_ARTIST_EVENTS,
  SET_ADMIN_EVENTS,
  SET_BOOKINGS,
  TOGGLE_LANDING_HERO_VISIBILITY,
};
