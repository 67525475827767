import gql      from 'graphql-tag';


export default gql`
  query getAdminEvents($page: Int!) {
    getAdminEvents(page: $page) {
      __typename

      ... on Events {
        Events {
          ID
          EventType
          IsRead
          Description
          Date
          ArtistID
          ArtistEmail
        }
      }
    }
  }
`;
