const getQueryParams = (paramsMap = {}) => Object.entries(paramsMap)
  .filter(([, value]) => value !== undefined && value !== null)
  .map(([key, value]) => typeof value === 'object'
    ? getQueryParams(value)
    : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  .join('&');

const getCloudinaryQueryParams = (paramsMap = {}) => {
  const params = Object.entries(paramsMap)
  .filter(([, value]) => value !== undefined && value !== null)
  .flatMap(([key, value]) => value.toString().startsWith(':') ? `${key}${value}` : `${key}_${value}`)
  .join(',');

  return params;
};

const getCroppedPictureURL = (baseURL, cropOptions = {}, additionalOptions = {}, chainedOptions = {}) => {
  if (!baseURL) return '';

  const defaultOptions = {
    'c': 'crop',
    'f': 'jpg',
    'q': '75',
    'fl_progressive': ':semi',
  };
  const options = {
    ...cropOptions,
    ...defaultOptions,
    ...additionalOptions,
  };
  const chainedParams = getCloudinaryQueryParams(chainedOptions);
  const params = getCloudinaryQueryParams(options);
  const url = chainedParams ? `upload/${params}/${chainedParams}` : `upload/${params}`

  return baseURL.replace('upload', url);
};

const getTrimmedVideoURL = (baseURL, options) => {
  const defaultOptions = {
    'f': 'mp4',
    'q': 'auto',
  };
  const params = getCloudinaryQueryParams({ ...defaultOptions, ...options });

  return baseURL.replace('upload', `upload/${params}`);
};


export {
  getQueryParams,
  getCroppedPictureURL,
  getTrimmedVideoURL,
};
