import { i18n } 														  from '../main.js';
import router                                 from '../router/router.js';
import apolloProvider                         from '../graphql/config.js';
import errorMessages                          from '../constants/errorMessages.js';
import SIGN_IN_MUTATION                       from '../features/signin/api/queries/q-AdminSignin.js';
import ADMIN_RESPONSE                         from '../features/signin/api/responses/r-AdminSignin.js';
import FETCH_ARTIST_QUERY                     from '../features/artistProfile/api/queries/q-fetchArtist.js';
import FETCH_ARTIST_RESPONSE                  from '../features/artistProfile/api/responses/r-fetchArtist.js';
import DELETE_ARTIST_MUTATION                 from '../features/artistProfile/api/queries/q-deleteArtist.js';
import DELETE_ARTIST_RESPONSE                 from '../features/artistProfile/api/responses/r-deleteArtist.js';
import ARTIST_PUBLIC_AVAILABILITIES_QUERY     from '../features/artistProfile/api/queries/q-publicAvailabilities.js';
import GET_LANDING_QUERY                      from '../features/editLanding/api/queries/q-getLanding.js';
import GET_ARTISTS_BANNERS_QUERY              from '../features/editLanding/api/queries/q-getArtistsBanners.js';
import GET_ARTISTS_BANNERS_QUERY_RESPONSE     from '../features/editLanding/api/responses/r-getArtistsBanners.js';
import GET_ADMIN_EVENTS_QUERY                 from '../features/adminEvents/api/queries/q-getAdminEvents.js';
import GET_ADMIN_EVENTS_RESPONSE              from '../features/adminEvents/api/responses/r-getAdminEvents.js';
import PUBLISH_ARTIST_MUTATION                from '../features/artistProfile/api/queries/q-publishArtist.js';
import PUBLISH_ARTIST_RESPONSE                from '../features/artistProfile/api/responses/r-publishArtist.js';
import UNPUBLISH_ARTIST_MUTATION              from '../features/artistProfile/api/queries/q-unpublishArtist.js';
import UNPUBLISH_ARTIST_RESPONSE              from '../features/artistProfile/api/responses/r-unpublishArtist.js';
import BAN_ARTIST_MUTATION                    from '../features/artistProfile/api/queries/q-banArtist.js';
import BAN_ARTIST_RESPONSE                    from '../features/artistProfile/api/responses/r-banArtist.js';
import UNBAN_ARTIST_MUTATION                  from '../features/artistProfile/api/queries/q-unbanArtist.js';
import UNBAN_ARTIST_RESPONSE                  from '../features/artistProfile/api/responses/r-unbanArtist.js';
import UPDATE_ARTIST_DESCRIPTION_MUTATION     from '../features/artistProfile/api/queries/q-updateArtistDescription';
import UPDATE_ARTIST_DESCRIPTION_RESPONSE     from '../features/artistProfile/api/responses/r-updateArtistDescription';
import GET_ARTIST_EVENTS_QUERY                from '../features/artistProfile/api/queries/q-getArtistEvents';
import GET_ARTIST_EVENTS_RESPONSE             from '../features/artistProfile/api/responses/r-getArtistEvents';
import EDIT_LANDING_MUTATION                  from '../features/editLanding/api/queries/q-editLanding.js';
import EDIT_LANDING_RESPONSE                  from '../features/editLanding/api/responses/r-editLanding.js';
import MARK_EVENT_AS_READ_MUTATION            from '../features/adminEvents/api/queries/q-markEventAsRead.js';
import MARK_EVENT_AS_READ_RESPONSE            from '../features/adminEvents/api/responses/r-markEventAsRead.js';
import ADMIN_EVENTS_SUBSCRIPTION              from '../features/adminEvents/api/queries/s-subscribeToAdminEvents.js';
import MARK_BOOKING_AS_PAID_QUERY             from '../features/adminEvents/api/queries/q-MarkBookingAsPaid.js';
import { MARK_BOOKING_AS_PAID_RESPONSE }      from '../features/adminEvents/api/responses/r-MarkBookingAsPaid.js';
import LIST_BOOKINGS_QUERY                    from '../features/bookings/api/queries/q-ListBookings.js';
import LIST_BOOKINGS_RESPONSE                 from '../features/bookings/api/responses/r-ListBookings.js';
import {
  UserNotFound,
  WrongPassword,
  WrongInput
}                                             from '../graphql/shared/responses/r-Errors.js';


const SIGN_IN = async ({ commit }, input) => {
  try {
    const data = await apolloProvider.mutate({
      mutation: SIGN_IN_MUTATION,
      variables: { input },
    });
    const {
      __typename: responseTypeName,
      ...loginMutationData
    } = data.data.loginAdmin;

    switch (responseTypeName) {
      case ADMIN_RESPONSE:
        commit('SET_ADMIN', loginMutationData);
        commit('SET_IS_SIGNED_IN', true);
        router.push({ name: 'Dashboard' });
        break;
      case UserNotFound:
      case WrongPassword:
      case WrongInput:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName], level: 'alert' }]);
        break;
      default:
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const UPDATE_ARTIST_DESCRIPTION = async ({ commit }, { artist = {}, description  = '' }) => {
   try {
    const data = await apolloProvider.mutate({
      mutation: UPDATE_ARTIST_DESCRIPTION_MUTATION,
      variables: {
        input: {
          artistID: artist.ID,
          description,
        }
      },
    });

    const {
      __typename: responseTypeName,
      ...adminDescription
    } = data.data.updateArtistDescription;

    switch (responseTypeName) {
      case UPDATE_ARTIST_DESCRIPTION_RESPONSE.artist:
        commit('SET_NEW_DESCRIPTION', adminDescription);
        break;
      case UPDATE_ARTIST_DESCRIPTION_RESPONSE.artistNotFound:
      case UPDATE_ARTIST_DESCRIPTION_RESPONSE.Unauthorized:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName], level: 'alert' }]);
        break;
      case UPDATE_ARTIST_DESCRIPTION_RESPONSE.tokenError:
        commit('SET_IS_SIGNED_IN', false);
        break;
      default:
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
}

const FETCH_ARTIST = async ({ commit }, artistId) => {
  try {
    const data = await apolloProvider.query({
      query: FETCH_ARTIST_QUERY,
      variables: { id: artistId },
    });
    const {
      __typename: responseTypeName,
      ...artistData
    } = data.data.findArtist;

    switch (responseTypeName) {
      case FETCH_ARTIST_RESPONSE.artist:
        commit('SET_CURRENT_ARTIST', { ...artistData, Availabilities: artistData?.Availabilities?.availabilities ?? [] });
        break;
      case FETCH_ARTIST_RESPONSE.artistNotFound:
      case FETCH_ARTIST_RESPONSE.unauthorized:
      case FETCH_ARTIST_RESPONSE.tokenError:
      default:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName || 'UnknownError'], level: 'alert' }]);
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const FETCH_ARTISTS = async ({ commit }, artistsIds) => {
  try {
    const data = await Promise.all(
      artistsIds.map(id => apolloProvider.query({
        query: FETCH_ARTIST_QUERY,
        variables: { id },
      })
    ));
    const artistsData = data
      .map(({ data: { findArtist } }) => findArtist)
      .filter(({ __typename }) => __typename === FETCH_ARTIST_RESPONSE.artist);

    return artistsData;
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const FETCH_ARTIST_PUBLIC_AVAILABILITIES = async ({ state, commit }, params) => {
  try {
		commit('TOGGLE_IS_AVAILABILITIES_LOADING', true);

		const data = await apolloProvider.query({
			query: ARTIST_PUBLIC_AVAILABILITIES_QUERY,
			variables: params,
			fetchPolicy: 'network-only',
    });
		const publicAvailabilities = data.data.publicAvailabilities;
		const hasPublicAvailabilities = publicAvailabilities?.length;

		hasPublicAvailabilities
			? commit('SET_CURRENT_ARTIST', { ...state.currentArtist, Availabilities: publicAvailabilities })
			: commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
	} catch (error) {
		commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
	} finally {
		commit('TOGGLE_IS_AVAILABILITIES_LOADING', false);
	}
};

const GET_LANDING = async ({ commit }) => {
  try {
		const data = await apolloProvider.query({
			query: GET_LANDING_QUERY,
    });
    const {
      __typename: responseTypeName, // eslint-disable-line
      ...landingData
    } = data.data.getLanding;

    commit('SET_LANDING_DATA', landingData);
	} catch (error) {
		commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
	}
};

const EDIT_LANDING = async ({ commit }, input) => {
  try {
		const data = await apolloProvider.mutate({
			mutation: EDIT_LANDING_MUTATION,
			variables: { input },
    });
    const {
      __typename: responseTypeName,
      ...landingData
    } = data.data.updateLanding;

    switch (responseTypeName) {
      case EDIT_LANDING_RESPONSE.landing:
        commit('CLEAR_PENDING_LANDING_DATA');
        commit('SET_LANDING_DATA', landingData);
        break;
      case EDIT_LANDING_RESPONSE.unauthorized:
      case EDIT_LANDING_RESPONSE.tokenError:
      default:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName], level: 'alert' }]);
        break;
    }
	} catch (error) {
		commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
	}
};

const GET_ARTISTS_BANNERS = async ({ commit }) => {
  try {
		const data = await apolloProvider.query({
			query: GET_ARTISTS_BANNERS_QUERY,
    });
    const {
      __typename: responseTypeName,
      ...bannersData
    } = data.data.listBanners;

    switch (responseTypeName) {
      case GET_ARTISTS_BANNERS_QUERY_RESPONSE.banners:
        commit('SET_ARTISTS_BANNERS', bannersData.Banners);
        break;
      case GET_ARTISTS_BANNERS_QUERY_RESPONSE.tokenError:
      case GET_ARTISTS_BANNERS_QUERY_RESPONSE.unauthorized:
      default:
        commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
        break;
    }
	} catch (error) {
		commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
	}
};

const TOGGLE_PUBLISH_ARTIST = async ({ state, commit }, { artist = {}, reason  = '' }) => {
  const isArtistCurrentlyPublished = artist.IsActive;
  const params = isArtistCurrentlyPublished
    ? { input: { artistID: artist.ID, reason } }
    : { id: artist.ID };

  try {
    const data = await apolloProvider.mutate({
      mutation: isArtistCurrentlyPublished
        ? UNPUBLISH_ARTIST_MUTATION
        : PUBLISH_ARTIST_MUTATION,
      variables: params,
    });
    const { '__typename': responseTypeName, ...publishArtistData } = isArtistCurrentlyPublished
      ? data.data.unpublishArtist
      : data.data.publishArtist;

    switch (responseTypeName) {
      case PUBLISH_ARTIST_RESPONSE.artist:
      case UNPUBLISH_ARTIST_RESPONSE.artist:
        commit('SET_CURRENT_ARTIST', { ...state.currentArtist, ...publishArtistData });
        break;

      case PUBLISH_ARTIST_RESPONSE.pricesShouldBeSet:
      case PUBLISH_ARTIST_RESPONSE.stripeIsNotSetup:
      case PUBLISH_ARTIST_RESPONSE.algoliaError:
      case PUBLISH_ARTIST_RESPONSE.tokenError:
      case PUBLISH_ARTIST_RESPONSE.unauthorized:
      default:
        commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const TOGGLE_BAN_ARTIST = async ({ state, commit }, { artist = {}, reason  = '' }) => {
  const isArtistCurrentlyBanned = artist.IsBanned;
  const params = isArtistCurrentlyBanned
    ? { id: artist.ID }
    : { input: { artistID: artist.ID, reason } };

  try {
    const data = await apolloProvider.mutate({
      mutation: isArtistCurrentlyBanned
        ? UNBAN_ARTIST_MUTATION
        : BAN_ARTIST_MUTATION,
      variables: params,
    });
    const { '__typename': responseTypeName, ...banArtistData } = isArtistCurrentlyBanned
      ? data.data.unbanArtist
      : data.data.banArtist;

    switch (responseTypeName) {
      case BAN_ARTIST_RESPONSE.artist:
      case UNBAN_ARTIST_RESPONSE.artist:
        commit('SET_CURRENT_ARTIST', { ...state.currentArtist, ...banArtistData });
        break;

      case PUBLISH_ARTIST_RESPONSE.pricesShouldBeSet:
      case PUBLISH_ARTIST_RESPONSE.stripeIsNotSetup:
      case PUBLISH_ARTIST_RESPONSE.algoliaError:
      case PUBLISH_ARTIST_RESPONSE.tokenError:
      case PUBLISH_ARTIST_RESPONSE.unauthorized:
      default:
        commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const MARK_EVENT_AS_READ = async ({ state,commit }, eventID) => {
  try {
    const data = await apolloProvider.mutate({
      mutation: MARK_EVENT_AS_READ_MUTATION,
      variables: { id: eventID },
    });
    const { '__typename': responseTypeName, ...eventReadData } = data.data.eventRead;

    switch (responseTypeName) {
      case MARK_EVENT_AS_READ_RESPONSE.event:
        commit('SET_ADMIN_EVENTS', [...state.adminEvents.filter(({ ID }) => ID !== eventReadData.ID), eventReadData ]);
        break;

      case MARK_EVENT_AS_READ_RESPONSE.eventNotFound:
      case MARK_EVENT_AS_READ_RESPONSE.tokenError:
      case MARK_EVENT_AS_READ_RESPONSE.unauthorized:
      default:
        commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const GET_ARTIST_EVENTS = async ({ commit }, artistID) => {
   try {
    const data = await apolloProvider.query({
      query: GET_ARTIST_EVENTS_QUERY,
      variables: {
        artistID: artistID,
      },
    });

    const {
      __typename: responseTypeName,
      ...events
    } = data.data.getArtistEvents;

    switch (responseTypeName) {
      case GET_ARTIST_EVENTS_RESPONSE.events:
        commit('SET_ARTIST_EVENTS', events.Events ?? []);
        break;
      case GET_ARTIST_EVENTS_RESPONSE.eventNotFound:
      case GET_ARTIST_EVENTS_RESPONSE.Unauthorized:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName], level: 'alert' }]);
        break;
      case GET_ARTIST_EVENTS_RESPONSE.tokenError:
        commit('SET_IS_SIGNED_IN', false);
        break;
      default:
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const GET_ADMIN_EVENTS = async ({ state, commit }, page = 0) => {
   try {
    const data = await apolloProvider.query({
      query: GET_ADMIN_EVENTS_QUERY,
      variables: { page },
    });

    const {
      __typename: responseTypeName,
      ...events
    } = data.data.getAdminEvents;
    const updatedEvents = [
      ...(page > 0 ? state.adminEvents : []),
      ...events?.Events ?? []
    ];

    switch (responseTypeName) {
      case GET_ADMIN_EVENTS_RESPONSE.events:
        commit('SET_ADMIN_EVENTS', updatedEvents);
        break;
      case GET_ADMIN_EVENTS_RESPONSE.Unauthorized:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName], level: 'alert' }]);
        break;
      case GET_ADMIN_EVENTS_RESPONSE.tokenError:
        commit('SET_IS_SIGNED_IN', false);
        break;
      default:
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const SUBSCRIBE_TO_ADMIN_EVENTS = ({ state, commit }) => {
	const subscription = apolloProvider.subscribe({
		query: ADMIN_EVENTS_SUBSCRIPTION,
	});

	subscription.subscribe({
		next({ data = {} }) {
      if (data?.AdminEvents) {
				const updatedAdminEvents = [
          ...state.adminEvents.filter(({ ID }) => ID !== data.AdminEvents.ID),
          data.AdminEvents,
        ];

				commit('SET_ADMIN_EVENTS', updatedAdminEvents);
			}
		},
		error() {
			commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
		},
	});
};

const DELETE_ARTIST = async ({ commit }, params) => {
  try {
    const data = await apolloProvider.mutate({
      mutation: DELETE_ARTIST_MUTATION,
      variables: params,
    });

    const { __typename: responseTypeName, } = data.data.deleteArtist;

    switch (responseTypeName) {
      case DELETE_ARTIST_RESPONSE.operationInformation:
        break;
      case DELETE_ARTIST_RESPONSE.artistNotFound:
      case DELETE_ARTIST_RESPONSE.Unauthorized:
        commit('ADD_ERRORS', [{ message: errorMessages[responseTypeName], level: 'alert' }]);
        break;
      case DELETE_ARTIST_RESPONSE.tokenError:
        commit('SET_IS_SIGNED_IN', false);
        break;
      default:
        break;
    }
  } catch (error) {
    commit('ADD_ERRORS', [{ message: errorMessages.UnknownError, level: 'alert' }]);
  }
};

const MARK_BOOKING_AS_PAID = async ({ state, commit }, bookingID) => {
  try {
		const data = await apolloProvider.mutate({
			mutation: MARK_BOOKING_AS_PAID_QUERY,
			variables: { bookingID },
    });
		const { '__typename': responseTypeName, ...markBookingAsPaidData } = data.data.markBookingAsPaid;

		switch (responseTypeName) {
			case MARK_BOOKING_AS_PAID_RESPONSE.booking:
				commit('SET_BOOKINGS', [...state.bookings.filter(({ ID }) => ID !== markBookingAsPaidData.ID), markBookingAsPaidData]);
				break;
			case MARK_BOOKING_AS_PAID_RESPONSE.bookingNotFound:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t(`client.booking.errors.${responseTypeName}`) }], { root: true });
				break;
			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};

const LIST_BOOKINGS = async ({ state, commit }, page = 0) => {
  try {
		const data = await apolloProvider.query({
      query: LIST_BOOKINGS_QUERY,
      variables: { page },
    });
		const { '__typename': responseTypeName, ...listBookingsData } = data.data.listBookings;

		switch (responseTypeName) {
			case LIST_BOOKINGS_RESPONSE.bookings:
				commit('SET_BOOKINGS', [...(page > 0 ? state.bookings : []), ...listBookingsData.Bookings]);
				break;
			case LIST_BOOKINGS_RESPONSE.tokenError:
			case LIST_BOOKINGS_RESPONSE.unauthorized:
			default:
				commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
				break;
		}
	} catch (error) {
		commit('ADD_ERRORS', [{ type: 'alert', message: i18n.t('common.errors.UnknownError') }], { root: true });
	}
};


export default {
  SIGN_IN,
  FETCH_ARTIST,
  FETCH_ARTISTS,
  FETCH_ARTIST_PUBLIC_AVAILABILITIES,
  GET_ARTISTS_BANNERS,
  GET_LANDING,
  TOGGLE_PUBLISH_ARTIST,
  TOGGLE_BAN_ARTIST,
  EDIT_LANDING,
  MARK_EVENT_AS_READ,
  SUBSCRIBE_TO_ADMIN_EVENTS,
  UPDATE_ARTIST_DESCRIPTION,
  GET_ARTIST_EVENTS,
  GET_ADMIN_EVENTS,
  DELETE_ARTIST,
  MARK_BOOKING_AS_PAID,
  LIST_BOOKINGS,
};
