<template>
  <div
    :style="wrapperStyle"
    class="loader__wrapper">
    <div :class="[loaderStyle, 'loader__loader']"></div>
  </div>
</template>

<script>

export default {
  name: 'a-Loader',
  props: {
    size: {
      type: String,
      default: 'base',
      validator: size => ['xs', 'sm', 'base', 'lg', 'xl', 'xxl'].includes(size),
    },
    color: {
      type: String,
      default: 'black',
      validator: color => ['black', 'white'].includes(color),
    },
    height: {
      type: [Number, String],
      default: 'auto'
    }
  },
  data: () => ({
    sizes: {
      xs: 16,
      sm: 32,
      base: 48,
      lg: 68,
      xl: 68,
      xxl: 68,
    },
  }),
  computed: {
    wrapperStyle() {
      return {
        height: this.height === 'auto'
          ? 'auto'
          : `${this.height}px`
      };
    },
    loaderStyle() {
      return {
        [`loader__loader--${this.size}`]: true,
        [`loader__loader--${this.color}`]: true,
      };
    }
  },
}

</script>

<style lang="scss">

$sizes: (
  'xs': 16px,
  'sm': 32px,
  'base': var(--space-main-height),
  'lg': 68px,
  'xl': 68px,
  'xxl': 68px,
);

$colors: (
  'white': var(--color-white),
  'black': var(--color-black),
);

.loader {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loader {
    display: block;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    border: 2px rgba(0, 0, 0, 0.25) solid;
    border-top: 2px black solid;
    border-right: 2px black solid;
    border-bottom: 2px black solid;
    border-radius: 50%;
    animation: spin 1s infinite linear;

    @each $size-key, $size-value in $sizes {
      &--#{$size-key} {
        height: $size-value;
        width: $size-value;
      }
    }

    @each $color-key, $color-value in $colors {
      &--#{$color-key} {
        border-top-color: $color-value;
        border-right-color: $color-value;
        border-bottom-color: $color-value;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

</style>
