<template>
  <AgendaCalendar
    v-if="isPublic"
    v-bind="$attrs"
    @availability-selected="handleAvailabilitySelected"
    @month-changed="handleFetchArtistPublicAvailabilities"
    :availabilities="publicAvailabilities"
    :isLoading="isAvailabilitiesLoading"
    isPublic
    isGreyHeader>
  </AgendaCalendar>
  <AgendaCalendar
    v-else
    v-bind="$attrs"
    @month-changed="fetchAvailabilities"
    @availabilities-changed="editAvailabilities"
    :availabilities="availabilities"
    :isLoading="isAvailabilitiesLoading"
    isShowTags>
  </AgendaCalendar>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
}                             from 'vuex';

import AgendaCalendar         from '../../../components/molecules/AgendaCalendar/m-AgendaCalendar.vue';


export default {
  name: 'o-ArtistAgenda',
  components: {
    AgendaCalendar,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['currentArtist', 'isAvailabilitiesLoading']),
    ...mapGetters({
      publicAvailabilities: 'CALENDAR_AVAILABILITIES',
    }),
  },
  methods: {
    ...mapMutations({ setSelectedAvailabilities: 'SearchArtist/SET_SELECTED_AVAILABILITIES' }),
    ...mapActions({
      fetchArtistPublicAvailabilities: 'FETCH_ARTIST_PUBLIC_AVAILABILITIES',
    }),
    async handleFetchArtistPublicAvailabilities(date) {
      const params = {
        input: {
          artistID: this.currentArtist.ID,
          date,
        },
      };

      await this.fetchArtistPublicAvailabilities(params);
    },
    handleAvailabilitySelected(dayProps) {
      const availabilityAttributes = dayProps.attributes && dayProps.attributes[dayProps.attributes.length - 1];

      if (!availabilityAttributes) return;

      const newSelectedAvailability = {
        type: availabilityAttributes.key,
        date: availabilityAttributes.customData.EventDate * 1000,
        id: availabilityAttributes.customData.Id
      };
      const shouldDeselectAvailability = this.selectedAvailability?.date === newSelectedAvailability.date;
      const selectedAvailability = shouldDeselectAvailability ? {} : newSelectedAvailability;

      this.setSelectedAvailabilities(selectedAvailability);
    }
  }
}

</script>

<style lang="scss">
</style>
