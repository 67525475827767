<template>
  <MainLayout
    isFullScreen
    class="main-layout--landing-page">
      <LandingSearchHeader :banners="banners"></LandingSearchHeader>
      <LandingCategories></LandingCategories>
      <LandingShowreel :showreelURL="showreelURL"></LandingShowreel>
      <LandingArtistsList :artists="artists"></LandingArtistsList>
      <LandingJoinArtist></LandingJoinArtist>
      <LandingTestimonials :comments="comments"></LandingTestimonials>
  </MainLayout>
</template>

<script>

import { mapActions }               from 'vuex';

import MainLayout                   from '../../../components/layout/MainLayout/l-MainLayout.vue';
import LandingCategories            from './o-LandingCategories.vue';
import LandingSearchHeader          from './o-LandingSearchHeader.vue';
import LandingShowreel              from './o-LandingShowreel.vue';
import LandingArtistsList           from './o-LandingArtistsList.vue';
import LandingJoinArtist            from './o-LandingJoinArtist.vue';
import LandingTestimonials          from './o-LandingTestimonials.vue';


export default {
  name: 'p-Landing',
  components: {
    MainLayout,
    LandingCategories,
    LandingSearchHeader,
    LandingShowreel,
    LandingArtistsList,
    LandingJoinArtist,
    LandingTestimonials,
  },
  props: {
    showreelURL: {
      type: String,
      required: true,
    },
    banners: {
      type: Array,
      required: true,
    },
    artists: {
      type: Array,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
  },
  async created() {
    await this.getLandingData();
  },
  methods: {
    ...mapActions({
      getLandingData: 'GET_LANDING',
    }),
  }
}

</script>

<style lang="scss">
</style>
