import gql from 'graphql-tag';


export default gql`
  mutation deleteArtist ($id: String!) {
    deleteArtist(id: $id) {
      ... on OperationInformation {
        message
        status
      }

      ... on ArtistNotFound {
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
