import { COMMON_API_RESPONSES }     from '../../../../constants/common-api-responses.js';


export const MARK_BOOKING_AS_PAID_RESPONSE = {
  // Success
  booking: 'Booking',
  // Managed Failures
  bookingNotFound: 'BookingNotFound',
  // Errors
  ...COMMON_API_RESPONSES,
};
