<template>
  <section>
    <form @submit.prevent>
      <header class="search-artist__header">
        <Heading :level="'h2'">
          Recherche artiste
        </Heading>
        <Icon
          v-if="activeFiltersCounter > 0"
          @click="clearFilters"
          :variant="'delete-solid'"
          :tag="'button'"
          :color="'white'"
          hasBlackBackdrop
          type="button">
        </Icon>
      </header>

      <InstantSearch
        hasFilters
        class="search-artist__wrapper">
        <fieldset class="search-artist__controls">
          <div class="search-artist__calendar-wrapper">
            <v-calendar
              v-model="date"
              :dates="selectedDates"
              @dayclick="handleDateInput"
              :attributes="selectedDates">
            </v-calendar>
            <ul>
              <li
                v-for="(date, index) in selectedDates"
                :key="`artist-date-${index}`"
                class="mb-xs ml-xs">
                <Tag :color="'light'">
                  {{ date.label }}
                  <template #actions>
                    <Icon
                      @click="deleteDate(date)"
                      :variant="'cross-outline'"
                      :tag="'button'"
                      :size="'xxs'"
                      :color="'neutral'"
                      type="button"
                      class="ml-xxs">
                    </Icon>
                  </template>
                </Tag>
              </li>
            </ul>
          </div>
          <FieldInput
            v-model="artistFreeInfos"
            id="search-artist"
            :label="'Recherche un artiste'"
            :placeholder="'Email, nom ou prénom...'"
            :isShowClearButton="!!artistFreeInfos">
          </FieldInput>
          <div>
            <FieldInput
              v-model="artistTag"
              id="artist-tag"
              :label="'Recherche de tag'"
              :placeholder="`Tags de l'artiste`"
              hasRightIcons
              class="mb-sm">
              <template #icons-right>
                <Icon
                  @click="addTag"
                  :variant="'plus-outline'"
                  :tag="'button'"
                  :size="'xs'"
                  isCircled>
                </Icon>
              </template>
            </FieldInput>
            <ul class="search-artist__tags-list">
              <li
                v-for="(tag, index) in filters.tags"
                :key="`artist-tag-${index}`"
                class="search-artist__tag-item">
                <Tag :color="'light'">
                  {{ tag }}
                  <template #actions>
                    <Icon
                      @click="deleteTag(tag)"
                      :variant="'cross-outline'"
                      :tag="'button'"
                      :size="'xxs'"
                      :color="'neutral'"
                      type="button"
                      class="ml-xxs">
                    </Icon>
                  </template>
                </Tag>
              </li>
            </ul>
          </div>
          <Select
            v-model="artistActivity"
            :options="activitiesOptions"
            :label="'Activité'"
            id="activity">
          </Select>
          <Select
            v-model="artistStatus"
            :options="statusesOptions"
            :label="'Statut administratif'"
            id="status">
          </Select>
          <div class="search-artist__pricings">
            <FieldInput
              v-model="artistPricing"
              :label="'Tarif maximum'"
              :placeholder="'Tarif maximum'"
              type="number"
              id="artist-pricing"
              hasLeftIcons>
              <template #icons-left>
                <span>€</span>
              </template>
            </FieldInput>
          </div>
          <GoogleLocationFinder
            @input="handleAddressInput"
            @place-selected="handleSetAddress"
            @clear-place="setFilters({ name: 'localisation', payload: {} });"
            :options="{ types: ['(regions)'] }"
            :label="$t('common.address')"
            :placeholder="$t('common.address')"
            :prefilledText="filters.localisation.formatted_address || ''"
            hasBordersRadiuses
            class="search-artist__location">
          </GoogleLocationFinder>
          <Checkbox
            v-model="isArtistPublished"
            :label="`Afficher que les artistes publiés`"
            id="artist-published"
            class="mt-sm">
          </Checkbox>
          <Checkbox
            v-model="isArtistNotPublished"
            :label="`Afficher que les artiste pas encore publiés`"
            id="artist-not-published"
            class="mt-sm">
          </Checkbox>
          <Checkbox
            v-model="isArtistBanned"
            :label="`Afficher que les artiste bannis`"
            id="artist-banned"
            class="mt-sm">
          </Checkbox>
        </fieldset>
        <ais-hits
          :transformItems="handleTransformItems"
          class="search-artist__results">
          <template #default="{ items: artists }">
            <div class="search-artist__results-infos">
              <ais-stats>
                <template #default="{ nbHits }">
                  <Heading :level="'h2'">
                    {{ nbHits }}
                    <Paragraph
                      :size="'md'"
                      class="ml-sm">
                      résultats
                    </Paragraph>
                  </Heading>
                </template>
              </ais-stats>
              <ais-pagination
                :show-first="false"
                :show-last="false"
                ref="pagination">
                <template #default="{ pages, currentRefinement, refine }">
                  <ul class="search-artist__results-pagination-list">
                    <li
                      v-for="page in pages"
                      :key="page">
                      <button
                        @click="refine(page)"
                        :class="[
                          { 'search-artist__results-pagination-item--selected': currentRefinement === page },
                          'search-artist__results-pagination-item',
                        ]"
                        type="button">
                        {{ page + 1 }}
                      </button>
                    </li>
                  </ul>
                </template>
              </ais-pagination>
            </div>
            <ul
              class="search-artist__results-list">
              <li v-if="!artists || (artists && !artists.length)">
                <Paragraph
                  isTextCentered
                  isBold
                  class="my-xl">
                  Aucun résultat
                </Paragraph>
              </li>
              <template v-else>
                <li
                  v-for="(artist, index) in artists"
                  :key="`artist-${index}`">
                  <router-link
                    :to="{ name: 'ArtistProfile', params: { id: artist.objectID } }"
                    target="_blank">
                    <ArtistResultCard :artist="artist"></ArtistResultCard>
                  </router-link>
                </li>
              </template>
            </ul>
            <ais-pagination
              :show-first="false"
              :show-last="false"
              ref="pagination"
              class="mt-md">
              <template #default="{ pages, currentRefinement, refine }">
                <ul class="search-artist__results-pagination-list">
                  <li
                    v-for="page in pages"
                    :key="page">
                    <button
                      @click="refine(page)"
                      :class="[
                        { 'search-artist__results-pagination-item--selected': currentRefinement === page },
                        'search-artist__results-pagination-item',
                      ]"
                      type="button">
                      {{ page + 1 }}
                    </button>
                  </li>
                </ul>
              </template>
            </ais-pagination>
          </template>
        </ais-hits>
      </InstantSearch>
    </form>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
}                                       from 'vuex';

import FieldInput                       from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Icon                             from '../../../components/atoms/Icon/a-Icon.vue';
import Heading                          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Tag                              from '../../../components/atoms/Tag/a-Tag.vue';
import Select                           from '../../../components/atoms/Select/a-Select.vue';
import Checkbox                         from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import GoogleLocationFinder             from '../../../components/molecules/GoogleLocationFinder/m-GoogleLocationFinder.vue';
import InstantSearch                    from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import ArtistResultCard                 from '../../../components/molecules/ArtistResultCard/m-ArtistResultCard.vue';
import ACTIVITIES_OPTIONS               from '../../../constants/activityOptions.js';
import STATUSES_OPTIONS                 from '../../../constants/statuses.js';
import {
  getDateAsLocalizedString,
  getDateWithTimezoneOffset,
}                                       from '../../../utils/dateUtils.js';


export default {
  name: 'p-SearchArtist',
  components: {
    FieldInput,
    Icon,
    Heading,
    Paragraph,
    Tag,
    Select,
    Checkbox,
    GoogleLocationFinder,
    InstantSearch,
    ArtistResultCard,
  },
  data: () => ({
    activitiesOptions: [
      {
        label: 'Aucune',
      },
      ...ACTIVITIES_OPTIONS,
    ],
    statusesOptions: [
      {
        label: 'Aucun',
      },
      ...STATUSES_OPTIONS,
    ],
    artistTag: '',
    artistTags: [],
    artistFreeInfosSearch: '',
    date: new Date(),
    latitude: '',
    longitude: '',
    errors: [],
  }),
  computed: {
    ...mapState(['filters']),
    ...mapGetters({
      algoliaFilters: 'ALGOLIA_FILTERS',
      activeFiltersCounter: 'ACTIVE_FILTERS_COUNTER',
      isGeolocationFilterActive: 'IS_GEOLOCALISATION_FILTER_ACTIVE',
    }),
    artistFreeInfos: {
      get() {
        return this.filters.query;
      },
      set(value) {
        this.setFilters({ name: 'query', payload: value });
      },
    },
    artistActivity: {
      get() {
        return this.filters.activity;
      },
      set(value) {
        this.setFilters({ name: 'activity', payload: value });
      },
    },
    artistStatus: {
      get() {
        return this.filters.status;
      },
      set(value) {
        this.setFilters({ name: 'status', payload: value });
      },
    },
    artistPricing: {
      get() {
        return this.filters.pricing;
      },
      set(value) {
        this.setFilters({ name: 'pricing', payload: value });
      },
    },
    isArtistPublished: {
      get() {
        return this.filters.isPublished;
      },
      set(value) {
        this.setFilters({ name: 'isPublished', payload: value });
      },
    },
    isArtistNotPublished: {
      get() {
        return this.filters.isNotPublished;
      },
      set(value) {
        this.setFilters({ name: 'isNotPublished', payload: value });
      },
    },
    isArtistBanned: {
      get() {
        return this.filters.isBanned;
      },
      set(value) {
        this.setFilters({ name: 'isBanned', payload: value });
      },
    },
    selectedDates() {
      return this.filters.unavailabilities
        .map(date => ({
          dates: date,
          label: this.getDateAsLocalizedString({ date: new Date(date * 1000) }),
          highlight: {
            class: 'date-active',
          },
        }))
        .sort((a, b) => a.dates - b.dates);
    },
  },
  methods: {
    ...mapMutations({
      setFilters: 'SET_ACTIVE_FILTER',
      clearFilters: 'CLEAR_ACTIVE_FILTERS',
    }),
    getDateAsLocalizedString,
    addTag() {
      const isValidTag = this.artistTag && this.artistTags.every(tag => tag.toLowerCase() !== this.artistTag.toLowerCase());

      if (!isValidTag) return;

      this.artistTags.push(`"${this.artistTag}"`);
      this.artistTag = '';
      this.setFilters({ name: 'tags', payload: [...this.artistTags] });
    },
    deleteTag(tagToDelete) {
      this.artistTags = this.artistTags.filter(tag => tag !== tagToDelete);
      this.setFilters({ name: 'tags', payload: [...this.artistTags] });
    },
    handleDateInput(day) {
      const selectedDate = getDateWithTimezoneOffset(day.date);
      const existingDayIndex = this.filters.unavailabilities.findIndex(date => date === selectedDate);

      const payload = existingDayIndex >= 0
        ? [...this.filters.unavailabilities].filter(date => date !== selectedDate)
        : [...this.filters.unavailabilities, getDateWithTimezoneOffset(new Date(day.date))];

      this.setFilters({ name: 'unavailabilities', payload });
    },
    deleteDate(day) {
      const payload = this.filters.unavailabilities.filter(date => date !== day.dates)

      this.setFilters({ name: 'unavailabilities', payload });
    },
    handleAddressInput() {
      this.latitude = '';
      this.longitude = '';
    },
    handleSetAddress(place) {
      const geometry = {
        ...place,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();
      this.errors = [];
      this.setFilters({ name: 'localisation', payload: geometry });
    },
    handleTransformItems(artists) {
      const shouldResetPagination = this.$refs?.pagination?.state?.canRefine && !artists.length;
      const filteredArtists = this.isGeolocationFilterActive
        // Matched geolocation distance is in meters but artists locationRange is in kilometers
        ? artists.filter(artist => artist?._rankingInfo?.matchedGeoLocation?.distance / 100 <= artist.locationRange)
        : artists;

      shouldResetPagination && this.$refs?.pagination?.refine(0);

      return filteredArtists;
    },
  },
}

</script>

<style lang="scss">

.search-artist {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xl);

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-column-gap: var(--space-lg);
      align-items: start;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-lg);
  }

  &__controls {
    flex: 0 1 50%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: var(--space-base);

    > * {
      flex: 0 1 calc(50% - var(--space-xs));
    }
  }

  &__tags-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 0 50%;
  }

  &__tag-item {
    margin-bottom: var(--space-xs);

    &:not(:last-child) {
      margin-right: var(--space-sm);
    }
  }

  &__pricings {
    flex: 1 1 auto;
    display: flex;
    column-gap: var(--space-sm);
  }

  &__location {
    flex: 1 0 100%;
  }

  &__calendar-wrapper {
    display: flex;
    flex: 1 0 100%;
  }

  &__results {
    flex: 1 0 50%;
  }

  &__results-infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-md);
  }

  &__results-pagination-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: var(--space-xxs);
    margin-left: var(--space-xxl);
  }

  &__results-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-size: var(--text-base);
    background-color: var(--color-grey-semi);
    border-radius: var(--rounded-sm);
    width: 24px;
    height: 24px;

    &--selected {
      background-color: var(--color-black);
    }

    &--previousPage,
    &--nextPage {
      margin: 0 var(--space-xxs);
      font-size: var(--text-lg);
      font-weight: var(--font-medium);
      color: var(--color-black);
      background-color: transparent;
    }
  }

  &__results-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
  }
}

</style>
