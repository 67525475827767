<template>
  <section class="landing-join-artist__wrapper">
    <figure class="landing-join-artist__image-wrapper">
      <img
        src="https://www.defense-92.fr/wp-content/uploads/2020/05/T6JB.jpg"
        alt=""
        class="landing-join-artist__image"/>
    </figure>

    <header class="landing-join-artist__header">
      <Heading
        :level="'h2'"
        :tag="'h3'"
        :color="'white'">
        Je suis un artiste
      </Heading>
    </header>

    <ul class="landing-join-artist__perks-list">
      <Heading
        v-for="(perk, index) in perks"
        :level="'h5'"
        :tag="'li'"
        :color="'white'"
        :key="`perk-${index}`"
        class="landing-join-artist__perks-item">
         • {{ perk.label }}
      </Heading>
    </ul>

    <Button
      isWhite
      hasNoBorder
      class="landing-join-artist__join-cta">
      Devenir membre
    </Button>
  </section>
</template>

<script>

import Heading      from '../../../components/atoms/Heading/a-Heading.vue';
import Button       from '../../../components/atoms/Button/a-Button.vue';


export default {
  name: 'o-LandingJoinArtist',
  components: {
    Heading,
    Button,
  },
  computed: {
    perks() {
      return [
        {
          label: 'Inscription gratuite',
        },
        {
          label: 'Présente ton univers',
        },
        {
          label: 'Deviens ton propre agent',
        },
      ];
    },
  },
}

</script>

<style lang="scss">

.landing-join-artist {
  &__wrapper {
    z-index: 1;
    position: relative;
    grid-row: 5 / 6;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 3fr 2fr;
    min-height: 250px;
    max-height: 250px;
    margin: var(--space-lg) var(--space-xl);
    padding: 0 var(--space-lg);
    padding-left: var(--space-xl);
    font-family: var(--font-stack-secondary);
  }

  &__header {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: end;
    margin-bottom: var(--space-base);
    transform: translateZ(0);
  }

  &__image-wrapper {
    z-index: -1;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 200px;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      background-color: hsla(0, 0%, 0%, .25);
      width: 100%;
      height: 100%;
      border-radius: var(--rounded-xs);
    }
  }

  &__image {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    border-radius: var(--rounded-xs);
  }

  &__perks-list {
    align-self: start;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: flex;
    align-items: center;
  }

  &__perks-item {
    &:not(:last-child) {
      margin-right: var(--space-xl);
    }
  }

  &__join-cta {
    grid-row: 1 / -1;
    align-self: center;
  }
}

</style>
