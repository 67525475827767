<template>
  <section>
    <header class="admin-events__header">
      <Heading>
        Événements admin
      </Heading>
      <Heading :level="'h3'">
        {{ unreadEvents.length }} événements non-lus
      </Heading>
    </header>

    <VirtualizedList
      @incremented="currentEventsPage++"
      :incrementPaginationBy="50"
      :items="sortedEvents">
      <template #default="{ virtualizedListItems: eventItems }">
        <ol class="admin-events__events-list">
          <li
            v-for="(eventItem, index) in eventItems"
            :key="`event-${index}`">
            <Loader v-if="loadingEventID === eventItem.ID"></Loader>
            <router-link
              v-else
              :to="{ name: 'ArtistProfile', params: { id: eventItem.ArtistID } }"
              :class="[
                'admin-events__events-item',
                { 'admin-events__events-item--read': eventItem.IsRead },
              ]"
              target="_blank">
              <Heading
                :tag="'span'"
                :level="'h3'">
                {{ eventItem.EventType | formatEvent }}
              </Heading>
              <Icon
                v-if="!eventItem.IsRead"
                @click.prevent.stop="handleEventMarkedAsRead(eventItem)"
                :variant="'cross-outline'"
                class="ml-auto">
              </Icon>
              <Paragraph
                :size="'sm'"
                class="admin-events__event-artist-email">
                {{ eventItem.ArtistEmail }}
              </Paragraph>
              <Paragraph
                :size="'sm'"
                isCapitalized
                hasEllipsisOverflow
                class="admin-events__event-date">
                {{ eventItem.Date }}
              </Paragraph>
              <Paragraph
                v-if="eventItem.Description"
                :size="'sm'">
                {{ eventItem.Description }}
              </Paragraph>
            </router-link>
          </li>
        </ol>
      </template>
    </VirtualizedList>

    <Loader v-if="isLoading"></Loader>

  </section>
</template>

<script>

import {
  mapState,
  mapActions,
}                               from 'vuex';

import Heading                  from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                     from '../../../components/atoms/Icon/a-Icon.vue';
import Loader                   from '../../../components/atoms/Loader/a-Loader.vue';
import Paragraph                from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import VirtualizedList          from '../../../components/renderless/VirtualizedList/r-VirtualizedList.vue';
import {
  getDateAsLocalizedString,
}                               from '../../../utils/dateUtils.js';


export default {
  name: 'p-AdminEvents',
  components: {
    Heading,
    Loader,
    Icon,
    Paragraph,
    VirtualizedList,
  },
  data: () => ({
    isLoading: false,
    currentEventsPage: 0,
    loadingEventID: '',
  }),
  computed: {
    ...mapState(['adminEvents']),
    sortedEvents() {
      const events = [ ...this.adminEvents ];

      return events
        .sort((a, b) => Date.parse(b.Date) - Date.parse(a.Date))
        .map((event) => ({
          ...event,
          Date: `${this.getDateAsLocalizedString({ date: new Date(event.Date) })} ${new Date(event.Date).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit' })}`,
        }));
    },
    unreadEvents() {
      return this.adminEvents.filter(({ IsRead }) => !IsRead);
    },
  },
  watch: {
    currentEventsPage: {
      immediate: true,
      handler: 'fetchAdminEvents',
    },
  },
  methods: {
    ...mapActions({
      getAdminEvents: 'GET_ADMIN_EVENTS',
      markEventAsRead: 'MARK_EVENT_AS_READ',
    }),
    getDateAsLocalizedString,
    async fetchAdminEvents() {
      try {
        this.isLoading = true;

        await this.getAdminEvents(this.currentEventsPage);
      } finally {
        this.isLoading = false;
      }
    },
    async handleEventMarkedAsRead({ ID }) {
      try {
        this.loadingEventID = ID;

        await this.markEventAsRead(ID);
      } finally {
        this.loadingEventID = '';
      }
    },
    searchArtist(ID) {
      this.$router.push(`search/artist/${ID}`);
    },
  },
  filters: {
    formatEvent(value) {
      switch (value) {
        case "BANNED":
          return "BANNIE";
        case "UNBANNED":
          return "DÉ-BANNIE";
        case "PUBLISH":
          return "PUBLIÉ";
        case "UNPUBLISH":
          return "DÉ-PUBLIÉ";
        case "NEW_ACCOUNT":
          return "CREATION DU COMPTE";
        case "NEW_BOOKING":
          return "NOUVEAU BOOKING";
        default:
          console.log("Unexpected event", value)
          return value;
      }
    },
  },
}
</script>

<style lang="scss">

.admin-events {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-lg);
  }

  &__events-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-base);
    padding-bottom: var(--space-xl);
  }

  &__events-item {
    display: grid;
    cursor: pointer;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    padding: var(--space-base) var(--space-md);
    padding-right: var(--space-lg);
    border-radius: var(--rounded-xs);
    background-color: var(--color-white);
    border: 1px solid var(--color-valid);

    &:nth-child(even) {
      background-color: var(--color-grey-light);
    }

    &--read {
      border-color: var(--color-grey-semi);
      opacity: .4;
    }
  }

  &__event-artist-email {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  &__event-date {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  &__event-read {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: end;
    justify-self: end;
    height: 20px;
    width: 20px;
    border-radius: var(--rounded-circle);
    background-color: var(--color-valid);

    &--read {
      visibility: hidden;
    }
  }
}

</style>
