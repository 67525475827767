const getDateWithTimezoneOffset = (date = new Date(Date.now())) => {
  const timezoneOffsetInHours = Math.abs(date.getTimezoneOffset() / 60);
  const timezoneOffsetInMilliseconds = 60 * 60 * 1000 * timezoneOffsetInHours;
  const dateWithTimezoneOffsetTimestamp = (Date.parse(date) + timezoneOffsetInMilliseconds) / 1000;

  return dateWithTimezoneOffsetTimestamp;
};

const getDateFromDDMMYYYYToMMDDYYYY = (dateAsString) => {
  const [ day, month, year ] = dateAsString.replaceAll('-', '/').split('/');

  return `${month}/${day}/${year}`;
};

const getDateAsYYYYMMDD = (date = new Date(Date.now())) => {
  const dateWithTimezoneOffset = new Date(getDateWithTimezoneOffset(new Date(date)) * 1000);

  return dateWithTimezoneOffset.toISOString().substring(0, 10);
};

const isCurrentOrFutureDate = (dateInMilliseconds) => {
  const [currentDate, targetDate] = [new Date(), new Date(dateInMilliseconds)];
  const [currentDay, currentMonth, currentYear] = [currentDate.getDate(), currentDate.getMonth() + 1, currentDate.getFullYear()];
  const [targetDay, targetMonth, targetYear] = [targetDate.getDate(), targetDate.getMonth() + 1, targetDate.getFullYear()];
  const isCurrentOrFutureDate =
    new Date(`${targetYear}/${targetMonth}/${targetDay}`) >=
    new Date(`${currentYear}/${currentMonth}/${currentDay}`);

  return isCurrentOrFutureDate;
};

const getDateAsLocalizedString = ({
    date = new Date(Date.now()),
    locale = 'fr',
    options = {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
  }) => {

  return date.toLocaleDateString(locale, options);
};

const getTimeAsMMSS = (valueInSeconds) => {
  const secondsNum = parseInt(valueInSeconds, 10);
  let minutes = Math.floor((secondsNum) / 60);
  let seconds = secondsNum - (minutes * 60);

  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  return `${minutes}:${seconds}`;
};


export {
  getDateWithTimezoneOffset,
  getDateAsYYYYMMDD,
  getDateFromDDMMYYYYToMMDDYYYY,
  isCurrentOrFutureDate,
  getDateAsLocalizedString,
  getTimeAsMMSS,
};
