import gql      from 'graphql-tag';


export default gql`
  mutation ($input: LoginInput!) {
    loginAdmin(input: $input) {
      __typename
      ... on Admin {
        ID
        Token
        Email
      }

      ... on WrongPassword {
        message
        status
      }

      ... on UserNotFound {
        message
        status
      }

      ... on WrongInput {
        errors {
          message
          status
        }
      }
    }
  }
`;
