import gql      from 'graphql-tag';


export default gql`
  query listBookings($page: Int!) {
    listBookings(page: $page) {
      __typename

      ... on Bookings {
        Bookings {
          ID
          EventType
          EventDate
          PriceForClient
          PriceForArtist
          CanceledBeforeDueDate
          Line1
          Line2
          PostalCode
          City
          Country
          StartTime
          EndTime
          Status
          IsMaterialNeeded
          IsCanceledByClient
          NumberOfPeople
          ClientEventType
          Client {
            Email
            Firstname
            Lastname
            PhoneNumber
          }
          Artist {
            ID
            Email
            Firstname
            Lastname
            ArtisteNicknameGenerated
            PhoneNumber
            Status
            IsPaid
            URLProfilePictures
            ProfilePicturesCropsOptions
          }
        }
      }

      ... on TokenError {
        status
        message
        requestID
      }

      ... on Unauthorized {
        status
        message
      }
    }
  }
`;
