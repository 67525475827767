import {
  ApolloClient,
	ApolloLink,
	split,
	InMemoryCache,
} 															from '@apollo/client/core/index.js';
import { WebSocketLink } 				from '@apollo/client/link/ws';
import { getMainDefinition } 		from 'apollo-utilities';
import { createUploadLink }			from 'apollo-upload-client';

import { API_ERROR_CODES }      from '../constants/apiErrorsCodes.js';
import router 									from '../router/router.js';
import store 										from '../store/index.js';
import { getEnv } 							from '../utils/envUtils.js';
import fragmentType 						from './fragmentTypes.json';


const multipartHeaderQueries = ['uploadProfilPicture', 'uploadMedia'];
const createUploadLinkParameters = { uri: getEnv('VUE_APP_API_URL') };
const HttpAndfileUploadLink = createUploadLink(createUploadLinkParameters);
const authenticationLink = new ApolloLink((operation, forward) => {
  const token = store.state.admin.Token;

  operation.setContext({
		headers: {
			authorization: token ? `Bearer ${token}` : null,
			...(multipartHeaderQueries.includes(operation.operationName)) ? { "content-type": "multipart/form-data" } : {}
		}
	});

  return forward(operation).map((result) => {
    const response = result?.data?.[operation.operationName];
    const isUnauthorized = API_ERROR_CODES.AUTH_TOKEN_ERROR.includes(response?.status);

    if (isUnauthorized) {
      router
        .push({ name: 'Signin' })
        .finally(() => store.commit('LOG_OUT'));
    }

    return result;
  });
});

const wsLink = new WebSocketLink({
  uri: getEnv('VUE_APP_WS_URL'),
  options: {
    lazy: true,
    timeout: 40000,
    minTimeout: 30000,
    reconnect: true,
    connectionParams: () => ({ authToken: `Bearer ${store.state.admin.Token}` }),
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([
    authenticationLink,
    HttpAndfileUploadLink,
  ]),
);
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({ possibleTypes: fragmentType }),
  connectToDevTools: true,
});


export default apolloClient;
