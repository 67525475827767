<template>
  <article class="google-location-finder__wrapper">
    <FieldInput
      v-on="$listeners"
      v-bind="$attrs"
      v-model="searchText"
      @input="handleSearchLocation"
      @clear="$emit('clear')"
      :errors="errors"
      :label="label"
      :required="isRequired"
      :class="{ 'google-location-finder__input--open': predictions.length }"
      :hasRightIcons="Boolean(prefilledText)"
      type="text"
      id="search-text-input"
      ref="search">
      <template #icons-right>
        <Icon
          @click="handleClearPlace"
          :variant="'cross-outline'"
          :size="'sm'"
          :tag="'button'">
        </Icon>
      </template>
    </FieldInput>
    <ul
      v-if="predictions.length"
      :class="[predictionsListStyle, 'google-location-finder__predictions-list']">
      <li
        v-for="(prediction, index) in predictions"
        :key="`place-prediction-${index}`"
        class="google-location-finder__predictions-item">
        <button
          @click="handleSelectPrediction(prediction)"
          type="button"
          class="google-location-finder__predictions-item-button">
          <Icon
            :variant="'location-solid'"
            :color="'neutral'"
            class="mr-xs">
          </Icon>
          <span class="google-location-finder__predictions-text google-location-finder__predictions-text--main">
            {{ prediction.mainText }}
          </span>
          <span class="google-location-finder__predictions-text google-location-finder__predictions-text--secondary">
            {{ prediction.secondaryText }}
          </span>
        </button>
      </li>
    </ul>
  </article>
</template>

<script>

import Icon                           from '../../atoms/Icon/a-Icon.vue';
import FieldInput                     from '../../atoms/FieldInput/a-FieldInput.vue';
import { appendScriptToDocument }     from '../../../utils/DOMUtils.js';


export default {
  name: 'm-GoogleLocationFinder',
  inheritAttrs: false,
  components: {
    Icon,
    FieldInput,
  },
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      required: true
    },
    prefilledText: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    hasBordersRadiuses: {
      type: Boolean,
      default: false
    },
    hasAbsoluteDropdown: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    searchText: '',
    GMapsAutocomplete: null,
    GMapsGeocoder: null,
    predictions: [],
  }),
  beforeMount() {
    this.insertGMapsScript();
  },
  created() {
    this.initGMaps();
    this.searchText = this.prefilledText;
  },
  beforeDestroy() {
    this.GMapsAutocomplete = null;
    this.GMapsGeocoder = null;
  },
  computed: {
    predictionsListStyle() {
      return { 'google-location-finder__predictions-list--absolute': this.hasAbsoluteDropdown };
    },
  },
  watch: {
    prefilledText(text) {
      if (text) this.searchText = text;
    }
  },
  methods: {
    insertGMapsScript() {
      const locale = 'fr';
      const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}&libraries=places&language=${locale}`;

      appendScriptToDocument({
        src: scriptSrc,
        isAsync: false,
        isDefer: true,
        id: `gmapSDK-${locale}`
      });
    },
    initGMaps() {
      if (!window.google) return;

      this.GMapsAutocomplete = new google.maps.places.AutocompleteService(); // eslint-disable-line
      this.GMapsGeocoder = new google.maps.Geocoder(); // eslint-disable-line
    },
    handleSearchLocation(query = '') {
      if (!this.GMapsAutocomplete || !this.GMapsGeocoder) this.initGMaps();

      query
        ? this.GMapsAutocomplete.getPlacePredictions({ input: query, options: this.options }, this.handlePredictionResults)
        : this.predictions = [];
    },
    handlePredictionResults(predictions) {
      this.predictions = predictions?.map(prediction => ({
        id: prediction.place_id,
        mainText: prediction.structured_formatting.main_text,
        secondaryText: prediction.structured_formatting.secondary_text,
      })) ?? [];
    },
    handleSelectPrediction(placePrediction) {
      this.GMapsGeocoder.geocode({ placeId: placePrediction.id }, this.handlePredictionDetailsResult);
    },
    handlePredictionDetailsResult([ placeResult ]) {
      if (!placeResult) return;

      this.$emit('place-selected', placeResult);
      this.searchText = placeResult.formatted_address;
      this.predictions = [];
    },
    handleClearPlace() {
      this.searchText = '';
      this.$emit('clear-place');
    },
  }
}

</script>

<style lang="scss">

.google-location-finder {
  &__wrapper {
    position: relative;
    flex: 1;
    font-family: var(--font-stack-secondary);
  }

  &__input {
    &--open {
      .field-input__input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .field-input__actions-wrapper {
        &--left {
          border-bottom-left-radius: 0;
        }

        &--right {
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  &__predictions-list {
    max-width: 100%;
    border: 1px solid var(--color-black);
    border-top: none;
    border-bottom-left-radius: var(--rounded-base);
    border-bottom-right-radius: var(--rounded-base);
    background-color: var(--color-white);

    &--absolute {
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  &__predictions-item {
    display: flex;
    padding: var(--space-base) var(--space-sm);
    border-bottom: 1px solid var(--color-black);

    &:last-child {
      border-bottom: none;
    }
  }

  &__predictions-item-button {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
  }

  &__predictions-text {
    margin-right: var(--space-xs);

    &--main {
      font-weight: var(--font-bold);
      font-size: var(--text-sm);
      white-space: nowrap;
    }

    &--secondary {
      color: var(--color-grey-dark);
      font-size: var(--text-xs);

      @include textOverflowEllipsis();
    }
  }
}

</style>