<template>
  <ais-instant-search
    :search-client="algoliaClient"
    :index-name="indexName">
    <ais-configure
      v-if="customFilters || hasFilters"
      v-bind="customFilters || algoliaFilters"
      :query="query"/>
    <slot></slot>
  </ais-instant-search>
</template>

<script>

import {
  mapState,
  mapGetters,
}                 from 'vuex';


export default {
  name: 'm-InstantSearch',
  props: {
    customFilters: {
      type: Object,
      default: null
    },
    hasFilters: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['filters']),
    query() {
      return this.filters.query || '';
    },
    indexName() {
      return process.env.VUE_APP_ALGOLIA_INDEX;
    },
    ...mapGetters({
      algoliaQuery: 'ALGOLIA_QUERY',
      algoliaClient: 'ALGOLIA_CLIENT',
      algoliaFilters: 'ALGOLIA_FILTERS',
    }),
  }
}

</script>

<style>

</style>