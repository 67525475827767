var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:[
  'agenda-calendar__wrapper',
  { 'agenda-calendar__wrapper--public': _vm.isPublic } ]},[(_vm.isLoading)?_c('Loader',{attrs:{"height":450}}):_vm._e(),_c('header',{staticClass:"agenda-calendar__header"},[(!_vm.isPublic)?_c('div',{staticClass:"agenda-calendar__heading"},[_c('Heading',{attrs:{"tag":'h3',"level":'h3'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.titleDispatchFee'))+" ")]),_c('Paragraph',[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.dispatchFee'))+" "),_c('Icon',{ref:"pricing-help-trigger",staticClass:"ml-sm mt-xs",attrs:{"variant":'question-outline',"size":'xxs',"backdropSize":'sm',"hasWhiteBackdrop":""}})],1),_c('Tooltip',{attrs:{"trigger":_vm.$refs['pricing-help-trigger'] || {}}},[_c('Heading',{staticClass:"mb-sm",attrs:{"tag":'h4',"level":'h5'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.titleInfoAgenda'))+" ")]),_c('Paragraph',[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.infoAgenda'))+" ")])],1)],1):_c('div',{class:['m-auto', { 'mt-xl': _vm.$mediaQueries.isDestkop }]},[_c('div',{staticClass:"agenda-calendar__client-legend"},[_c('div',{class:[
          'agenda-calendar__tags-cta-color--base',
          'agenda-calendar__tags-cta-color',
          'mr-base'
        ]}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.publicLegend.available'))+" ")])]),_c('div',{staticClass:"agenda-calendar__client-legend"},[_c('div',{class:[
          'agenda-calendar__tags-cta-color--client-unavailable',
          'agenda-calendar__tags-cta-color',
          'mr-base'
        ]}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.publicLegend.unavailable'))+" ")])])]),(_vm.isShowTags)?_c('ol',{staticClass:"agenda-calendar__tags-list"},_vm._l((_vm.availabilitiesTypes),function(availabilityType,index){return _c('li',{key:("tag-agenda-" + index),staticClass:"agenda-calendar__tags-item"},[_c('button',{class:[
            { 'agenda-calendar__tags-cta--active': _vm.currentPricingEditing === availabilityType.type },
            'agenda-calendar__tags-cta'
          ],attrs:{"type":"button"},on:{"click":function($event){_vm.currentPricingEditing = _vm.currentPricingEditing === availabilityType.type ? '' : availabilityType.type}}},[_c('div',{class:[
            ("agenda-calendar__tags-cta-color--" + (availabilityType.color)),
            'agenda-calendar__tags-cta-color'
          ]}),_vm._v(" "+_vm._s(availabilityType.label)+" ")])])}),0):_vm._e()]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"agenda-calendar__calendar-wrapper",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('v-calendar',{staticClass:"agenda-calendar__calendar",attrs:{"theme":_vm.calendarTheme,"attributes":_vm.availabilities,"locale":'fr',"is-expanded":""},on:{"update:to-page":_vm.setCurrentDisplayedMonth},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
          var monthLabel = ref.monthLabel;
          var yearLabel = ref.yearLabel;
return [_c('div',{staticClass:"agenda-calendar__calendar-header-title"},[_c('span',[_vm._v(" "+_vm._s((monthLabel + " " + yearLabel))+" ")])])]}},{key:"header-left-button",fn:function(){return [_c('Icon',{staticClass:"my-xs",staticStyle:{"width":"30px"},attrs:{"variant":'arrow-left-outline',"size":'xxs'}})]},proxy:true},{key:"header-right-button",fn:function(){return [_c('Icon',{staticClass:"my-xs",staticStyle:{"width":"30px"},attrs:{"variant":'arrow-right-outline',"size":'xxs'}})]},proxy:true},{key:"day-content",fn:function(dayContentProps){return [_c('AgendaCalendarAvailability',_vm._b({attrs:{"isClient":_vm.isPublic}},'AgendaCalendarAvailability',dayContentProps,false))]}}])},[void 0],2),(!_vm.isPublic && _vm.isEditAgendaPage)?_c('div',{staticClass:"agenda-calendar__submit-wrapper"},[(_vm.isLoading)?_c('Loader',{staticClass:"mr-lg"}):[(_vm.isEditAgendaPage)?_c('Button',{staticClass:"mr-sm",attrs:{"isWhite":"","type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'ArtistProfile' })}}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.editAgenda.goToProfil'))+" ")]):_c('Button',{staticClass:"mr-sm",attrs:{"isGrey":"","type":"button"},on:{"click":_vm.handleCancelPendingAvailabilitiesUpdates}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),_c('Button',{attrs:{"isWhite":"","type":"submit"}},[_c('Icon',{staticClass:"mr-md",attrs:{"variant":'check-outline',"size":'xs'}}),_vm._v(" "+_vm._s(_vm.$t('common.validate'))+" ")],1)]],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }