import gql      from 'graphql-tag';


export default gql`
  subscription AdminEvents {
    AdminEvents {
      __typename

      ... on Event {
          ID
          EventType
          IsRead
          Description
          Date
          ArtistEmail
          ArtistID
        }
    }
  }
`;
