<template>
  <article :class="[
    'agenda-calendar__wrapper',
    { 'agenda-calendar__wrapper--public': isPublic },
  ]">
    <Loader
      v-if="isLoading"
      :height="450">
    </Loader>

    <header class="agenda-calendar__header">
      <div
        v-if="!isPublic"
        class="agenda-calendar__heading">
        <Heading
          :tag="'h3'"
          :level="'h3'">
          {{ $t('artist.dashboard.menu.editAgenda.titleDispatchFee') }}
        </Heading>
        <Paragraph>
          {{ $t('artist.dashboard.menu.editAgenda.dispatchFee') }}
          <Icon
            :variant="'question-outline'"
            :size="'xxs'"
            :backdropSize="'sm'"
            hasWhiteBackdrop
            class="ml-sm mt-xs"
            ref="pricing-help-trigger">
          </Icon>
        </Paragraph>
        <Tooltip :trigger="$refs['pricing-help-trigger'] || {}">
          <Heading
            :tag="'h4'"
            :level="'h5'"
            class="mb-sm">
            {{ $t('artist.dashboard.menu.editAgenda.titleInfoAgenda') }}
          </Heading>
          <Paragraph>
            {{ $t('artist.dashboard.menu.editAgenda.infoAgenda') }}
          </Paragraph>
        </Tooltip>
      </div>
      <div
        v-else
        :class="['m-auto', { 'mt-xl': $mediaQueries.isDestkop }]">
        <div class="agenda-calendar__client-legend">
          <div :class="[
            'agenda-calendar__tags-cta-color--base',
            'agenda-calendar__tags-cta-color',
            'mr-base'
          ]">
          </div>
          <span>
            {{ $t('artist.dashboard.menu.editAgenda.publicLegend.available') }}
          </span>
        </div>
        <div class="agenda-calendar__client-legend">
          <div :class="[
            'agenda-calendar__tags-cta-color--client-unavailable',
            'agenda-calendar__tags-cta-color',
            'mr-base'
          ]">
          </div>
          <span>
            {{ $t('artist.dashboard.menu.editAgenda.publicLegend.unavailable') }}
          </span>
        </div>
      </div>
      <ol
        v-if="isShowTags"
        class="agenda-calendar__tags-list">
        <li
          v-for="(availabilityType, index) in availabilitiesTypes"
          :key="`tag-agenda-${index}`"
          class="agenda-calendar__tags-item">
          <button
            @click="currentPricingEditing = currentPricingEditing === availabilityType.type ? '' : availabilityType.type"
            :class="[
              { 'agenda-calendar__tags-cta--active': currentPricingEditing === availabilityType.type },
              'agenda-calendar__tags-cta'
            ]"
            type="button">
            <div :class="[
              `agenda-calendar__tags-cta-color--${availabilityType.color}`,
              'agenda-calendar__tags-cta-color'
            ]">
            </div>
            {{ availabilityType.label }}
          </button>
        </li>
      </ol>
    </header>

    <form
      v-show="!isLoading"
      @submit.prevent="handleSubmit"
      class="agenda-calendar__calendar-wrapper">
      <v-calendar
        @update:to-page="setCurrentDisplayedMonth"
        :theme="calendarTheme"
        :attributes="availabilities"
        :locale="'fr'"
        is-expanded
        class="agenda-calendar__calendar">
        <template></template>
        <template #header-title="{ monthLabel, yearLabel }">
          <div class="agenda-calendar__calendar-header-title">
            <span>
              {{ `${monthLabel} ${yearLabel}` }}
            </span>
          </div>
        </template>
        <template #header-left-button>
          <Icon
            :variant="'arrow-left-outline'"
            :size="'xxs'"
            class="my-xs"
            style="width: 30px">
          </Icon>
        </template>
        <template #header-right-button>
          <Icon
            :variant="'arrow-right-outline'"
            :size="'xxs'"
            class="my-xs"
            style="width: 30px">
          </Icon>
        </template>
        <template #day-content="dayContentProps">
          <AgendaCalendarAvailability
            v-bind="dayContentProps"
            :isClient="isPublic">
          </AgendaCalendarAvailability>
        </template>
      </v-calendar>

      <div
        v-if="!isPublic && isEditAgendaPage"
        class="agenda-calendar__submit-wrapper">
        <Loader
          v-if="isLoading"
          class="mr-lg">
        </Loader>
        <template v-else>
          <Button
            v-if="isEditAgendaPage"
            @click="$router.push({ name: 'ArtistProfile' })"
            isWhite
            type="button"
            class="mr-sm">
            {{ $t('artist.dashboard.menu.editAgenda.goToProfil') }}
          </Button>
          <Button
            v-else
            @click="handleCancelPendingAvailabilitiesUpdates"
            isGrey
            type="button"
            class="mr-sm">
            {{ $t('common.cancel') }}
          </Button>
          <Button
            isWhite
            type="submit">
            <Icon
              :variant="'check-outline'"
              :size="'xs'"
              class="mr-md">
            </Icon>
            {{ $t('common.validate') }}
          </Button>
        </template>
      </div>
    </form>
  </article>
</template>

<script>

import AgendaCalendarAvailability     from '../AgendaCalendarAvailability/m-AgendaCalendarAvailability.vue'
import Icon                           from '../../atoms/Icon/a-Icon.vue';
import Heading                        from '../../atoms/Heading/a-Heading.vue';
import Tooltip                        from '../../atoms/Tooltip/a-Tooltip.vue';
import Button                         from '../../atoms/Button/a-Button.vue';
import Paragraph                      from '../../atoms/Paragraph/a-Paragraph.vue';
import Loader                         from '../../atoms/Loader/a-Loader.vue';
import {
  getDateWithTimezoneOffset,
}                                     from '../../../utils/dateUtils.js';
import availableTags                  from '../../../constants/availabilityTags.js';


export default {
  name: 'm-AgendaCalendar',
  components: {
    Icon,
    Heading,
    Tooltip,
    Button,
    Paragraph,
    Loader,
    AgendaCalendarAvailability,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isShowGoToProfileCTA: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isGreyHeader: {
      type: Boolean,
      default: false,
    },
    isShowTags: {
      type: Boolean,
      default: false,
    },
    availabilities: {
      type: Array,
      default: () => ([]),
    }
  },
  data: () => ({
    currentPricingEditing: '',
    tags: availableTags,
  }),
  computed: {
    isEditAgendaPage() {
      return this.$route.name === 'ArtistEditAgenda';
    },
    availabilitiesTypes() {
      return [
        { type: 'EXCEPTION', label: this.$t('pricingsList.exceptional'), color: 'exceptional' },
        { type: 'UNAVAILABLE', label: this.$t('pricingsList.unavailable'), color: 'unavailable' },
      ];
    },
    calendarTheme() {
      return {
        header: this.isGreyHeader
          ? 'agenda-calendar__calendar-header agenda-calendar__calendar-header--grey'
          : 'agenda-calendar__calendar-header',
      };
    },
  },
  methods: {
    setCurrentDisplayedMonth({ month, year }) {
      const shouldNotFetchAvailabilities = this.hasSelectedPastDate({ month, year });

      if (shouldNotFetchAvailabilities) return;

      const currentDisplayedMonth = new Date(`${month}/01/${year}`);
      const currentDisplayedMonthTimestamp = getDateWithTimezoneOffset(currentDisplayedMonth);

      this.firstOfCurrentMonth = currentDisplayedMonth;
      this.$emit('month-changed', currentDisplayedMonthTimestamp);
    },
    hasSelectedPastDate({ month, year }) {
      const currentDate = new Date(Date.now());
      const [currentMonth, currentYear] = [currentDate.getMonth() + 1, currentDate.getFullYear()];
      const [currentMonthAndYear, selectedMonthAndYear] = [new Date(`${currentYear}-${currentMonth}`), new Date(`${year}-${month}`)];
      const hasSelectedPastDate = selectedMonthAndYear < currentMonthAndYear;

      return hasSelectedPastDate;
    },
    handleCancelPendingAvailabilitiesUpdates() {
      this.setPendingAvailabilitiesUpdates([]);
    },
    handleSubmit() {
      this.$emit('availabilities-changed');
    },
  }
}

</script>

<style lang="scss">

.agenda-calendar {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: var(--space-base);
    grid-column-gap: var(--space-sm);
    grid-template-areas:
      "header"
      "calendar";

    @media screen and ($desktop) {
      grid-template-columns: 300px 1fr;
      grid-column-gap: var(--space-md);
    }

    &--public {
      grid-template-columns: 100%;
      grid-template-areas:
        "header"
        "calendar"
      ;

      @media screen and ($desktop) {
        grid-template-columns: 2fr 1fr;
        grid-template-areas: "calendar header";
      }
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-sm);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
  }

  &__heading {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xxs);
    align-items: center;
    margin-bottom: var(--space-md);
  }

  &__client-legend {
    display: flex;
    align-items: center;
    margin: var(--space-md) var(--space-base);
  }

  &__tags-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--space-xs);
    align-items: flex-start;

    @media screen and ($desktop) {
      flex-direction: column;
      flex-wrap: initial;
      align-items: initial;
    }
  }

  &__tags-item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: var(--space-base);
    }
  }

  &__tags-cta {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--space-sm) var(--space-base);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
    font-weight: var(--font-light);
    line-height: var(--lineheight-xs);
    border: 1px solid var(--color-grey-semi);
    border-radius: var(--rounded-xs);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      width: 60%;
    }

    &--active {
      background-color: var(--color-grey-semi);
    }
  }

  $availabilities-colors: (
    'base': var(--color-pricing-base),
    'exceptional': var(--color-gradient-main),
    'unavailable': var(--color-pricing-unavailable),
    'client-unavailable': var(--color-black),
  );

  &__tags-cta-color {
    height: 20px;
    width: 20px;
    margin-right: var(--space-sm);
    border-radius: var(--rounded-circle);

    @each $availability-key, $availability-value in $availabilities-colors {
      &--#{$availability-key} {
        background: $availability-value;

        @if $availability-key == 'base' {
          border: 1px solid var(--color-grey-semi);
        }
      }
    }
  }

  &__calendar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__calendar {
    grid-area: calendar;
    margin-bottom: var(--space-base);
    font-family: var(--font-stack-secondary) !important;
    background-color: transparent;
    border: none;
  }

  &__calendar-header {
    padding-bottom: var(--space-sm) !important;
    margin-bottom: var(--space-xs);
    background-color: var(--color-white);
    border-radius: var(--rounded-sm);

    &--grey {
      background-color: var(--color-grey-background);
    }
  }

  &__calendar-header-title {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-family: var(--font-stack-primary) !important;
    font-size: var(--text-base);
    font-weight: var(--font-light);
  }

  &__submit-wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
