const getEnv = (variableName) => {
  const variable = process.env[variableName];
  if (!variable) throw new Error(`Error missing environnement variable ${variableName}`);

  return variable;
};


export {
  getEnv,
};
