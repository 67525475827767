<template>
  <section class="artist-profile-pricing__wrapper">
    <header>
      <Paragraph v-if="isPublic">
        {{ $t('artist.dashboard.menu.priceCard.title') }}
      </Paragraph>
      <Heading
        :tag="'span'"
        :level="'h1'"
        isCapitalized>
        {{ pricingNormal || '-' }} €
      </Heading>
      <Paragraph>
        {{ $t('artist.dashboard.menu.priceCard.subTitle') }}
      </Paragraph>
    </header>
    <div class="artist-profile-pricing__rating">
      <Icon
        :variant="'star-outline'"
        :size="'sm'"
        class="mr-xs">
      </Icon>
      <Heading
        v-if="artist.Rating"
        :tag="'span'"
        :level="'h5'">
        {{ $t('artist.dashboard.menu.priceCard.rating.countRating', { countRating: artist.Rating }) }}
      </Heading>
      <Paragraph
        v-else
        :size="'sm'">
        {{ $t('artist.dashboard.menu.priceCard.rating.noRating') }}
      </Paragraph>
    </div>
  </section>
</template>

<script>

import Icon             from '../../../components/atoms/Icon/a-Icon.vue';
import Heading          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph        from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'o-ArtistProfilePricing',
  components: {
    Icon,
    Heading,
    Paragraph,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    pricingNormal() {
      return this.artist?.ClientPrices?.Normal
        ?? this.artist?.DefaultPrices?.Normal
        ?? 0;
    },
  },
}

</script>

<style lang="scss">

.artist-profile-pricing {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: start;
    padding: var(--space-lg);
    background-color: var(--color-grey-light);
    border-radius: var(--rounded-xl);
  }

  &__rating {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    justify-self: end;
  }
}

</style>
