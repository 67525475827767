<template>
  <nav>
    <ol class="app-breadcrumb__levels-list">
      <li
        v-for="(level, index) in levels"
        :key="`breadcrumb-level-${index}`"
        :class="[{ 'app-breadcrumb__levels-item--active': index === levels.length - 1 }]">
        <router-link
          v-if="(index < levels.length - 1) && level.link"
          :to="{ name: level.link }">
          {{ level.label }}
        </router-link>
        <span v-else>
          {{ level.label }}
        </span>
        <span
          v-if="index < levels.length - 1"
          class="ml-xs mr-sm">
          >
        </span>
      </li>
    </ol>
  </nav>
</template>

<script>

export default {
  name: 'm-AppBreadcrumb',
  props: {
    levels: {
      type: Array,
      required: true,
    },
  },
}

</script>

<style lang="scss">

.app-breadcrumb {
  &__levels-list {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: var(--space-sm);
    align-items: center;
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
  }

  &__levels-item {
    &--active {
      font-size: var(--text-xs);
      font-weight: var(--font-bold);
    }
  }
}

</style>
