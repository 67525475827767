import gql      from 'graphql-tag';


export default gql`
  mutation banArtist($input: BanArtistInput!) {
    banArtist(input: $input) {
      __typename

      ... on Artist {
        IsBanned
      }

      ... on ArtistNotFound {
        message
        status
      }

      ... on AlgoliaError {
        message
        Status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
