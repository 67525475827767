export default [
	{
		label: 'statusesList.intermittent',
		value: 'INTERMITTENT'
	},
	{
		label: 'statusesList.selfEmployed',
		value: 'SELF_EMPLOYED'
	},
	{
		label: 'statusesList.SAS',
		value: 'SAS'
	},
	{
		label: 'statusesList.SARL',
		value: 'SARL'
	},
];