<template>
  <section class="artist-profile-medias__wrapper">
    <section
      @click="() => isPublic ? isPicturesLightboxOpen = true : false"
      :style="{ cursor: isPublic ? 'pointer' : 'initial' }"
      class="artist-profile-medias__pictures-gallery-wrapper">
      <ul
        class="artist-profile-medias__pictures-gallery"
        ref="pictures-gallery">
        <li
          v-for="(picture, index) in picturesToDisplay"
          :key="`picture-${index}`"
          class="artist-profile-medias__pictures-gallery-item">
          <img
            :src="picture.URL"
            :alt="picture.Type"
            class="artist-profile-medias__pictures-gallery-picture"/>
        </li>
      </ul>
    </section>

    <Lightbox
      v-if="isPicturesLightboxOpen"
      @close-lightbox="isPicturesLightboxOpen = false"
      :items="publicArtistPictures">
      <template #default="{ item }">
        <img
          :src="item.URL"
          alt="Artist picture">
      </template>
    </Lightbox>

    <section
      class="artist-profile-medias__videos-gallery-wrapper">
      <Icon
        @click="currentVideoIndex--"
        :variant="'chevron-left-outline'"
        :size="'sm'"
        :tag="'button'"
        type="button"
        class="
          artist-profile-medias__videos-gallery-arrow-cta
          artist-profile-medias__videos-gallery-arrow-cta--left
        ">
      </Icon>

      <VideoMediaCard
        v-if="currentVideo"
        :isPending="!currentVideo.URL.startsWith('http')"
        :isLoading="currentVideo.isLoading"
        :source="currentVideo.URL"
        :key="currentVideo.URL"
        isRoundedBorders>
      </VideoMediaCard>
      <Icon
        v-if="(videosToDisplay.length > 1) && (currentVideoIndex === 0)"
        @click="currentVideoIndex++"
        :variant="'chevron-right-outline'"
        :size="'sm'"
        :tag="'button'"
        type="button"
        class="
          artist-profile-medias__videos-gallery-arrow-cta
          artist-profile-medias__videos-gallery-arrow-cta--right
        ">
      </Icon>
    </section>
  </section>
</template>

<script>

import { mapGetters }     from 'vuex';

import Icon               from '../../../components/atoms/Icon/a-Icon.vue';
import Lightbox           from '../../../components/molecules/Lightbox/m-Lightbox.vue';
import VideoMediaCard     from '../../../components/molecules/VideoMediaCard/m-VideoMediaCard.vue';


export default {
  name: 'o-ArtistProfileMedias',
  components: {
    Icon,
    Lightbox,
    VideoMediaCard,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isPicturesLightboxOpen: false,
    isUploadPicturesModalOpen: false,
    isUploadVideosModalOpen: false,
    maxVideosUpload: 2,
    currentVideoIndex: 0,
    pendingVideosUploads: [],
    pendingPicturesUploads: [],
  }),
  computed: {
    ...mapGetters({
      publicArtistVideos: 'MEDIAS_VIDEOS',
      publicArtistPictures: 'MEDIAS_IMAGES',
    }),
    currentVideo() {
      return this.videosToDisplay?.[this.currentVideoIndex]
        ?? null;
    },
    picturesToDisplay() {
      return this.publicArtistPictures;
    },
    videosToDisplay() {
      return this.publicArtistVideos;
    },
  },
  mounted() {
    this.setGalleryGridRows();
  },
  methods: {
    setGalleryGridRows() {
      this.$refs['pictures-gallery']?.style?.setProperty('--rows', this.picturesToDisplay.length - 1);
    },
    getVideoSource(video) {
      const isTrimmingVideo = this.currentEditedVideoID === video.ID;
      const isVideoPending = !video.URL.startsWith('http');

       if (isTrimmingVideo) return video.originalURL;
       if (isVideoPending) return '';

       return video.URL;
    },
  }
}

</script>

<style lang="scss">

.artist-profile-medias {
  &__upload-card {
    display: flex;
    align-items: center;
    padding: var(--space-lg);
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-empty);

    &:not(:last-child) {
      margin-bottom: var(--space-md);
    }
  }

  &__upload-card-header {
    flex: 1 0 auto;
  }

  &__pictures-gallery-wrapper {
    position: relative;
    margin-bottom: var(--space-md);
  }

  &__pictures-gallery {
    --grid-gap: 3px;
    --rows: 0;

    display: grid;
    height: 400px;
    grid-template-columns: minmax(calc(70% - var(--grid-gap)), 1fr) repeat(auto-fit, minmax(0, 30%));
    grid-template-rows: repeat(var(--rows), calc((400px / var(--rows)) - 2px));
    grid-gap: var(--grid-gap);
  }

  &__pictures-gallery-item {
    &:first-child {
      grid-row: 1 / -1;

      .artist-profile-medias__pictures-gallery-picture {
        border-top-left-radius: var(--rounded-xs);
        border-bottom-left-radius: var(--rounded-xs);
      }

      &:last-child {
        .artist-profile-medias__pictures-gallery-picture {
          border-top-right-radius: var(--rounded-xs);
          border-bottom-right-radius: var(--rounded-xs);
        }
      }
    }

    &:nth-child(2) {
      .artist-profile-medias__pictures-gallery-picture {
        border-top-right-radius: var(--rounded-xs);
      }
    }

    &:last-child {
      &:nth-child(4), &:nth-child(3), &:nth-child(2) {
        .artist-profile-medias__pictures-gallery-picture {
          border-bottom-right-radius: var(--rounded-xs);
        }
      }
    }
  }

  &__pictures-gallery-picture {
    height: 100%;
    max-height: 400px;
    min-width: 100%;
    object-fit: cover;
  }

  &__videos-gallery-wrapper {
    position: relative;
    height: 400px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-black);
  }

  &__videos-gallery-arrow-cta {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: var(--rounded-circle);
      background-color: var(--color-white);
    }

    &--left {
      left: var(--space-lg);
    }

    &--right {
      right: var(--space-lg);
    }
  }

  &__gallery-edit-cta {
    position: absolute;
    left: var(--space-lg);
    bottom: var(--space-lg);
  }
}

</style>
