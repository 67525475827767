<template>
  <section class="artist-profile-private-document__wrapper">
    <Heading
      :level="'h2'"
      :tag="'h2'"
      class="mb-sm">
      Informations administratives
    </Heading>

    <div v-if="artist.StatusName && artist.Status">
      <template v-if="isIntermittent">
        <div class="artist-profile-private-document__status-wrapper">
          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            Patronyme:
          </Paragraph>
          <Paragraph isCapitalized>
            {{ `${artist.Firstname} ${artist.Lastname}` }}
          </Paragraph>
          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            Status:
          </Paragraph>
          <Paragraph isCapitalized>{{ artist.StatusName }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            Nationality:
          </Paragraph>
          <Paragraph>{{ artist.Status.Nationality || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            FamilyStatus:
          </Paragraph>
          <Paragraph>{{ artist.Status.FamilyStatus || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            PlaceOfBirth:
          </Paragraph>
          <Paragraph>{{ artist.Status.PlaceOfBirth || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            StateOfBirth:
          </Paragraph>
          <Paragraph>{{ artist.Status.StateOfBirth || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            CountryOfBirth:
          </Paragraph>
          <Paragraph>{{ artist.Status.CountryOfBirth || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            SocialSecurityNumber:
          </Paragraph>
          <Paragraph>{{ artist.Status.SocialSecurityNumber || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            ShowLeaveNumber:
          </Paragraph>
          <Paragraph>{{ artist.Status.ShowLeaveNumber || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            Iban:
          </Paragraph>
          <Paragraph>{{ artist.Status.Iban || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            Bic:
          </Paragraph>
          <Paragraph>{{ artist.Status.Bic || `Non renseigné` }}</Paragraph>

          <Paragraph
            :size="`md`"
            isBold
            hasEllipsisOverflow>
            TaxRate:
          </Paragraph>
          <Paragraph>{{ artist.Status.TaxRate || `Non renseigné` }}</Paragraph>
        </div>
      </template>
      <div
        v-else
        class="artist-profile-private-document__status-wrapper">
        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Patronyme:
        </Paragraph>
        <Paragraph isCapitalized>
          {{ `${artist.Firstname} ${artist.Lastname}` }}
        </Paragraph>
        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Status:
        </Paragraph>
        <Paragraph isCapitalized>
          {{ $t(getStatusTranslation(artist.StatusName)) }}
        </Paragraph>

        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Siret:
        </Paragraph>
        <Paragraph>
          {{ artist.Status.Siret }}
        </Paragraph>

        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Numéro TVA:
        </Paragraph>
        <Paragraph>
          {{ artist.Status.VATNumber }}
        </Paragraph>

        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Soumis à la TVA:
        </Paragraph>
        <Paragraph>
          {{ artist.Status.VATTaxable ? 'Oui' : 'Non' }}
        </Paragraph>

        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Détiens une RC:
        </Paragraph>
        <Paragraph>
          {{ artist.Status.IsRCPro ? 'Oui' : 'Non' }}
        </Paragraph>

        <Paragraph
          :size="`md`"
          isBold
          hasEllipsisOverflow>
          Nom de l'entreprise:
        </Paragraph>
        <Paragraph>
          {{ artist.Status.Name }}
        </Paragraph>
      </div>
    </div>
    <div v-else>
      <Paragraph>
        Les informations sur le statut administratif ne sont pas encore renseignées
      </Paragraph>
    </div>

    <template v-if="artist.Status">
      <a
        v-if="artist.Status.KBISUrl"
        :href="artist.Status.KBISUrl"
        class="artist-profile-private-document-card-id mr-md"
        target="_blank">
        Voir le kbis
      </a>
      <Paragraph
        v-else
        class="artist-profile-private-document-status__link-no">
        KBIS non renseigné
      </Paragraph>
      <a
        v-if="URLCardID"
        :href="URLCardID"
        class="artist-profile-private-document-card-id"
        target="_blank">
        Voir la carte d'identité
      </a>
      <Paragraph
        v-else
        class="artist-profile-private-document-card-id-no">
        Carte d'identité non renseigné
      </Paragraph>
    </template>
  </section>
</template>

<script>

import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import {
  getStatusTranslation,
}                           from '../../../utils/i18nUtils.js';

export default {
  name: 'o-ArtistProfilePrivateDocuments',
  components: {
    Heading,
    Paragraph,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
  },
  computed: {
    isIntermittent() {
      return this.artist.StatusName === "INTERMITTENT";
    },
    URLCardID() {
      return this.artist?.URLCardID ?? '';
    },
  },
  methods: {
  getStatusTranslation,
  },
}

</script>

<style lang="scss">
 .artist-profile-private-document {
   &__wrapper {
    background-color: var(--color-grey-light);
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
   }

   &__status-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: var(--space-xs);
      margin: var(--space-base) 0;
   }

   &__link {
      margin-top: var(--space-base);
      text-decoration: underline;
      font-style: italic;
   }

    &-no {
      cursor: not-allowed;
    }

   &-card-id {
     margin-top: var(--space-base);
     text-decoration: underline;
     font-style: italic;
     &-no {
       margin-top: var(--space-base);
       cursor: not-allowed;
       font-style: italic;
     }
   }
 }
</style>
