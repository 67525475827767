import Vue                      from 'vue';
import Vuex                     from 'vuex';
import createPersistedState     from 'vuex-persistedstate';

import rootMutations            from './mutations.js';
import rootActions              from './actions.js';
import rootGetters              from './getters.js';
import {
  isNewAppVersionAvailable,
  clearVuexLocalStorage
}                               from '../utils/localStorageUtils.js';


if (isNewAppVersionAvailable()) clearVuexLocalStorage();

Vue.use(Vuex);

const getInitialState = () => ({
  isSignedIn: false,
  isAvailabilitiesLoading: false,
  isLandingHeroVisible: true,
  admin: {
    ID: '',
    Email: '',
    Token: '',
    Events: {},
  },
  filters: {
		query: '',
    tags: [],
		activity: '',
    status: '',
    pricing: 0,
    isPublished: false,
    isNotPublished: false,
    isBanned: false,
		unavailabilities: [],
		localisation: {},
	},
  bookings: [],
  currentArtist: {},
  adminDescription: '',
  artistEvents: {},
  adminEvents: [],
  landingData: {
    VideoURL: '',
    BannerArtists: [],
    FeaturedArtists: [],
    Comments: [],
  },
  pendingLandingData: {
    VideoURL: '',
    BannerArtists: [],
    FeaturedArtists: [],
    Comments: [],
  },
  artistsBanners: [],
  artistsBannersData: [],
  errors: [],
});
const store = new Vuex.Store({
  strict: true,
  state: getInitialState,
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions,
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_VERSION ?? 'vuex',
    }),
  ],
});


export { getInitialState };
export default store;
