<template>
  <div :class="[wrapperStyle, 'checkbox__wrapper']">
    <!-- @slot Custom content -->
    <slot v-bind="{ isChecked }"></slot>

    <div :class="[inputWrapperStyle, 'checkbox__input-wrapper']">
      <input
        v-bind="$attrs"
        v-on="{
          ...$listeners,
          input: handleInput,
          mousedown: $event => $event.preventDefault()
        }"
        :value="value"
        :checked="isChecked || $attrs.checked"
        :id="id"
        class="checkbox__input"
        type="checkbox"
      />
    </div>

    <label
      v-if="label"
      :for="id"
      :class="[checkboxLabelStyle, 'checkbox__label']">
      <!-- @slot Custom label -->
      <slot name="label">
        {{ label }}
      </slot>
    </label>

    <!-- @slot Custom error  -->
    <slot name="error">
      <span
        v-if="localErrors.length"
        :title="localErrors[0].message"
        class="field-input__error-message">
        {{ localErrors[0].message }}
      </span>
    </slot>
  </div>
</template>

<script>

import { handleValidityCheck }      from '../../../utils/DOMUtils.js';


export default {
  name: 'a-Checkbox',
  inheritAttrs: false,
  model: {
    prop: 'parentModel',
  },
  props: {
    parentModel: null,
    value: {
      type: [String, Number],
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    isLabelSm: {
      type: Boolean,
      default: false,
    },
    hasTextOverflow: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    localErrors: [],
  }),
  computed: {
    isChecked() {
      return Array.isArray(this.parentModel)
        ? this.parentModel.includes(this.value)
        : this.parentModel === true;
    },
    wrapperStyle() {
      return { 'checkbox__wrapper--overflow': this.hasTextOverflow };
    },
    inputWrapperStyle() {
      return { 'checkbox__input-wrapper--checked': this.isChecked };
    },
    checkboxLabelStyle() {
      return { 'checkbox__label--sm': this.isLabelSm };
    },
  },
  methods: {
    handleValidityCheck,
    handleInput({ target }) {
      /**
       * Emits the checked state as an updated array if the parent 'v-model' is bound to an array
       * Emits a boolean otherwise
       *
       * @event input
       * @type {array | boolean}
       */
      const isChecked = target.checked;
      const canCheckMultipleValues = Array.isArray(this.parentModel);

      if (!canCheckMultipleValues) this.$emit('input', isChecked);
      else {
        const newParentModel = [...this.parentModel];

        isChecked
          ? newParentModel.push(this.value)
          : newParentModel.splice(newParentModel.indexOf(this.value), 1);

        this.$emit('input', newParentModel);
      }
    },
    checkValidity() {
      const errors = this.handleValidityCheck(this.$refs.input);

      this.localErrors = errors.length ? [ ...errors, ...this.localErrors ] : [];
    },
  },
};
</script>

<style lang="scss">

.checkbox {
  &__wrapper {
    display: flex;
    align-items: center;
    align-items: flex-start;
    cursor: pointer;
    max-width: 100%;
    min-width: 0;
    font-family: var(--font-stack-secondary);

    * {
      cursor: pointer;
    }

    &--overflow {
      max-width: 100%;
    }
  }

  &__input-wrapper {
    position: relative;
    display: flex;

    &::before {
      content: '';
      z-index: 1;
      position: absolute;
      height: 20px;
      width: 20px;
      background-color: var(--color-white);
      border: 1px solid var(--color-black);
      border-radius: 2px;
      pointer-events: none;
    }

    &--checked {
      &::after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 12px;
        width: 12px;
        transform: translate(33%, 33%);
        border-radius: 1px;
        background-color: var(--color-black);
        pointer-events: none;
      }
    }
  }

  &__input {
    position: relative;
    height: 20px;
    width: 20px;
  }

  &__label {
    position: relative;
    top: -4px;
    flex: 1 1 auto;
    margin-left: var(--space-sm);
    font-family: var(--font-stack-tertiary);
    font-size: var(--text-sm);
    font-weight: var(--font-light);
    line-height: var(--lineheight-md);
  }
}

</style>
