import store      from '../store/index.js';


const navigationGuard = (to, from, next) => {
  const isSignedIn = store.state.isSignedIn;
  const isAuthenticationRequired = to.matched.some(matchedRoute => matchedRoute.meta.isAuthenticationRequired);
  const isNotAllowedWhenSignedIn = to.matched.some(matchedRoute => matchedRoute.meta.isNotAllowedWhenSignedIn);
  const isAlreadyGoingToSignin = to.name?.toLowerCase()?.includes('signin');
  const shouldRedirectToSignin = isAuthenticationRequired && !isSignedIn && !isAlreadyGoingToSignin;
  const shouldRedirectToDashboard = isNotAllowedWhenSignedIn && isSignedIn;

  if (shouldRedirectToDashboard) return next({ name: 'Dashboard' });

  return shouldRedirectToSignin
    ? next('/')
    : next();
};


export default navigationGuard;
