<template>
  <section class="landing-artists-list__wrapper">
    <header class="landing-artists-list__header">
      <Heading
        :tag="'h2'"
        :level="'h1'"
        :color="'white'"
        class="mb-lg">
        {{ $t('artist.dashboard.landing.validatedArtists.title') }}
      </Heading>
      <Paragraph
        :color="'white'"
        class="mb-lg">
        {{ $t('artist.dashboard.landing.validatedArtists.subtitle') }}
      </Paragraph>
      <TextLink :color="'white'">
        {{ $t('artist.dashboard.landing.validatedArtists.cta') }}
      </TextLink>
    </header>

    <div class="landing-artists-list__nav">
      <Icon
        @click="() => $refs.flickity && $refs.flickity.previous()"
        :variant="'arrow-left-outline'"
        :size="'xxs'"
        :color="'white'"
        :tag="'button'"
        hasBackdrop
        hasDarkBackdrop
        type="button"
        class="ml-auto mr-sm">
      </Icon>
      <Icon
        @click="() => $refs.flickity && $refs.flickity.next()"
        :variant="'arrow-right-outline'"
        :size="'xxs'"
        :color="'white'"
        :tag="'button'"
        hasBackdrop
        hasDarkBackdrop
        type="button"
        class="ml-lg">
      </Icon>
    </div>

    <Flickity
      :options="flickityOptions"
      ref="flickity"
      class="landing-artists-list__artists-list">
      <div
        v-for="(artist, index) in artists"
        :key="`artist-item-${index}`"
        class="landing-artists-list__artists-item">
        <figure class="landing-artists-list__artist-card">
          <img
            :src="artist.ProfilePictureURL"
            alt="Photo Artiste"
            class="landing-artists-list__artist-card-image">
          <figcaption class="landing-artists-list__artist-card-infos">
            <Heading
              :tag="'h3'"
              :level="'h2'">
              {{ artist.Firstname }}
            </Heading>
            <Paragraph
              :tag="'span'"
              class="mb-md">
              @{{ artist.ArtisteNickname || artist.ArtisteNicknameGenerated }}
            </Paragraph>
            <Paragraph
              :tag="'span'"
              isBold
              class="mb-lg">
              {{ $t(artist.Activity) }}
            </Paragraph>
            <router-link :to="{ name: 'ArtistProfile', params: { id: artist.ID } }">
              <Button :tag="'span'">
                {{ $t('artist.dashboard.landing.validatedArtists.artistProfileCta') }}
              </Button>
            </router-link>
          </figcaption>
        </figure>
      </div>
    </Flickity>
  </section>
</template>

<script>

import Flickity             from 'vue-flickity';

import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                 from '../../../components/atoms/Icon/a-Icon.vue';
import Button               from '../../../components/atoms/Button/a-Button.vue';
import TextLink             from '../../../components/atoms/TextLink/a-TextLink.vue';
import FLICKITY_OPTIONS     from '../../../constants/flickity-options.js';


export default {
  name: 'o-LandingArtistsList',
  components: {
    Flickity,
    Heading,
    Paragraph,
    Icon,
    Button,
    TextLink,
  },
  props: {
    artists: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    flickityOptions: {
      ...FLICKITY_OPTIONS,
      cellAlign: 'left',
    },
  }),
}

</script>

<style lang="scss">

.landing-artists-list {
  &__wrapper {
    grid-row: 4 / 5;
    display: flex;
    flex-direction: column;
    padding: var(--space-lg) var(--space-md);
    background-color: var(--color-black);

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr auto;
      grid-gap: var(--space-xl);
      padding: var(--space-xl);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-self: start;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin: var(--space-md) 0;

    @media screen and ($desktop) {
      margin: 0;
    }
  }

  &__artists-list {
    margin: 0 calc(-1 * var(--space-md));

    @media screen and ($desktop) {
      margin: 0 calc(-1 * var(--space-xl));
      margin-left: var(--space-xl);
    }
  }

  &__artists-item {
    margin-right: var(--space-lg);
  }

  &__artist-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 380px;
    max-height: 380px;
    min-width: 380px;
    max-width: 380px;
    padding: var(--space-md) var(--space-xl);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
  }

  &__artist-card-image {
    height: 130px;
    width: 130px;
    margin-bottom: var(--space-md);
    object-fit: cover;
    border-radius: var(--rounded-xl);
  }

  &__artist-card-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
