<template>
  <form
    @submit.prevent="updateDescription"
    class="artist-profile-admin-actions__wrapper"
    ref="form"
    novalidate>
    <header class="mb-base">
      <Heading
        :tag="'h2'"
        :level="'h2'"
        class="mb-sm"
        style="display: flex;">
        Note
        <Icon
          v-if="!isEditingDescription"
          @click="isEditingDescription = true"
          :tag="'button'"
          :color="'white'"
          :variant="'edit-outline'"
          :size="'sm'"
          hasBlackBackdrop
          type="button"
          class="ml-auto">
        </Icon>
      </Heading>
      <Paragraph :size="'sm'">
        Contact de l'artiste:
      </Paragraph>
      <Paragraph isBold>
        {{ artist.PhoneNumber.replace('+33', '0') }}
      </Paragraph>
      <Paragraph
        @click.native="copyEmailToClipboard"
        isBold
        class="artist-profile-admin-actions__email">
        {{ artist.Email }}
        <Icon
          :variant="hasCopied ? 'valid-solid' : 'link-outline'"
          :size="'xs'"
          :color="hasCopied ? 'valid' : 'black'"
          :tag="'button'"
          type="button"
          class="ml-xs">
        </Icon>
      </Paragraph>
      <hr class="mt-xs">
    </header>

    <Loader
      v-if="isLoading"
      :height="220">
    </Loader>
    <Paragraph
      v-else-if="!isEditingDescription"
      class="artist-profile-admin-actions__description">
      {{ description }}
    </Paragraph>
    <template v-else>
      <FieldInput
        v-model="description"
        :charactersLimit="false"
        :label="`Commentaire interne`"
        :placeholder="`Description utile pour les admins`"
        type="textarea"
        id="reason"
        class="mb-base">
      </FieldInput>
      <Button
        type="submit"
        class="artist-profile-admin-actions__actions-list">
        Mise à jour de la description
      </Button>
    </template>
  </form>
</template>

<script>
import { mapActions }     from 'vuex';

import Loader             from '../../../components/atoms/Loader/a-Loader.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph          from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon               from '../../../components/atoms/Icon/a-Icon.vue';
import Button             from '../../../components/atoms/Button/a-Button.vue';
import FieldInput         from '../../../components/atoms/FieldInput/a-FieldInput.vue';


export default {
  name: 'o-ArtistProfileDescription',
  components: {
    Loader,
    Heading,
    Paragraph,
    Icon,
    Button,
    FieldInput,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isEditingDescription: false,
    hasCopied: false,
    copiedTimeout: null,
    description: '',
  }),
  created() {
    if (!this.artist.AdminDescription) return;

    this.description = this.artist.AdminDescription;
  },
  beforeDestroy() {
    clearTimeout(this.copiedTimeout);
  },
  methods: {
    ...mapActions({
      updateArtistDescription: 'UPDATE_ARTIST_DESCRIPTION',
    }),
    async copyEmailToClipboard() {
      try {
        await navigator.clipboard.writeText(this.artist.Email);

        this.hasCopied = true;
        this.copiedTimeout = setTimeout(() => this.hasCopied = false, 500);
      } catch (error) {
        console.error(error);
      }
    },
    async updateDescription() {
      if (!this.$refs.form.checkValidity()) return;

      try {
        this.isLoading = true;

        await this.updateArtistDescription({ artist: this.artist, description: this.description });
      } finally {
        this.isLoading = false;
        this.isEditingDescription = false;
      }
    }
  },
}

</script>

<style lang="scss">

.artist-profile-admin-actions {
  &__wrapper {
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);
  }

  &__actions-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }

  &__email {
    display: flex;
    align-items: center;
  }
}

</style>
