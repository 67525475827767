import { i18n }             from '../main.js';
import ACTIVITY_OPTIONS     from '../constants/activityOptions.js';
import STATUSES_OPTIONS     from '../constants/statuses.js';


const sortTranslationsAlphabetically = (a, b) => {
  const translationA = i18n.t(a);
  const translationB = i18n.t(b);

  return translationA.localeCompare(translationB, 'fr', { sensitivity: 'base' });
};

const getActivityTranslation = (activity) => {
  const activityDetails = ACTIVITY_OPTIONS.find(({ value }) => value.toLowerCase() === activity.toLowerCase());

  return activityDetails?.label ?? '';
};

const getStatusTranslation = (activity) => {
  const statusDetails = STATUSES_OPTIONS.find(({ value }) => value.toLowerCase() === activity.toLowerCase());

  return statusDetails?.label ?? '';
};


export {
  sortTranslationsAlphabetically,
  getActivityTranslation,
  getStatusTranslation,
};
