var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",class:[_vm.wrapperStyle, 'field-input__wrapper'],on:{"focusout":_vm.handleFocusOut}},[_c('div',{class:[_vm.containerStyle, 'field-input__container']},[_c('div',{class:[_vm.inputWrapperStyle, 'field-input__input-wrapper']},[(_vm.hasLeftIcons)?_c('div',{staticClass:"field-input__actions-wrapper field-input__actions-wrapper--left"},[_vm._t("icons-left")],2):_vm._e(),_vm._t("label",[(_vm.label)?_c('label',{staticClass:"field-input__label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]),(_vm.isTextareaInput)?_c('textarea',_vm._g(_vm._b({ref:"input",class:[_vm.inputStyle, 'field-input__input'],attrs:{"id":_vm.id,"maxlength":_vm.charactersLimit,"placeholder":_vm.placeholder,"wrap":"hard","rows":"1","cols":"40"},domProps:{"value":_vm.value}},'textarea',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
          {input: _vm.handleInput,
          valid: _vm.checkValidity,
          invalid: _vm.checkValidity,
          change: _vm.checkValidity}))):_c('input',_vm._g(_vm._b({ref:"input",class:[_vm.inputStyle, 'field-input__input'],style:(_vm.inputMaxWidth),attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"min":_vm.minNumberValue || false,"max":_vm.maxNumberValue || false},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners,
          {input: _vm.handleInput,
          valid: _vm.checkValidity,
          invalid: _vm.checkValidity,
          change: _vm.checkValidity}))),_vm._t("hint"),(_vm.hasRightIcons || _vm.isShowClearButton || (_vm.isSearchFieldInput && _vm.value))?_c('div',{staticClass:"field-input__actions-wrapper field-input__actions-wrapper--right"},[(_vm.isShowClearButton || (_vm.isSearchFieldInput && _vm.value))?_c('Icon',{attrs:{"variant":'cross-outline',"tag":'button',"size":'xs',"isCircled":""},on:{"click":_vm.clearFieldInput}}):_vm._e(),_vm._t("icons-right")],2):_vm._e(),(_vm.isTextareaInput && _vm.charactersLimit)?_c('div',{staticClass:"field-input__limit"},[_vm._t("limit",[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.header.editPerformance.description.signCount.countdown', { signCountdown: _vm.limit }))+" ")])],2):_vm._e()],2),(_vm.isComboBox && _vm.isComboBoxOpen)?_c('ul',{staticClass:"field-input__combo-box-list"},_vm._l((_vm.matchingComboBoxOptions),function(option,index){return _c('li',{key:("combo-box-option-" + index),staticClass:"field-input__combo-box-item"},[_c('button',{staticClass:"field-input__combo-box-item-button",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleComboOptionSelect(option)}}},[_vm._v(" "+_vm._s(option)+" ")])])}),0):_vm._e()]),_vm._t("error",[(_vm.errorsAggregate.length)?_c('span',{staticClass:"field-input__error-message",attrs:{"title":_vm.errorsAggregate[0].message}},[_vm._v(" "+_vm._s(_vm.errorsAggregate[0].message)+" ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }