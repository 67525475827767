import algoliasearch      		from 'algoliasearch';
import FILTER_OPTIONS     		from '../constants/filterOptions.js';

import {
	getCroppedPictureURL,
	getTrimmedVideoURL
}															from '../utils/URLUtils.js';
import {
	getActivityTranslation,
}												 			from '../utils/i18nUtils.js';


const ALGOLIA_CLIENT = () => algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY);

const ACTIVE_FILTERS = (state) => {
	const activeFilters = Object.entries(state.filters).reduce((acc, [ filterName, filterValue ]) => {
		const isActive = Array.isArray(filterValue)
			? filterValue.length > 0
			: filterValue && typeof filterValue === 'object'
				? Object.keys(filterValue).length > 0
				: !!filterValue;

		return isActive
			? { ...acc, [filterName]: filterValue }
			: acc;
	}, {});

	return activeFilters;
};

const ACTIVE_FILTERS_COUNTER = (state, getters) => Object.values(getters['ACTIVE_FILTERS'])?.length ?? 0;

const ALGOLIA_FILTERS = (state, getters) => {
	if (!getters['ACTIVE_FILTERS_COUNTER']) return {};

	const filters = Object.entries(getters['ACTIVE_FILTERS'])
		.reduce((acc, [ filterName, filterValue ]) => {
			if (['localisation', 'query'].includes(filterName)) return acc;

			let filterQuery = '';
			const {
				algoliaFilterKeyName = '',
				algoliaFilterJoinOperator = '',
				algoliaFilterPrefixOperator = '',
				algoliaFilterOperator = ':'
			} = FILTER_OPTIONS.find(({ value }) => value === filterName);

			filterQuery = Array.isArray(filterValue)
				? filterValue.map(value => algoliaFilterPrefixOperator
						? `${algoliaFilterPrefixOperator} ${algoliaFilterKeyName}${algoliaFilterOperator}${value}`
						: `${algoliaFilterKeyName}${algoliaFilterOperator}${value}`
					).join(` ${algoliaFilterJoinOperator} `)
				: algoliaFilterPrefixOperator
					? `${algoliaFilterPrefixOperator} ${algoliaFilterKeyName}${algoliaFilterOperator}${filterValue}`
					: `${algoliaFilterKeyName}${algoliaFilterOperator}${filterValue}`;

			return acc
				? `${acc} AND (${filterQuery})`
				: filterQuery;
	}, '');
	const localisationFilter = {
		...(getters['IS_GEOLOCALISATION_FILTER_ACTIVE']
			? {
					aroundLatLng: `${getters['ACTIVE_FILTERS'].localisation.lat},${getters['ACTIVE_FILTERS'].localisation.lng}`,
					getRankingInfo: true
				}
			: {}
		)
	};

	return { filters, ...localisationFilter };
};

const IS_GEOLOCALISATION_FILTER_ACTIVE = (state, getters) => {
	return Boolean(getters['ACTIVE_FILTERS']?.localisation?.lat && getters['ACTIVE_FILTERS']?.localisation?.lng);
};

const MEDIAS_IMAGES = (state) => {
	return state.currentArtist?.Medias
		?.filter(media => media.Type === 'IMAGE')
		?.map(({ Options, URL, ...image }) => ({
				...image,
				originalURL: URL,
				URL: getCroppedPictureURL(URL, Options),
				...(Object.keys(Options || {}).length ? { options: Options } : {}),
			}))
		?.sort((a, b) => a.rank - b.rank)
		?? [];
};

const MEDIAS_VIDEOS = (state) => {
	return state.currentArtist?.Medias
		?.filter(media => media.Type === 'VIDEO')
		?.map(({ Options, URL, ...video }) => ({
				...video,
				originalURL: URL,
				URL: getTrimmedVideoURL(URL, Options),
				...(Object.keys(Options || {}).length ? { options: Options } : {}),
			}))
		?.sort((a, b) => a.rank - b.rank)
		?? [];
};

const HAS_SETUP_PRICINGS = (state) => {
	if (!state.currentArtist.Price) return false;

	const { __typename: priceTypeName, ...pricings } = state.currentArtist.Price; // eslint-disable-line

	return Object.values(pricings).every(pricing => pricing !== null);
};

const PRICINGS = (state, getters) => {
	const prices = getters.HAS_SETUP_PRICINGS // eslint-disable-line
		? state.currentArtist.Price
		: state.currentArtist.DefaultPrices;

	if (!prices) {
		return []
	}

	const { ...pricings } = prices

	return Object.entries(pricings)
		.map(([pricingType, pricingPrice]) => ({
			type: pricingType.toLowerCase(),
			price: pricingPrice,
			label: `common.pricingsList.${pricingType.toLowerCase()}`,
			name: `Price${pricingType}`,
	}));
};

const CALENDAR_AVAILABILITIES = (state) => {
	const bookings = state.currentArtist.Bookings ?? [];
	const calendarAvailabilities = state.currentArtist.Availabilities.map(availability => {
		const isBookedAvailability = bookings.some(({ EventDate }) => EventDate === availability.EventDate);

		return {
			key: isBookedAvailability
				? `${availability.EventType.toLowerCase()}-${availability.Id}-booked`
				: `${availability.EventType.toLowerCase()}-${availability.Id}`,
			dates: [availability.EventDate * 1000],
			customData: availability,
		};
	});

	return calendarAvailabilities;
};

const CROPPED_ARTISTS_BANNERS = (state) => {
	return state.artistsBanners?.map(({ Options, ArtistID, URL }) => ({
		URL: getCroppedPictureURL(URL, Options),
		ArtistID,
		...(Object.keys(Options || {}).length ? { options: Options } : {}),
	}));
};

const GET_CROPPED_ARTISTS_BANNERS = (state) => (artists) => { // eslint-disable-line
	return artists?.map(({ Options, ArtistID, URL, ...artist }) => ({
		...artist,
		MediaURL: getCroppedPictureURL(URL, Options),
		ArtistID,
		...(Object.keys(Options || {}).length ? { options: Options } : {}),
	}));
};

const FEATURED_ARTISTS_PROFILES = (state, getters) => {
	return getters['CURRENT_LANDING_DATA']?.FeaturedArtists?.map(({ Activity, ProfilePicturesCropsOptions, URLProfilePictures, ...profile }) => ({
		...profile,
		Activity: getActivityTranslation(Activity),
		ProfilePictureURL: getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions),
	}));
};

const CURRENT_LANDING_DATA = (state) => {
	const {
    BannerArtists = [],
    FeaturedArtists = [],
    Comments = {},
		VideoURL = '',
  } = state.landingData;
	const {
    BannerArtists: pendingBannerArtists = [],
    FeaturedArtists: pendingFeaturedArtists = [],
    Comments: pendingComments = [],
		VideoURL: pendingVideoURL = '',
  } = state.pendingLandingData;

	return {
		BannerArtists: [
			...(pendingBannerArtists.length
				? pendingBannerArtists
				: BannerArtists?.map(({ MediaURL: URL, MediaURLCropOption: Options }) => ({ MediaURL: getCroppedPictureURL(URL, Options)}))
					?? []
			),
		],
		FeaturedArtists: pendingFeaturedArtists.length
			? pendingFeaturedArtists?.map(
				({ URLProfilePictures, ProfilePicturesCropsOptions, Activity, ...artist }) =>
				({ ...artist, ProfilePictureURL: getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions), Activity: getActivityTranslation(Activity) }))
					?? []
			: FeaturedArtists?.map(
				({ Picture: URLProfilePictures, PicturesCropOption: ProfilePicturesCropsOptions, Activity, ...artist }) =>
				({ ...artist, ProfilePictureURL: getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions), Activity: getActivityTranslation(Activity) }))
					?? [],
		VideoURL: pendingVideoURL || VideoURL,
		Comments: pendingComments.length
			? pendingComments
			: Object.values(Comments),
	};
};

const HAS_MODIFIED_LANDING_DATA = (state) => Boolean(state.pendingLandingData.BannerArtists.length
	|| state.pendingLandingData.Comments.length
	|| state.pendingLandingData.FeaturedArtists.length
	|| state.pendingLandingData.VideoURL
);


export default {
  ALGOLIA_CLIENT,
  ACTIVE_FILTERS,
  ACTIVE_FILTERS_COUNTER,
  ALGOLIA_FILTERS,
	IS_GEOLOCALISATION_FILTER_ACTIVE,
	MEDIAS_IMAGES,
	MEDIAS_VIDEOS,
	HAS_SETUP_PRICINGS,
	PRICINGS,
	CALENDAR_AVAILABILITIES,
	CROPPED_ARTISTS_BANNERS,
	GET_CROPPED_ARTISTS_BANNERS,
	FEATURED_ARTISTS_PROFILES,
	CURRENT_LANDING_DATA,
	HAS_MODIFIED_LANDING_DATA,
};
