import Vue                from 'vue';
import VueRouter          from 'vue-router';
import VueI18n            from 'vue-i18n';
import VCalendar          from 'v-calendar/lib/components/calendar.umd';
import InstantSearch 			from 'vue-instantsearch';

import App 								from './App.vue';
import router 						from './router/router.js';
import store 							from './store/index.js';
import apolloProvider			from './graphql/config.js';
import messages 					from './translations/index.js';


const mediaWatcher = window.matchMedia('(min-width: 1000px)');

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.component('v-calendar', VCalendar);
Vue.prototype.$mediaQueries = Vue.observable({ isDesktop: mediaWatcher.matches });
mediaWatcher.addListener(({ matches }) => Vue.prototype.$mediaQueries.isDesktop = matches);

const i18n = new VueI18n({
	locale: 'fr',
	messages,
	silentTranslationWarn: true,
	silentFallbackWarn: true,
});

new Vue({
	render: h => h(App),
	router,
	store,
	apolloProvider,
	i18n,
}).$mount('#app');


export {
	i18n
};