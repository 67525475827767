export default [
	{
		label: 'common.languagesList.mandarin',
		value: 'MANDARIN'
	},
	{
		label: 'common.languagesList.spanish',
		value: 'SPANISH'
	},
	{
		label: 'common.languagesList.english',
		value: 'ENGLISH'
	},
	{
		label: 'common.languagesList.hindi',
		value: 'HINDI'
	},
	{
		label: 'common.languagesList.arabic',
		value: 'ARABIC'
	},
	{
		label: 'common.languagesList.portuguese',
		value: 'PORTUGUESE'
	},
	{
		label: 'common.languagesList.russian',
		value: 'RUSSIAN'
	},
	{
		label: 'common.languagesList.japanese',
		value: 'JAPANESE'
	},
	{
		label: 'common.languagesList.german',
		value: 'GERMAN'
	},
	{
		label: 'common.languagesList.javanese',
		value: 'JAVANESE'
	},
	{
		label: 'common.languagesList.korean',
		value: 'KOREAN'
	},
	{
		label: 'common.languagesList.french',
		value: 'FRENCH'
	},
	{
		label: 'common.languagesList.turkish',
		value: 'TURKISH'
	},
	{
		label: 'common.languagesList.italian',
		value: 'ITALIAN'
	},
	{
		label: 'common.languagesList.dutch',
		value: 'DUTCH'
	}
];