import gql      from 'graphql-tag';


export default gql`
  query listBanners {
    listBanners {
      __typename

      ... on Banners {
        Banners {
          ArtistID
          URL
          Options
        }
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
