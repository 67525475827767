<template>
  <section class="artist-profile-infos__wrapper">
    <div class="artist-profile-infos__avatar-wrapper">
      <div :class="{ 'mb-lg px-xs': !croppedProfilePictureUrl }">
        <img
          v-if="croppedProfilePictureUrl"
          :src="croppedProfilePictureUrl"
          alt="Profile picture"
          class="artist-profile-infos__avatar">
        <Paragraph
          v-else
          :size="'sm'"
          isTextCentered>
          {{ $t('artist.dashboard.menu.header.addProfilePicture') }}
        </Paragraph>
        <Icon
          v-if="!isPublic"
          :tag="'button'"
          :size="'xs'"
          :variant="'photo-outline'"
          type="button"
          class="artist-profile-infos__upload-icon">
        </Icon>
      </div>
    </div>
    <div class="artist-profile-infos__details">
      <Heading>
        {{ artist.Firstname || 'TODO Firstname' }}
      </Heading>
      <Paragraph class="artist-profile-infos__nickname">
        @{{ artist.ArtisteNicknameGenerated || "Supername45" }}
      </Paragraph>
      <Paragraph
        isBold
        class="artist-profile-infos__activity">
        {{ $t(getActivityTranslation(artist.ActivityInformations["__typename"])) }}
      </Paragraph>
      <div
        :class="[
          { 'artist-profile-infos__rating--active': artist.ratings },
          'artist-profile-infos__rating'
        ]">
        <Icon
          :variant="'star-outline'"
          class="mr-sm">
        </Icon>
        <!-- TODO: Link -->
        <template v-if="artist.ratings">
          <span class="mr-xs">
            4.3 (link)
          </span>
          <span>
            Avis TODO
          </span>
        </template>
        <Paragraph
          v-else
          :size="'sm'">
          {{ $t('artist.dashboard.menu.header.rating.noRating') }}
        </Paragraph>
      </div>
      <Paragraph
        isBold
        class="artist-profile-infos__activity">
        {{ artist.City }}
      </Paragraph>
    </div>
  </section>
</template>

<script>

import Heading                                from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                                   from '../../../components/atoms/Icon/a-Icon.vue';
import { getCroppedPictureURL }               from '../../../utils/URLUtils.js';
import { getActivityTranslation }             from '../../../utils/i18nUtils.js';


export default {
  name: 'o-ArtistProfileInfos',
  components: {
    Heading,
    Paragraph,
    Icon,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    croppedProfilePictureUrl() {
      const { URLProfilePictures, ProfilePicturesCropsOptions } = this.artist;

      return getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions, {}, { c: 'fill', w: 200, h: 200, });
    },
  },
  methods: {
    getActivityTranslation,
  },
}

</script>

<style lang="scss">

.artist-profile-infos {
  &__wrapper {
    grid-column: 1 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: stretch;
      margin-bottom: var(--space-md);
    }
  }

  &__avatar-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 130px;
    height: 130px;
    margin-bottom: var(--space-lg);
    background-color: var(--color-grey-empty);
    border-radius: var(--rounded-xl);

    @media screen and ($desktop) {
      margin-bottom: 0;
      margin-right: var(--space-lg);
    }
  }

  &__avatar {
    border-radius: var(--rounded-xl);
  }

  &__upload-icon {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: var(--rounded-circle);
      background-color: var(--color-white);
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    grid-row-gap: var(--space-base);

    @media screen and ($desktop) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, min-content);
      grid-row-gap: 0;
      align-self: center;
      grid-column-gap: var(--space-lg);
      justify-items: initial;
    }
  }

  &__nickname {
    grid-row: 2 / 3;
    margin-top: calc(-1 * var(--space-base));
    font-family: var(--font-stack-primary);
    font-size: var(--text-base);

    @media screen and ($desktop) {
      margin-top: 0;
    }
  }

  &__activity {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and ($desktop) {
      grid-row: 3 / 4;
      justify-self: start;
      margin-top: var(--space-base);
    }
  }

  &__rating {
    display: flex;
    align-items: center;
    align-self: center;
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);

    &--active {
      font-weight: var(--font-bold);
    }

    span:last-child {
      text-decoration: underline;
      text-underline-offset: var(--space-xxs);
    }
  }

  &__city {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }
}

</style>