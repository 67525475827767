import gql      from 'graphql-tag';


export default gql`
  mutation updateLanding($input: LandingInput!) {
    updateLanding(input: $input) {
      __typename

      ... on Landing {
        VideoURL
        FeaturedArtists {
          ArtisteNickname
          Activity
          City
          State
          Picture
          PictureCropOption
          Rank
        }
        BannerArtists {
          Rank
          MediaURL
          MediaURLCropOption
          ArtisteNickname
        }
        Comments
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
