<script>

export default {
  name: 'r-VirtualizedList',
  data: () => ({
    pagination: 0,
    observer: {},
    currentObservedItem: {}
  }),
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    sortedBy: {
      type: String,
      default: ''
    },
    incrementPaginationBy: {
      type: Number,
      default: 10
    }
  },
  created() {
    this.pagination = this.incrementPaginationBy;
  },
  activated() {
    this.resetPagination();
  },
  beforeDestroy() {
    this.observer.disconnect && this.observer.disconnect();
    this.observer = {};
    this.currentObservedItem = {};
  },
  computed: {
    virtualizedListItems() {
      if (!this.items.length) return [];

      const { items, pagination, sortedBy } = this;
      const isSorted = sortedBy && items.some(item => item[sortedBy]);
      const virtualizedList = [];

      for (let i = 0; i < pagination; i++) items[i] && virtualizedList.push(items[i]);

      return isSorted
        ? virtualizedList.sort((itemA, itemB) => itemA[sortedBy] > itemB[sortedBy] ? -1 : 1)
        : virtualizedList;
    },
    hasEnoughPagination() {
      return this.virtualizedListItems.length < this.pagination;
    }
  },
  methods: {
    setCurrentObservedItem() {
      const { $el, observer, currentObservedItem, handleCurrentObservedItemIntersection } = this;
      let lastItem = $el.lastElementChild;

      if (!lastItem) return;

      const hasObserverAlready = observer.unobserve;
      const isItemTableRow = lastItem.cells;

      // Edge case because of 'display: contents' on table rows which won't trigger intersection event.
      // Track the intersection of the row's first cell instead
      if (isItemTableRow) lastItem = lastItem.cells[0];

      hasObserverAlready
        ? observer.unobserve(currentObservedItem)
        : this.observer = new IntersectionObserver(handleCurrentObservedItemIntersection, { rootMargin: '0px 0px 20px 0px' });

      this.observer.observe(lastItem);
      this.currentObservedItem = lastItem;
    },
    handleCurrentObservedItemIntersection([ observedItem ]) {
      const { hasEnoughPagination, incrementPaginationBy } = this;
      const shouldIncrementPagination = !hasEnoughPagination && observedItem.isIntersecting;

      if (shouldIncrementPagination) {
        this.pagination += incrementPaginationBy;
        this.$emit('incremented', this.pagination);
      }
    },
    resetPagination() {
      this.pagination = this.incrementPaginationBy;
      this.setCurrentObservedItem();
    }
  },
  watch: {
    virtualizedListItems: {
      immediate: true,
      handler() {
        this.$nextTick(this.setCurrentObservedItem);
      }
    }
  },
  render() {
    return this.$scopedSlots.default({ virtualizedListItems: this.virtualizedListItems });
  }
}

</script>