<template>
  <section class="artist-profile-admin-actions__wrapper">
    <header class="mb-base">
      <Heading
        :tag="'h2'"
        :level="'h2'"
        class="mb-sm">
        Actions admin
      </Heading>
      <Paragraph :size="'sm'">
        {{
          artist.IsActive
            ? "- L'artiste est actuellement publié"
            : "- L'artiste n'est pas actuellement publié"
        }}
      </Paragraph>
      <Paragraph :size="'sm'">
        {{
          artist.IsBanned
            ? "- L'artiste est actuellement banni"
            : "- L'artiste n'est pas actuellement banni"
        }}
      </Paragraph>
    </header>

    <Loader
      v-if="isLoading"
      :height="200">
    </Loader>
    <template v-else>
      <ul class="artist-profile-admin-actions__actions-list">
        <li
          v-for="(action, index) in actionsToDisplay"
          :key="`action-${index}`">
          <Button
            @click="handleAction(action.action)"
            type="button">
            {{ action.label }}
          </Button>
        </li>
      </ul>
    </template>
  </section>
</template>

<script>

import { mapActions }     from 'vuex';

import Loader             from '../../../components/atoms/Loader/a-Loader.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph          from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button             from '../../../components/atoms/Button/a-Button.vue';
 // import FieldInput         from '../../../components/atoms/FieldInput/a-FieldInput.vue';


export default {
  name: 'o-ArtistProfileAdminActions',
  components: {
    Loader,
    Heading,
    Paragraph,
    Button,
    // FieldInput,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    reason: '',
  }),
  computed: {
    actions() {
      return [
        {
          label: `Bannir l'artiste`,
          isVisible: !this.artist.IsBanned,
          hasReason: true,
          action: async () => await this.toggleBanArtist({ artist: this.artist, reason: this.reason }),
        },
        {
          label: `Débannir l'artiste`,
          isVisible: this.artist.IsBanned,
          action: async () => await this.toggleBanArtist({ artist: this.artist }),
        },
        {
          label: `Publier l'artiste`,
          isVisible: !this.artist.IsActive,
          action: async () => await this.togglePublishArtist({ artist: this.artist }),
        },
        {
          label: `Dépublier l'artiste`,
          isVisible: this.artist.IsActive,
          action: async () => await this.togglePublishArtist({ artist: this.artist, reason: this.reason }),
          hasReason: true,
        },
        {
          label: `Supprimer l'artiste`,
          isVisible: true,
          action: async () => {
            await this.deleteArtist({ id: this.artist.ID });
            await this.$router.push({ name: 'SearchArtist' });
          },
        },
      ];
    },
    actionsToDisplay() {
      return this.actions.filter(({ isVisible }) => isVisible)
    },
  },
  methods: {
    ...mapActions({
      togglePublishArtist: 'TOGGLE_PUBLISH_ARTIST',
      toggleBanArtist: 'TOGGLE_BAN_ARTIST',
      deleteArtist: 'DELETE_ARTIST',
    }),
    async handleAction(action) {
      try {
        this.isLoading = true;

        await action();
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.artist-profile-admin-actions {
  &__wrapper {
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);
  }

  &__actions-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }
}

</style>
