export default [
  {
    value: 'query',
    algoliaFilterJoinOperator: 'OR',
    algoliaFilterKeyName: ['email', 'firstname', 'lastname'],
  },
  {
    value: 'tags',
    algoliaFilterJoinOperator: 'OR',
    algoliaFilterKeyName: 'tags',
  },
  {
    value: 'activity',
    algoliaFilterKeyName: 'activity',
  },
  {
    value: 'status',
    algoliaFilterKeyName: 'status',
  },
  {
    value: 'pricing',
    algoliaFilterKeyName: 'priceNormal',
    algoliaFilterOperator: ' <= ',
  },
  {
    value: 'isPublished',
    algoliaFilterKeyName: 'accountPublished',
  },
  {
    value: 'isNotPublished',
    algoliaFilterKeyName: 'accountPublished',
    algoliaFilterPrefixOperator: 'NOT',
  },
  {
    value: 'isBanned',
    algoliaFilterKeyName: 'accountBanned',
  },
  {
    value: 'unavailabilities',
    algoliaFilterJoinOperator: 'OR',
    algoliaFilterPrefixOperator: 'NOT',
    algoliaFilterKeyName: 'unavailabilities',
  },
  {
    label: 'client.dashboard.filter.location',
    value: 'localisation',
    link: 'ClientSearchArtistLocationFilter',
  },
];
