<template>
  <div class="artist-profile-cover__wrapper">
    <figure class="artist-profile-cover__picture-wrapper">
      <template v-if="croppedBannerPictureUrl">
        <img
          :src="croppedBannerPictureUrl"
          alt="Banner"
          class="artist-profile-cover__picture">
      </template>
    </figure>
  </div>
</template>

<script>

import {
  mapState,
}                                         from 'vuex';

import { getCroppedPictureURL }           from '../../../utils/URLUtils.js';


export default {
  name: 'o-ArtistProfileCover',
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['currentArtist']),
    croppedBannerPictureUrl() {
      const { URLBannerPictures, BannerPicturesCropsOptions } = this.isPublic ? this.currentArtist : this.user;

      return this.getCroppedPictureURL(URLBannerPictures, BannerPicturesCropsOptions, { q: 100, }, { c: 'fill', w: 1320, h: 385 });
    },
  },
  methods: {
    getCroppedPictureURL,
    async handleBannerPictureUpload({ url, cropOptions }) {
      const uploadBannerPictureParams = {
        input: {
          url,
          options: cropOptions,
          type: 'BANNER',
        },
      };

      await this.uploadBannerPicture(uploadBannerPictureParams);
    },
  },
}

</script>

<style lang="scss">

.artist-profile-cover {
  &__wrapper {
    position: relative;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    height: 350px;
    width: 100%;

    @media screen and ($desktop) {
      grid-column: 1 / -1;
    }
  }

  &__picture-wrapper {
    height: 100%;
    background-color: var(--color-grey-empty);

    @media screen and ($desktop) {
      border-radius: var(--rounded-xl);
    }
  }

  &__picture {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    object-fit: cover;

    @media screen and ($desktop) {
      border-radius: var(--rounded-xl);
    }

    &--empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--color-grey-empty);
      border-radius: var(--rounded-xl);
    }
  }

  &__edit-cta {
    position: absolute;
    right: var(--space-lg);
    bottom: var(--space-lg);
  }
}

</style>
