<template>
  <div class="edit-landing__wrapper">
    <section class="edit-landing__landing-page">
      <Landing
        :banners="currentLandingData.BannerArtists"
        :showreelURL="currentLandingData.VideoURL"
        :artists="currentLandingData.FeaturedArtists"
        :comments="currentLandingData.Comments">
      </Landing>
    </section>
    <section
      :class="[
        'edit-landing__landing-controls',
        { 'edit-landing__landing-controls--open': isControlsOpen },
      ]">
      <button
        v-if="!currentEditSection"
        @click="toggleControls"
        :class="[
          'edit-landing__panel-toggle',
          { 'mb-lg': isControlsOpen },
        ]"
        type="button">
        <Icon
          :variant="isControlsOpen
            ? 'chevron-right-outline'
            : 'chevron-left-outline'">
        </Icon>
        <span>
          {{ isControlsOpen ? 'Réduire' : 'Afficher le panel' }}
        </span>
      </button>
      <template v-if="isControlsOpen">
        <ul
          v-if="!currentEditSection"
          class="edit-landing__controls-list">
          <li
            v-for="(section, index) in sections"
            :key="`section-${index}`">
            <button
              @click="currentEditSection = section"
              class="edit-landing__controls-item"
              type="button">
              <Heading
                :tag="'span'"
                :level="'h2'">
                {{ section.label }}
              </Heading>
              <Icon
                :variant="'chevron-right-outline'"
                :tag="'button'">
              </Icon>
            </button>
          </li>
        </ul>
        <section v-else>
          <header class="edit-landing__section-header">
            <Icon
              @click="currentEditSection = ''"
              :variant="'chevron-left-outline'"
              :tag="'button'"
              type="button"
              class="mr-base">
            </Icon>
            <Heading
              :tag="'h2'"
              :level="'h2'">
              {{ currentEditSection.label }}
            </Heading>
          </header>

          <Loader
            v-if="isLoading"
            class="my-xl">
          </Loader>
          <template v-else-if="currentEditSection.value === 'banner'">
            <div>
              <Heading
                :level="'h3'"
                class="mb-base">
                {{ artistsBanners.length }}
                <Paragraph
                  :size="'md'"
                  class="ml-xxs">
                  résultats
                </Paragraph>
              </Heading>
              <VirtualizedList :items="artistsBanners">
                <template #default="{ virtualizedListItems: banners }">
                  <ul class="edit-landing__banners-list">
                    <li
                      v-for="(banner, index) in banners"
                      @click="handleSelectBanner(banner)"
                      :key="`banner-${index}`"
                      class="edit-landing__banners-item">
                      <img
                        :src="banner.URL"
                        class="edit-landing__banners-image"
                      />
                    </li>
                  </ul>
                </template>
              </VirtualizedList>
              <div class="edit-landing__banners-submit-wrapper">
                <ul
                  v-if="selectedBanners.length"
                  class="edit-landing__selected-banners-list">
                  <li
                    v-for="(selectedBanner, index) in selectedBanners"
                    :key="`selected-banner-${index}`"
                    class="edit-landing__selected-banner-item">
                    <img :src="selectedBanner.URL"/>
                    <Icon
                      @click="selectedBanners = selectedBanners.filter(({ URL }) => URL !== selectedBanner.URL)"
                      :variant="'cross-outline'"
                      :tag="'button'"
                      :size="'xs'"
                      type="button"
                      class="edit-landing__selected-item-delete">
                    </Icon>
                  </li>
                </ul>
                <Button
                  @click="handleValidateBannerSelection"
                  :disabled="!selectedBanners.length"
                  isFluid
                  type="button">
                  Valider la sélection
                </Button>
              </div>
            </div>
          </template>

          <FieldInput
            v-else-if="currentEditSection.value === 'video'"
            v-model="videoURL"
            :label="'Lien de la vidéo'"
            :placeholder="`Copiez l'URL de la vidéo`"
            isAutofocus
            type="text"
            id="showreel-video-url">
          </FieldInput>

          <template v-else-if="currentEditSection.value === 'featured'">
            <InstantSearch :customFilters="featuredArtistAlgoliaFilterQuery">
              <FieldInput
                v-model="featuredArtistAlgoliaFilter"
                :label="'Recherche artiste'"
                :placeholder="`Recherchez par ID, Email, Prénom, Nom...`"
                type="text"
                id="featured-artist">
              </FieldInput>
              <div class="edit-landing__featured-artists-list-pagination">
                <ais-stats>
                  <template #default="{ nbHits }">
                    <Heading :level="'h3'">
                      {{ nbHits }}
                      <Paragraph
                        :size="'md'"
                        class="ml-xxs">
                        résultats
                      </Paragraph>
                    </Heading>
                  </template>
                </ais-stats>
                <ais-pagination
                  :show-first="false"
                  :show-last="false">
                </ais-pagination>
              </div>
              <ais-hits>
                <VirtualizedList
                  slot-scope="{ items }"
                  :items="items">
                  <template #default="{ virtualizedListItems: artists }">
                    <ul class="edit-landing__featured-artists-list">
                      <li v-if="!artists || (artists && !artists.length)">
                        <Paragraph
                          isTextCentered
                          isBold
                          class="my-xl">
                          Aucun résultat
                        </Paragraph>
                      </li>
                      <template v-else>
                        <li
                          v-for="(artist, index) in artists"
                          @click="handleSelectFeaturedArtist(artist)"
                          :key="`artist-${index}`"
                          class="edit-landing__featured-artists-item">
                          <img
                            v-if="artist.urlProfilePictures"
                            :src="artist.urlProfilePictures"
                            class="edit-landing__featured-artists-item-image">
                          <Icon
                            v-else
                            :variant="'user-outline'"
                            :size="'lg'"
                            style="grid-row: 1 / -1; justify-self: center;">
                          </Icon>
                          <Heading
                            :tag="'span'"
                            :level="'h3'">
                            {{ `${artist.firstname} ${artist.lastname}` }}
                          </Heading>
                          <Paragraph>
                            {{ $t(getActivityTranslation(artist.activity)) }}
                          </Paragraph>
                        </li>
                      </template>
                    </ul>
                  </template>
                </VirtualizedList>
              </ais-hits>
              <div class="edit-landing__selected-featured-artist-submit-wrapper">
                <ul
                  v-if="selectedFeaturedArtists.length"
                  class="edit-landing__selected-featured-artist-list">
                  <li
                    v-for="(artist, index) in selectedFeaturedArtists"
                    :key="`selected-featured-artist-${index}`"
                    class="edit-landing__featured-artists-item">
                    <img
                      v-if="artist.urlProfilePictures"
                      :src="artist.urlProfilePictures"
                      class="edit-landing__featured-artists-item-image">
                    <Icon
                      v-else
                      :variant="'user-outline'"
                      :size="'lg'"
                      style="grid-row: 1 / -1;">
                    </Icon>
                    <Heading
                      :tag="'span'"
                      :level="'h3'">
                      {{ `${artist.firstname} ${artist.lastname}` }}
                    </Heading>
                    <Paragraph>
                      {{ $t(getActivityTranslation(artist.activity)) }}
                    </Paragraph>
                    <Icon
                      @click="selectedFeaturedArtists = selectedFeaturedArtists.filter(({ objectID }) => objectID !== artist.objectID)"
                      :variant="'cross-outline'"
                      :tag="'button'"
                      :size="'xs'"
                      type="button"
                      class="edit-landing__selected-item-delete">
                    </Icon>
                  </li>
                </ul>
                <Button
                  @click="handleValidateFeaturedArtistsSelection"
                  :disabled="!selectedFeaturedArtists.length"
                  isFluid
                  type="button">
                  Valider la sélection
                </Button>
              </div>
            </InstantSearch>
          </template>

          <div v-else-if="currentEditSection.value === 'comments'">
            <FieldInput
              v-model="comment"
              :label="'Contenu du commentaire'"
              :charactersLimit="1000"
              isAutofocus
              type="textarea"
              id="comments"
              class="mb-base">
            </FieldInput>
            <Button
              @click="addComment"
              :disabled="!comment"
              type="button">
              Enregistrer le commentaire
            </Button>
            <div
              v-if="currentLandingData.Comments.length"
              class="edit-landing__comments-list-wrapper">
              <ul class="edit-landing__comments-list">
                <li
                  v-for="(savedComment, index) in currentLandingData.Comments"
                  :key="`saved-comment-${index}`"
                  class="edit-landing__comments-item">
                  <details>
                    <summary>
                      Commentaire n°{{ index + 1 }}
                      <Icon
                        @click="deleteComment(index)"
                        :variant="'cross-outline'"
                        :tag="'button'"
                        :size="'xs'"
                        class="edit-landing__selected-item-delete">
                      </Icon>
                    </summary>
                    <Paragraph
                      :size="'sm'"
                      class="my-xs">
                      {{ savedComment }}
                    </Paragraph>
                  </details>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <template v-if="hasModifiedLandingData && !currentEditSection">
          <Loader
            v-if="isLoadingSaveEdit"
            class="my-base">
          </Loader>
          <template v-else>
            <Button
              @click="handleEditLanding"
              isFluid
              type="button"
              class="my-sm">
              Sauvegarder les changements
            </Button>
            <Button
              @click="clearPendingLandingData"
              isFluid
              isWhite
              type="button">
              Annuler les changements
            </Button>
          </template>
        </template>
      </template>
    </section>
  </div>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                             from 'vuex';

import Landing                from '../../landing/components/p-Landing.vue';
import Loader                 from '../../../components/atoms/Loader/a-Loader.vue';
import Paragraph              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                   from '../../../components/atoms/Icon/a-Icon.vue';
import Button                 from '../../../components/atoms/Button/a-Button.vue';
import Heading                from '../../../components/atoms/Heading/a-Heading.vue';
import FieldInput             from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import InstantSearch          from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import VirtualizedList        from '../../../components/renderless/VirtualizedList/r-VirtualizedList.vue';
import SECTIONS               from '../../../constants/adminEditLandingSections.js';
import {
  getActivityTranslation,
}                             from '../../../utils/i18nUtils.js';


export default {
  name: 'p-EditLanding',
  components: {
    Landing,
    Loader,
    Paragraph,
    Icon,
    Button,
    Heading,
    FieldInput,
    InstantSearch,
    VirtualizedList,
  },
  data: () => ({
    isLoading: false,
    isLoadingSaveEdit: false,
    isControlsOpen: true,
    currentEditSection: '',
    bannerArtist: '',
    featuredArtistAlgoliaFilter: '',
    maxSelectedBanners: 5,
    maxSelectedFeaturedArtists: 5,
    maxComments: 5,
    selectedBanners: [],
    selectedFeaturedArtists: [],
    comment: '',
    sections: SECTIONS,
  }),
  computed: {
    ...mapState([
      'landingData',
      'pendingLandingData',
    ]),
    ...mapGetters({
      getCroppedArtistsBanners: 'GET_CROPPED_ARTISTS_BANNERS',
      currentLandingData: 'CURRENT_LANDING_DATA',
      artistsBanners: 'CROPPED_ARTISTS_BANNERS',
      hasModifiedLandingData: 'HAS_MODIFIED_LANDING_DATA',
    }),
    featuredArtistAlgoliaFilterQuery() {
      let filters = 'accountPublished:true';

      if (this.featuredArtistAlgoliaFilter) {
        filters = `${filters} AND (objectID:${this.featuredArtistAlgoliaFilter} OR email:${this.featuredArtistAlgoliaFilter} OR firstname:${this.featuredArtistAlgoliaFilter} OR lastname:${this.featuredArtistAlgoliaFilter})`
      }

      return { filters };
    },
    videoURL: {
      get() {
        return this.currentLandingData.VideoURL;
      },
      set(URL) {
        const isValidURL = URL && URL.startsWith('http');

        if (isValidURL) this.setPendingLandingData({ VideoURL: URL });
      }
    }
  },
  watch: {
    hasModifiedLandingData(hasModifiedLandingData, wasModifiedLandingData) {
      const hasResetedPendingData = wasModifiedLandingData && !hasModifiedLandingData;

      if (hasResetedPendingData) {
        this.selectedBanners = [];
        this.selectedFeaturedArtists = [];
      }
    },
    async currentEditSection(section) {
      if (section?.value === 'banner') {
        try {
          this.isLoading = true;

          await this.getArtistsBanners();
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      setPendingLandingData: 'SET_PENDING_LANDING_DATA',
      clearPendingLandingData: 'CLEAR_PENDING_LANDING_DATA',
    }),
    ...mapActions({
      getLandingData: 'GET_LANDING',
      editLandingData: 'EDIT_LANDING',
      getArtistsBanners: 'GET_ARTISTS_BANNERS',
      fetchArtists: 'FETCH_ARTISTS',
    }),
    getActivityTranslation,
    toggleControls() {
      this.isControlsOpen = !this.isControlsOpen;
    },
    handleSelectBanner(banner) {
      if (this.selectedBanners.length >= this.maxSelectedBanners) return;

      const shouldDeselectBanner = this.selectedBanners.some(({ URL }) => URL === banner.URL);

      this.selectedBanners = shouldDeselectBanner
        ? this.selectedBanners.filter(({ URL }) => URL !== banner.URL)
        : [...this.selectedBanners, banner];
    },
    async handleValidateBannerSelection() {
      const bannersArtistsIDs = this.selectedBanners.map(({ ArtistID }) => ArtistID);

      try {
        this.isLoading = true;

        const artistsData = await this.fetchArtists(bannersArtistsIDs);
        const bannerArtists = this.getCroppedArtistsBanners(artistsData
          .map(
            ({ URLBannerPictures: URL, BannerPicturesCropsOptions: Options, ID: ArtistID, ArtisteNicknameGenerated: ArtisteNickname, ...artist }) =>
            ({ URL, Options, ArtistID, ArtisteNickname, ...artist })
          ))
          .sort((a, b) => bannersArtistsIDs.findIndex(ID => ID === a.id) - bannersArtistsIDs.findIndex(ID => ID === b.id)
        );

        this.setPendingLandingData({ BannerArtists: bannerArtists });
      } finally {
        this.currentEditSection = '';
        this.isLoading = false;
      }
    },
    handleSelectFeaturedArtist(artist) {
      if (this.selectedFeaturedArtists.length >= this.maxSelectedFeaturedArtists) return;

      const shouldDeselectArtist = this.selectedFeaturedArtists.some(({ objectID }) => objectID === artist.objectID);

      this.selectedFeaturedArtists = shouldDeselectArtist
        ? this.selectedFeaturedArtists.filter(({ objectID }) => objectID !== artist.objectID)
        : [...this.selectedFeaturedArtists, artist];
    },
    async handleValidateFeaturedArtistsSelection() {
      const featuredArtistsIDs = this.selectedFeaturedArtists.map(({ objectID }) => objectID);

      try {
        this.isLoading = true;

        const artistsData = await this.fetchArtists(featuredArtistsIDs);

        this.setPendingLandingData({ FeaturedArtists: artistsData });
      } finally {
        this.currentEditSection = '';
        this.isLoading = false;
      }
    },
    addComment() {
      if (this.currentLandingData.Comments.length >= this.maxComments) return;

      this.setPendingLandingData({ Comments: [...this.currentLandingData.Comments, this.comment] });
      this.comment = '';
    },
    deleteComment(commentIndex) {
      const comments = this.currentLandingData.Comments.filter((comment, index) => index !== commentIndex);

      this.setPendingLandingData({ Comments: comments });
    },
    async handleEditLanding() {
      const { VideoURL, BannerArtists, FeaturedArtists, Comments, } = this.currentLandingData;
      const input = {
        videoURL: VideoURL,
        bannerArtists: BannerArtists?.reduce((acc, { ArtistID }, index) => ({ ...acc, [index]: ArtistID }), {}) ?? [],
        featuredArtists: FeaturedArtists?.reduce((acc, { ID }, index) => ({ ...acc, [index]: ID }), {}) ?? [],
        comments: Comments?.reduce((acc, comment, index) => ({ ...acc, [index]: comment }), {}) ?? [],
      };

      try {
        this.isLoadingSaveEdit = true;

        await this.editLandingData(input);
      } finally {
        this.isLoadingSaveEdit = false;
      }
    },
  },
}

</script>

<style lang="scss">

.edit-landing {
  &__wrapper {
    position: relative;
    margin: calc(-1 * var(--space-xl));
    margin-top: 0;
  }

  &__landing-controls {
    z-index: 10;
    position: fixed;
    top: var(--space-md);
    right: var(--space-md);
    padding: var(--space-xs);
    background: var(--color-gradient-main);
    overflow-y: auto;
    overscroll-behavior-y: contain;
    border: 1px solid var(--color-black);
    border-radius: var(--space-xs);

    &--open {
      max-height: 90vh;
      width: 400px;
      padding: var(--space-md);
      background: transparent;
      background-color: var(--color-white);
      border-color: var(--color-grey-semi);
    }
  }

  &__panel-toggle {
    display: flex;
    align-items: center;
    column-gap: var(--space-xs);
  }

  &__controls-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
    margin-top: var(--space-lg);

    li {
      display: flex;
      padding-bottom: var(--space-md);

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-grey-semi);
      }
    }
  }

  &__controls-item {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-lg);
  }

  &__banners-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-sm);
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  &__banners-image {
    border-radius: var(--rounded-xs);
  }

  &__banners-submit-wrapper {
    z-index: 10;
    position: sticky;
    bottom: calc(-1 * var(--space-md));
    display: flex;
    flex-direction: column;
    margin-top: var(--space-md);
    padding-top: var(--space-md);
    border-top: 1px solid var(--color-grey-semi);
  }

  &__selected-banners-list {
    display: flex;
    column-gap: var(--space-xs);
    margin-bottom: var(--space-md);

    > * {
      border: 2px solid var(--color-black);
      border-top-right-radius: 0;
    }
  }

  &__selected-banner-item {
    position: relative;
    display: flex;
    flex: 1 0 20%;
    border-radius: var(--rounded-xs);

    img {
      min-height: 50px;
      max-height: 50px;
      min-width: 100%;
      max-width: 100%;
      object-fit: cover;
      border-radius: var(--rounded-xs);
    }
  }

  &__selected-item-delete {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-white);
  }

  &__featured-artists-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-sm);
    margin-top: var(--space-md);
    overflow-y: auto;
    overscroll-behavior-y: contain;
  }

  &__featured-artists-list-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: var(--space-md);

    .ais-Pagination-list {
      display: flex;
      align-items: center;
      column-gap: var(--space-xxs);
    }

    .ais-Pagination-item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-white);
      font-size: var(--text-base);
      background-color: var(--color-grey-semi);
      border-radius: var(--rounded-sm);
      width: 24px;
      height: 24px;

      a {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &--selected {
        background-color: var(--color-black);
      }

      &--previousPage,
      &--nextPage {
        margin: 0 var(--space-xxs);
        font-size: var(--text-lg);
        font-weight: var(--font-medium);
        color: var(--color-black);
        background-color: transparent;
      }
    }
  }

  &__featured-artists-item {
    position: relative;
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    grid-column-gap: var(--space-base);
    padding: var(--space-sm) var(--space-md);
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-background);
    cursor: pointer;
  }

  &__featured-artists-item-image {
    grid-row: 1 / -1;
    max-height: 50px;
    max-width: 50px;
    justify-self: center;
    border-radius: var(--rounded-xs);
  }

  &__selected-featured-artist-submit-wrapper {
    z-index: 10;
    position: sticky;
    bottom: calc(-1 * var(--space-md));
    margin-top: var(--space-md);
    padding-top: var(--space-md);
    border-top: 1px solid var(--color-grey-semi);
    background-color: var(--color-white);
  }

  &__selected-featured-artist-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-sm);
    margin-bottom: var(--space-md);
    background-color: var(--color-white);

    > * {
      border: 2px solid var(--color-black);
      border-top-right-radius: 0;
    }
  }

  &__comments-list-wrapper {
    padding-top: var(--space-md);
    margin-top: var(--space-md);
    border-top: 1px solid var(--color-grey-semi);
  }

  &__comments-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-sm);
    padding-bottom: var(--space-md);
  }

  &__comments-item {
    position: relative;
  }
}

</style>
