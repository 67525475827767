import gql      from 'graphql-tag';


export default gql`
  mutation markBookingAsPaid($bookingID: String!) {
    markBookingAsPaid(bookingID: $bookingID) {
      ... on Booking {
        ID
        EventType
        EventDate
        PriceForClient
        PriceForArtist
        CanceledBeforeDueDate
        Line1
        Line2
        PostalCode
        City
        Country
        StartTime
        EndTime
        Status
        IsMaterialNeeded
        IsCanceledByClient
        NumberOfPeople
        ClientEventType
        Client {
          Email
          Firstname
          Lastname
          PhoneNumber
        }
        Artist {
          ID
          Email
          Firstname
          Status
          ArtisteNicknameGenerated
          Lastname
          PhoneNumber
          URLProfilePictures
          IsPaid
          ProfilePicturesCropsOptions
        }
      }

      ... on BookingNotFound {
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
