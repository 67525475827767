import gql      from 'graphql-tag';


export default gql`
  mutation eventRead($id: String!) {
    eventRead(id: $id) {
      __typename

      ... on Event {
        ID
        EventType
        IsRead
        Description
        Date
        ArtistID
        ArtistEmail
      }

      ... on EventNotFound {
        message
        status
      }

      ... on Unauthorized {
        message
        status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;
