import gql from 'graphql-tag';


export default gql`
mutation ($input: UpdateArtistDescriptionInput!) {
  updateArtistDescription(input: $input) {
    __typename
    ... on Artist {
      AdminDescription
    }
    ... on ArtistNotFound {
    message
    status
    }
    ... on Unauthorized {
    message
    status
    }
    ... on TokenError {
    message
    status
    requestID
    }
  }
}
`;
