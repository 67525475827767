const en = {
  common: {
    artist: "Artist",
    tasks: "My administrative profile",
    task: "task",
    profile: "profile",
    dashboard: "My account",
    agenda: "agenda et tarifs",
    pay: "Pay",
    payment: "payment",
    payments: "payments",
    finish: "Finish",
    english: "English",
    french: "French",
    email: "E-mail address",
    password: "Password",
    confirmPassword: "Confirm password",
    rememberMe: "Remember me",
    forgotPassword: "Forgot your password ?",
    connexion: "Connexion",
    signup: "Sign up",
    back: "Back",
    next: "Next",
    later: "Later",
    delete: "Delete",
    nextStep: "Next step",
    start: "Start",
    learnMore: "Learn more",
    showLess: "Show less",
    duration: "duration",
    faq: "Artist FAQ",
    equipement: "equipement",
    references: "references",
    instruments: "instruments",
    languages: "languages",
    locale: "languages",
    location: "location",
    medias: "medias",
    pricings: "pricings",
    pricing: "rate",
    repertoire: "repertoire",
    styles: "styles",
    settings: "settings",
    confirm: "Confirm",
    validate: "Validate",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    book: "Book",
    date: "Date",
    hour: "Hour",
    dd: "DD",
    mm: "MM",
    yyyy: "YYYY",
    day: "Day",
    month: "Month",
    year: "Year",
    birthday: "Date of birth",
    savedChanges: "Changes saved",
    bankCard: "Bank Card",
    timeTable: "Start time",
    startTime: "Start time",
    performance: "Performance",
    additionalFees: "Additional fees",
    additionalFeesDetails: "Taxes & commission",
    travelingFees: "Displacement",
    travelingFeesDetails: "Mileage fees",
    allIncluded: "All included",
    myPerformance: "My performance",
    displacement: "Displacement",
    includingTaxes: "Incl. taxes price",
    excludingTaxes: "Exclu. taxes price",
    withEquipements: "with equipements",
    firstName: "First name",
    lastName: "Last name",
    fullName: "Fullname",
    nickName: "Stage name",
    select: "Select...",
    telephone: "Phone number",
    mobilePhoneNumber: "Mobile phone number",
    address: "Address",
    addressComplement: "Address complement (not required)",
    zipCode: "Zip code",
    city: "City",
    country: "Country",
    department: "Region",
    generalInformations: "General Informations",
    administrativeStatus: "Administrative Status",
    artisticIdentity: "Artistic Identity",
    moreInformation: "More information",
    seeMore: "See more",
    description: "Description",
    bookingCheckoutInformation: "Lorem ipsum dolor sit amet sur",
    bookingCheckoutPerformanceInformation: "Lorem ipsum dolor sit amet sur",
    errors: {
      UnknownError: "An unknown error happened, please contact the support team",
      Unauthorized: "Your are not authorized to do this",
      passwordsMustMatch: "Passwords must be matching",
      filesUploadLimit: "These files exceed the {sizeLimit} MB limit : {filesNames}"
    },
    faqList: {
      chooseTricks: "Is it possible to choose the tricks which will be performed?",
      charactersStories: "What characters and stories are you staging?",
      executableTechniques: "What are the techniques that you can execute? (Contouring, smoky eye…)",
      kidsMakeup: "Can you do make up for kids?",
      customeMakeupTheme: "Can we settle on a specific make up for my event?",
      henna: "Can you do Henna ?",
      productsUse: "What products do you use?",
      characterOutfit: "What are the outfits that you wear to fit your character?",
      specialities: "What are your specialities ? (cards, illusion…)",
      customPerformance: "Can we work on a specific number for my event?",
      chooseDanceSong: "Is it possible to choose the music which you will dance on?",
      customChoreography: "Can we work on a specific choreography for my event?",
      activityExamples: "Can you give me some examples of activities you would put in place for kids and/or adults?",
      spectatorsLimit: "Is there a limited number of people?",
      bandComposition: "What is the band’s formation? Is it flexible, do you have different options?",
      chooseSong: "Can I choose the music/songs I would like to be performed?",
      learnSong: "Are you able to learn a specific number of songs for my event? If so, how many?",
      playInstrument: "Do you play any instrument? If so, which one(s)?",
      setupTime: "How much time do you need to install before your performance?",
      performanceSpace: "What kind of space does your performance require (size, specificities …)?",
      communicationMeans: "What is your preferred way of communication ahead of the event? By phone, email, video call or a meeting in person?",
      communicationTiming: "How much time in advance shall I contact you?",
      equipementDetails: "Can you detail your equipment (sound, lights, accessories…)?",
      equipementSpecificity: "Does your performance require any special equipment or any other specifics?",
      performanceOutfit: "Do you have any stage outfit(s)? Would you agree to an imposed dress code?"
    },
    countriesList: {
      france: 'France',
      spain: 'Spain',
      greatBritain: 'Great Britain',
      belgium: 'Belgium',
      switzerland: 'Switzerland'
    },
    activities: {
      Animator: "Animator",
      Musician: "Musician",
      Musicien: "Musician",
      Band: "Band",
      CircusArtist: "Circus Artist",
      Dancer: "Dancer",
      Dj: "DJ",
      Doppleganger: "Doppleganger",
      Hypnotiser: "Hypnotiser",
      Magician: "Magician",
      MakeupArtist: "Makeup Artist",
      Mentalist: "Mentalist",
      Puppeteer: "Puppeteer",
      Singer: "Singer",
      SingerMusician: "Singer & Musician",
      SingerMusicien: "Singer & Musician",
      NotSet: "Not set"
    },
    pricingsList: {
      base: 'regular',
      normal: 'regular',
      exceptional: 'special',
      exception: 'special',
      unavailable: "unavailable"
    },
    gendersList: {
      male: "Male",
      female: "Female",
      other: "Other"
    },
    statusesList: {
      intermittent: 'Intermittent',
      selfEmployed: 'Self Employed',
      SAS: 'SAS',
      SARL: 'SARL'
    },
    allowanceList: {
      twenty: "20% allowance",
      twentyFive: "25% allowance",
      zero: "No allowance"
    },
    languagesList: {
      mandarin: "Mandarin",
      spanish: "Spanish",
      english: "English",
      hindi: "Hindi",
      arabic: "Arabic",
      portuguese: "Portuguese",
      russian: "Russian",
      japanese: "Japanese",
      german: "German",
      javanese: "Javanese",
      korean: "Korean",
      french: "French",
      turkish: "Turkish",
      italian: "Italian",
      dutch: "Dutch"
    },
    instrumentsList: {
      accordions: "Accordions",
      altoFlat: "Alto e flat",
      altoSaxophone: "Alto saxophone",
      altoTrombone: "Alto trombone",
      baritoneFlat: "Baritone b flat",
      baritoneOboe: "Baritone oboe",
      baritoneSaxophone: "Baritone saxophone",
      bass: "Bass",
      bassFlat: "Bass B flat",
      bassClarinet: "Bass clarinet",
      bassDrum: "Bass drum",
      bassFlute: "Bass flute",
      bassGuitar: "Bass guitar",
      bassTrombone: "Bass trombone",
      bassTuba: "Bass tuba",
      bassoon: "Bassoon",
      bassBugle: "Bugle, bugle bass",
      cello: "Cello",
      clarinet: "Clarinet",
      clave: "Clave, maracas, guiro, wood blocks, cabasa",
      contrabassoon: "Contrabassoon",
      contralto: "Contralto clarinet",
      cornet: "Cornet",
      cymbals: "Cymbals",
      digitalPiano: "Digital piano",
      doubleBassTuba: "Double bass tuba, sub-bassophone, helicon",
      drums: "Drums",
      electricGuitar: "Electric guitar",
      electronicDrums: "Electronic drums",
      englishHorn: "English horn",
      ewi: "EWI (electronic wind)",
      fluteG: "Flute in G",
      frenchHorns: "French horns (with pistons or paddles)",
      glockenspiel: "Glockenspiel",
      gong: "Gong",
      guitar: "Guitar",
      harmonicas: "Harmonicas",
      harp: "Harp",
      harpsichord: "Harpsichord",
      naturalCorns: "Natural corns",
      naturalTrumpets: "Natural trumpets",
      oboe: "Oboe",
      oboeAmore: "Oboe d'amore",
      ondesMartenot: "Ondes Martenot",
      organs: "Organs",
      piano: "Piano",
      piccoloSmall: "Piccolo or small flute",
      piccoloTrumpet: "Piccolo trumpet, trumpet in C, trumpet in B flat",
      pistonTrombone: "Piston trombone",
      recorders: "Recorders or sweet flutes",
      smallClarinet: "Small E-flat clarinet",
      snareDrum: "Snare drum, rolling drum",
      soprano: "Soprano saxophone",
      synthesizer: "Synthesizer",
      tambourine: "Tambourine",
      tenorSaxophone: "Tenor saxophone",
      tenorTrombone: "Tenor trombone",
      tenorTuba: "Tenor tuba, euphonium",
      timpani: "Timpani",
      traverseFlute: "Transverse flute or large flute",
      triangle: "Triangle, castanets, rattle, whip",
      tubularBells: "Tubular-bells (carillon)",
      tympanum: "Tympanum, cymbalum",
      vibraphone: "Vibraphone",
      violaViolin: "Viola violin",
      violin: "Violin",
      xylophone: "Xylophone"
    },
    stylesList: {
      rock: "Rock",
      soul: "Soul",
      rAndB: "RnB",
      discoFunk: "Disco / Funk",
      hiphop: "Hip-hop",
      frenchVarieties: "French Varieties",
      classicalMusic: "Classical Music",
      pop: "Pop",
      reggaeRagga: "Reggae / Ragga",
      jazz: "Jazz",
      blues: "Blues",
      latino: "Latino",
      country: "Country",
      generalMusic: "General Music",
      lounge: "Lounge",
      worldMusic: "World Music",
      generalist: "Generalist"
    }
  },
  admin: {
    signin: {
      artistEmail: "Artist email",
      errors: {
        adminNotFound: "No admin associated with this mail address",
        artistNotFound: "No artist associated with this mail address",
        WrongPassword: "Wrong password for this mail address",
      }
    }
  },
  artist: {
    signup: {
      createAccount: "Create an Artist account",
      alreadyMember: "Already registered ?",
      signin: "Sign In",
      fillInformations: "Enter my login details",
      errors: {
        UserAlreadyExist: "A user is already associated with this mail address",
        WrongInput: "Wrong inputs, Please verify your data"
      }
    },
    signin: {
      connect: "Connect",
      welcome: "I am an artist ? <br> Log in",
      noAccount: "No account ?",
      signup: "Sign up",
      iAmBooker: "I want to book an artist",
      iAmArtist: "I am an artist",
      errors: {
        UserNotFound: "No account found matching this mail address",
        WrongPassword: "Wrong password for this mail address",
        WrongInput: "Wrong inputs, Please verify your data",
      }
    },
    mailValidation: {
      mailValidation: "Confirm you",
      emailSent: "We just sent you a mail, please click on the validation link to finish your registration",
      sentTo: "Sent to : ",
      expirationMessage: "Link will expire in : ",
      expirationDate: "1 week "
    },
    mailValidated: {
      congratulations: "Your account has been created successfully!",
      created: "It's up to you! <br> Fill out your artistt profile and get your first booking offer!",
      name: "Your name will be displayed as :",
      fillProfile: "Complete my Profile",
      errors: {
        emailAlreadyVerified: 'Your email has already been verified !',
      }
    },
    passwordRecovery: {
      askEmail: "Please provide us with the email associated with your account to send you a link to reset your password",
      mailSent: "Email sent to :",
      errors: {
        UserNotFound: "No account found matching this mail address",
        WrongInput: "Wrong inputs, Please verify your data"
      }
    },
    phoneValidation: {
      title: "Verification code",
      subTitle: "It is mandatory and will allow bookers to contact you",
      sentTo: "Sent to :",
      sendCode: "A code will be sent to you by SMS to :",
      inputCode: "Veuillez entrer le code reçu par SMS",
      codeExpiresIn: "Code expires in :",
      timeout: "{timeout} second(s)",
      codeExpired: "The code has expired, please try again.",
      alreadyVerified: "Your phone number is verified",
      resendCode: "Resend code",
      send: "Envoyer",
      errors: {
        NoSMSCodeFound: "No code found. Please enter the code you just received on your phone.",
        WrongSMSCode: "Wrong code. Please enter a valid code.",
      }
    },
    passwordReset: {
      newPassword: "New password",
      confirmNewPassword: "Enter your new password",
      errors: {
        WrongInput: "Wrong inputs, Please verify your data"
      }
    },
    settings: {
      connexionCredentials: "Connexion credentials",
      payment: "My payments",
      identity: "Identity informations",
      status: "Administrative status",
    },
    agenda: {
      yourPerformances: "Check out your next bookings and tell us about your availabilities and their pricing"
    },
    dashboard: {
      menu: {
        myProfile: "My profile",
        myAccount: "My account",
        logout: "Log out",
        help: "Help",
        changeLocale: "Change language",
        requestValidation: "I wish to be certified by Bookunartiste !",
        requestValidationSent: "Your certification request is being reviewed"
      },
      tasks: {
        todo: "Complete and modify your information",
        timeLimit: "You have {timeLimit} days left to complete this task",
        identity: {
          title: "Fill in your profile ! This will only take a few minutes.",
          description: `We'll ask for some administrative and artistic information before you can complete your Book Un Artiste profile.<br>
            This is will allow us to pay you and contact you for future bookings. Everything is strictly confidential and securely stored. Do not share those info with your bookers !<br>
          `
        },
        stripe: {
          title: "Payment information",
          cta: "Configure Stripe",
          description: "Set up your Stripe account to receive your payments",
          later: "We work with Stripe to secure all payments on the platform. So the button will take you to their portal where you'll be asked to fill in some info like your email and phone number (you already gave that to us we know) along with your IBAN information. This is a mandatory step for us to be able to validate your account. <br> If you'd like you can come back to that step later. But don't come back too late. You have until {stripeTaskTimeLimit} to do so.",
          clickHere: "Click here",
        },
        activity: {
          title: "Artistic activity",
          description: "Select an activity",
          successTitle: "Welcome {firstname} !",
          successMessage: "Success message",
        },
        status: {
          title: "My status",
          description: "Complete the information about your legal status"
        },
        artisticIdentity: {
          title: "Artistic identity",
          description: "About me"
        },
        artistIdentity: {
          title: "Identity",
          description: "Your identity informations"
        },
        phoneValidation: {
          title: "Phone number",
          description: "Verifiy my phone number"
        },
        agendaAndPricing: {
          title: "Agenda and pricings",
        },
        bookingsAndPayments: {
          title: "Bookings and payments",
        }
      },
      profile: {
        title: "My artistic profile",
        yourProfile: "Bring out your universea"
      },
      payments: {
        yourPayments: "Your upcoming payments",
        noPayment: "You don't have any upcoming payment yet"
      },
      errors: {
        dateNotFound: "The date you asked for could not be found"
      }
    },
    profile: {
      header: {
        editProfilePicture: "Edit your profile picture",
        editBannerPicture: "Edit your banner picture",
        errors: {
          cropOutOfBounds: "Your picture must stay within the bounds",
          needsAnotherBanner: "Upload another banner picture before deleting this one"
        }
      },
      description: {
        notSet: "Introduce yourself in a few lines"
      },
      agenda: {
        yourPerformances: "Check out your next bookings and tell us about your availabilities and their pricing",
      },
      pricings: {
        subTitle: "Details your rates depeding on the dates"
      },
      performance: {
        errors: {
          activityNotSet: "Set your activity before updating your performance"
        }
      },
      duration: {
        subTitle: "My performance duration",
        performanceDuration: "{duration}h of performance",
        isChildrenSafe: "My performance is children safe",
        isNotChildrenSafe: "My performance is not children safe",
        notSet: "You have not set your performances duration yet.",
        isAdditionalHourOk: "You accept overtime",
        isAdditionalHourNotOk: "You do not accept overtime"
      },
      references: {
        notSet: "You have not set your references yet."
      },
      instruments: {
        notSet: "You have not set your instruments yet."
      },
      languages: {
        subTitle: "Spoken languages",
        notSet: "You have not set your languages yet."
      },
      location: {
        subTitle: "Maximum distance for a performance",
        range: "Max {range} km",
        notSet: "You have not set your location yet."
      },
      repertoire: {
        subTitle: "Your band's musical repertoiresubTitle:",
        notSet: "Your band has not set its repertoire yet."
      },
      faq: {
        notSet: "You have not answered any question yet."
      },
      styles: {
        notSet: "You have not set your style yet."
      },
      medias: {
        subTitle: "Your videos",
        notSet: "You have not added any video yet.",
        pendingVideo: "Your video is being uploaded and will be available shortly..."
      },
      generalInformations: {
        subTitle: "Information regarding the Activity",
        notSet: "You have not set your general informations yet.",
        canReadScore: "You can read scores",
        canNotReadScore: "You can not read scores",
        songCount: "You know {songCount} songs",
        hasOwnEquipement: "You have your own equipement",
        hasNotOwnEquipement: "You don't have your own equipement",
        bandMemberCount: "Your band has {bandMemberCount} members",
        ismcOrAnimationMic: "You are an evening entertainer",
        isNotmcOrAnimationMic: "You are not an evening entertainer"
      },
      dashboard: {
        yourDashboard: 'Go back to your account'
      },
      equipement: {
        title: "Equipement",
        subTitle: "Choose your equipement"
      }
    },
    editActivity: {
      aboutYou: "About you :",
      iAm: "I am :",
      performanceSuited: "My performance are :",
      childrenDedicated: "Dedicated to children",
      childrenAllowed: "Suitable for children",
      childrenNotAllowed: "Not suitable for children",
      conditions: "I certify that I am over 18 years old and accept the {conditions}",
      accept: "T&Cs",
      errors: {
        onlyOneActivity: "You can only set a single activity",
        isAllowChildrenMissing: "Please select one option in the list",
        conditionsMissing: "Please certify that you are over 18 years old and accept the T&Cs"
      }
    },
    editIdentity: {
      title: "Identity",
      subTitle: "Your contact details will in no case be disclosed or used for commercial purposes but will allow us to calculate your travel costs as well as possible and pay you",
      fields: {
        civility: "Civility",
        nickname: "Nickname",
      },
      errors: {
        WrongInput: "Wrong inputs, Please verify your data",
        SMSError: "Error while sending SMS, please verify your phone number or try again later",
        noCitySelected: "Please pick a city from the list."
      }
    },
    editAgenda: {
      subtitle: "Who is the Boss? Fill in your agenda according to your availability and the rates you want to exercise according to the dates. Regular updating is essential in order to avoid being booked on a day when you are unavailable! The days you left blank are scheduled by default as available for instant booking. <a class='crop-text--cta crop-text--cta--base' href='https://bookunartiste.tawk.help'>You are responsible for your planning</a>. By default, your calendar is pre-filled at the normal rate on weekdays and full rate on weekends. It's up to you to determine the days of strong activities. The exceptional days are the holidays such as Christmas, New Year's Day ..."
    },
    editAdministrativeStatus: {
      subTitle: "Fill in the necessary information according to your status. They are important because they allow us to pay you and calculate your compensation if you are intermittent.",
      iAm: "I am :",
      leaveNumber: "Leave No.",
      siretNumber: "Siret No.",
      socialSecurityNumber: "Social Security No.",
      uploadPrivateDocument: "Upload an ID document"
    },
    editArtisticIdentity: {
      subTitle: "Your artistic profile. Please note that your description will be public. In agreemnent with the GCU, do not write any personal information not related to your artistic profile in it.",
      description: "Description",
      uploadAvatar: "Upload your profile picture",
      uploadCover: "Upload your cover photo"
    },
    editPricings: {
      hint: "Indicate the price of your performance according to the days of the week and your activity including the commission of 12.5% of Book un artiste. They will be classified by color in your calendar.",
      isIntermittent: "NB: Are you an intermittent artist?",
      calculatePricing: "Calculate your pricing."
    },
    editPerformanceDuration: {
      subTitle: "Specify the maximum duration of your performance (excluding installation time). As a reminder, it is mandatory to come with your equipment in good condition and functional to your event. \nPro tip: If you do not have the necessary material, you can rent it and take into account its costs when calculating your rate.",
      hint: "Please set the maximum duration of your performance :",
      isChildrenSafe: "My performance is children safe",
      isAdditionalHourOk: "I accept overtime"
    },
    editLocationRange: {
      subTitle: "Set the maximum distance you are willing to travel for a performance. A package of 30 € is included in addition to your price in compensation for your travel within a perimeter of 50km. Beyond that, an additional 1.25 € per km applies. Beware of artists who don't have their own ride! Beyond 150km and with your agreement, the customer must request a quote in order to best calculate your travel costs.",
      hint: "Please set the maximum range of your performance :"
    },
    editGeneralInformations: {
      hint: "Please set the general informations regarding your activity :",
      songCount: "Number of songs in repertoire",
      bandMemberCount: "Number of members in the band",
      canReadScore: "Can read scores",
      mcOrAnimationMic: "I am an evening entertainer",
      hasOwnEquipement: "I have my own equipement"
    },
    editReferences: {
      searchLabel: "New reference"
    },
    editRepertoire: {
      subTitle: "",
      searchLabel: "Add a repertoire"
    },
    editMedias: {
      subTitle: "Upload your photos and videos to impress and reassure your future bookers. It will improve your visibility on the Book un artiste platform. FYI, you can upload up to 4 photos and 4 videos.",
      yourPictures: "Your pictures",
      yourVideos: "Your videos",
      picturesNotSet: "You have not set any pictures yet",
      videosNotSet: "You have not set any videos yet",
      uploadInProgress: "Your video is being uploaded and will be available soon. You can continue filling in your profile.",
      errors: {
        uploadError: "Error while uploading your files"
      }
    },
    editInfluences: {
      subTitle: "From Johnny Haliday to Britney Spears by way of Frozen, list the people who make you the artist you are today.",
    },
    editInstruments: {
      subTitle: "The different musical instruments you play.",
    },
    editLanguage: {
      subTitle: "Indicate which languages you speak or the ones you sing",
    },
    editSettings: {
      subTitle: "General settings",
    },
    editStyle: {
      subTitle: "Select the styles that will allow bookers to better understand your universe. Maximum 4.",
    },
    editFaq: {
      subTitle: "You will find below the list of the most frequent questions of Bookers. Answer as many questions as possible to facilitate your reservation and that your service takes place in the best conditions",
      chooseQuestion: "Please select a question",
      answer: "Answer"
    },
    editEquipement: {
      subTitle: "Edit your equipement"
    },
    prestationSummary: {
      title: "Performance",
      subTitle: "Here is your performance summary",
      intermittentInfo: "We are in charge of your tax declarations",
      dates: {
        title: "Date & Time",
        subTitle: ""
      },
      location: {
        title: "Location",
        subTitle: "The address where you will perform"
      },
      client: {
        title: "Client",
        subTitle: "Client information"
      },
      price: {
        title: "Pricing",
        subTitle: "Pricing details for this performance",
        cancelWithinTwoDays: "No cancelation fees within 48h",
        prestation: "Prestation",
        fees: "Taxes",
        netSalary: "Net Salary",
      }
    },
    pricingSimulator: {
      title: "Pricing simulation",
      detailsIntermittent: "This simulator will allow you to know your net remuneration, given the fees you ask for. Fill your tax allowance and one of the fields to get the other one.",
      detailsOther: "Calculate the fee you will receive after deducting our commission",
      selectLabel: "My system :",
      askedFeeLabel: "Fee displayed in the application : ",
      netFeeLabel: "My net salary : ",
      noAllowanceSelected: "Please select an allowance before simulating your salary"

    }
  },
  pageNotFound: {
    notFound: "This page cannot be found",
    backToDashboard: "Go back to your account"
  },
  landing: {
    header: {
      artistSignin: "I am an artist",
      signin: "Sign in"
    },
    searchHeader: {
      findAnArtist: "Find an artist",
      bookAnArtist: "Book an artist with only a few clicks !",
      singerOrDj: "A singer? A Dj?",
      iSearch: "I'm looking for",
      eventDate: "Event date",
      addDate: "Add a date",
      location: "Location",
      aroundYou: "Around you? Further?",
      search: "Search",
      Artist: "Artist, n,m",
      artistDef: "« Someone dedicated to the expression of what is beauty, pratices art »",
      iAmArtist: "I am an Artist"
    },
    validatedArtists: {
      title: "Validated artists",
      label: "Our Artists of the week",
    },
    cta: {
      allArtists: "All kind of artists",
      allFrance: "Everywhere in France",
      simple: "Simple and Reliable",
    },
    help: {
      title1: "Do you need",
      title2: "some help?",
      label: "An event abroad or over several days? Need a quote or just help?",
      buttonLabel: "Contact us",
    },
    footer: {
      cgu: "Conditions d'utilisation & Vie privée"
    }
  },
  client: {
    signup: {
      createAccount: "Create your account"
    },
    mailValidated: {
      congratulations: "Congratulations !",
      created: "Your profile has been created with success!",
      bookAnArtist: "Book an artist",
      fillProfile: "Fill my Profile"
    },
    dashboard: {
      menu: {
        title: "Settings",
        logout: "Log out",
        help: "Help",
        changeLocale: "Change language"
      },
      search: {
        title: "Search",
        searchBoxHint: "Which artists ?",
        artists: "Artist | Artists",
        results: "Here are our artists.",
        filter: "Filter",
        sort: "Sort",
        noResults: "There is no artist meeting your criteria.",
      },
      filter: {
        title: "Filters",
        customQuote: "Custom quote",
        customQuotesubTitle: "For special request (abroad, on multiple days etc...)",
        activities: "Activities",
        styles: "Styles",
        allowChildren: "Children safe",
        availabilities: "Availabilities",
        location: "Location",
        pricing: "Pricing",
        findALocation: "Find a location",
        selectedLocation: "Selected location :"
      },
      sort: {
        ascending: "Ascending pricing",
        descending: "Descending pricing",
      },
      seeArtists: "See the {hitsCounter} artists",
    },
    artistPublicProfile: {
      agenda: {
        performances: "The artist's upcoming performances"
      },
      duration: {
        subTitle: "Performance duration",
        performanceDuration: "{duration}h of performance",
        isChildrenSafe: "Performances are children safe",
        isNotChildrenSafe: "Performances are not children safe",
        notSet: "The artist has not set the performance's duration yet.",
        isAdditionalHourOk: "The artist accepts overtime",
        isAdditionalHourNotOk: "The artist does not accept overtime"
      },
      references: {
        notSet: "The artist has not set his references yet."
      },
      instruments: {
        notSet: "The artist has not set his instruments yet."
      },
      languages: {
        notSet: "The artist has not set his languages yet."
      },
      location: {
        subTitle: "Maximum distance for a performance",
        range: "Max {range} km around {city}",
        notSet: "The artist has not set his location yet."
      },
      repertoire: {
        subTitle: "The band's musical repertoire",
        notSet: "The band has not set its repertoire yet."
      },
      styles: {
        notSet: "The artist has not set his style yet."
      },
      faq: {
        notSet: "The artist has not answered any question yet."
      },
      medias: {
        subTitle: "The artist's videos",
        notSet: "The artist has not added any video yet.",
        picturesNotSet: "The artist has no pictures",
        videosNotSet: "The artist has no videos",
      },
      generalInformations: {
        subTitle: "Information regarding the Activity",
        notSet: "The artist has not set his general informations yet.",
        canReadScore: "The artist can read scores",
        canNotReadScore: "The artist cannot read scores",
        songCount: "The artist knows {songCount} songs",
        hasOwnEquipement: "The artist has his own equipement",
        hasNotOwnEquipement: "The artist has not his own equipement",
        bandMemberCount: "The band has {bandMemberCount} members",
        ismcOrAnimationMic: "The artist is an evening entertainer",
        isNotmcOrAnimationMic: "The artist is not an evening entertainer"
      }
    },
    booking: {
      pendingStatus: "Pending",
      validStatus: "Valid",
      performanceLocation: "{line1} {line2}, {postalCode}, {city}, {countryLabel} at {startTime}h {IsMaterialNeeded}",
      details: {
        addressInformation: "Where will the performance take place",
        timeInformation: "When will the performance start",
        isMaterialNeeded: "I need equipements for my performance"
      },
      pricing: {
        title: "Quote",
        yourPerformance: "Your performance",
        yourPerformanceSubtitle: "Performance recap",
        pricing: "Pricing",
        pricingSubtitle: "Pricing detail",
        freeCancellation: "Free cancellation within 48h",
        freeCancellationAdditionalInfo: ""
      },
      summary: {
        title: "Résumé",
        messageTitle: "Lorem ipsum sit amet sur",
        messageContent: "Lorem ipsum sit amet sur",
      },
      checkout: {
        message: "Enter your credit card information",
        prestationInformation: "Your performance recap",
      },
      success: {
        successMessage: "Congratulations !",
        additionalInfo: "Your performance is confirmed! You'll receive a confirmation email soon"
      },
      errors : {
        notLoggedIn: "Please login to complete your booking",
        noStartTimeSelected: "Please select the performance start time.",
        noLocationSelected: "Please select a city in the list",
        noDateSelected: "Please select a performance date on the calendar.",
        artistProfileNotFound: "This artist could not be found.",
        ArtistNotFound: "This artist could not be found.",
        ArtistNotAvailable: "This artist is not available for this date.",
        AvailabilityNotFound: "The date you selected could not be found."
      }
    }
  },
  navigation: {
    common: {
      myMenu: "Menu",
      signIn: 'I am an artist',
      searchArtist: 'Search an artist',
      changeLocale: 'Language',
      help: "Need help ?",
      contactUs: "Contact us",
      logout: "Log out"
    },
    artist: {
      myProfile: 'My profile',
      myIdentity: "My identity",
      myAdministrativeStatus: "Administration",
      myPayments: "My payments",
      pricingSimulator: "Pricing simulator"
    }
  }
};


export default en;