<template>
  <MainLayout
    class="main-layout--dashboard"
    isFullScreen>
    <section>
      <header class="admin-dashboard__header">
        <Heading isTextCentered>
          <router-link :to="{ name: 'Dashboard' }">
            Arrière Guichet
          </router-link>
        </Heading>
        <button
          @click="logout"
          type="button">
          <Paragraph isBold>
            Deconnexion
          </Paragraph>
        </button>
      </header>
      <ul class="admin-dashboard__actions-list">
        <template v-for="(action, index) in actions">
          <router-link
            :tag="'li'"
            :to="{ name: action.route }"
            :key="`action-${index}`"
            custom>
            <template #default="{ navigate, isActive, isExactActive }">
              <li
                @click="navigate"
                :class="[
                  'admin-dashboard__actions-item',
                  { 'admin-dashboard__actions-item--active': (isActive || isExactActive) },
                ]">
                <a>
                  <Heading
                    :tag="'h2'"
                    :level="'h3'"
                    :color="(isActive || isExactActive) ? 'white' : 'black'">
                    {{ action.label }}
                  </Heading>
                </a>
              </li>
            </template>
          </router-link>
        </template>
      </ul>
    </section>

    <router-view name="default"></router-view>
  </MainLayout>
</template>

<script>

import {
  mapMutations,
}                     from 'vuex';

import MainLayout     from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'p-Dashboard',
  components: {
    MainLayout,
    Heading,
    Paragraph,
  },
  computed: {
    actions() {
      return [
        {
          label: 'Évènements admin',
          route: 'Dashboard',
        },
        {
          label: 'Recherche Artistes',
          route: 'SearchArtist',
        },
        {
          label: 'Bookings',
          route: 'Bookings',
        },
        {
          label: 'Landing',
          route: 'EditLanding',
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setIsSignedIn: 'SET_IS_SIGNED_IN',
    }),
    logout() {
      this.setIsSignedIn(false);
      this.$router.push({ name: 'Signin' });
    },
  },
};

</script>

<style lang="scss">

.admin-dashboard {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-base);
  }

  &__actions-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }

  &__actions-item {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-xs);
    background-color: var(--color-white);
    border: 2px solid var(--color-black);
    border-radius: var(--rounded-xs);
    text-align: center;
    cursor: pointer;

    &--active {
      color: var(--color-white);
      background-color: var(--color-black);
    }

    &--disabled {
      cursor: not-allowed;
      border-color: var(--color-grey-semi);

      h2 {
        color: var(--color-grey-semi) !important;
      }
    }
  }
}

</style>
